/**
 * 현재 페이지에 따라 로그인 페이지로 이동하는 모달을 띄워주는 함수입니다.
 * hook을 사용할 수 없는 부분에서 사용합니다.
 */
export function blockGuestAndRedirectLogin() {
  const currentPath = window.location.pathname
  const isApp = currentPath.includes('/app/')

  // 현재 페이지가 로그인이 필요 하지 않는 페이지일 경우 로그인 모달을 띄우지 않는다.
  const nonAuthRequiredPages = ['/promotion', '/support', '/auth']
  const isNonAuthRequiredPage = nonAuthRequiredPages.some((path) => currentPath.includes(path))

  if (isNonAuthRequiredPage) return

  const requestLoginModalProps = isApp
    ? {
        title: '로그인이 필요한 페이지입니다.',
        message: '로그인 페이지로 이동합니다',
      }
    : {
        title: '로그인이 필요한 페이지입니다.',
        message: '잠시 후 로그인 페이지로 이동합니다',
        confirmText: '바로 로그인 하기',
        kickTo: '/auth/login',
      }

  window.postMessage({
    to: '@reactleaf/modal',
    payload: {
      type: 'account/Kickout',
      props: requestLoginModalProps,
    },
  })
}
