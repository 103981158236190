import { css } from 'styled-components'

export const PromotionTypography = {
  H1: css`
    font-size: 5rem;
    font-weight: 700;
    line-height: 6rem;
    letter-spacing: -0.04em;
  `,
  H2: css`
    font-size: 4rem;
    font-weight: 700;
    line-height: 5rem;
    letter-spacing: -0.04em;
  `,
  H3: css`
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 4.4rem;
    letter-spacing: -0.04em;
  `,
  H4: css`
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 3.6rem;
    letter-spacing: -0.04em;
  `,
  H5: css`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 3.2rem;
    letter-spacing: -0.04em;
  `,
  H6: css`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.6rem;
    letter-spacing: -0.04em;
  `,
  H7: css`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2rem;
  `,
  Body1: css`
    font-size: 1.6rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  `,
  Body1Bold: css`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  `,
  Body2: css`
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: -0.04em;
  `,
  Body2Bold: css`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.2rem;
    letter-spacing: -0.04em;
  `,
  Caption1: css`
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: -0.04em;
  `,
  Caption2: css`
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: -0.04em;
  `,
  ButtonSmall: css`
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    letter-spacing: 0;
  `,
  ButtonMedium: css`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4rem;
    letter-spacing: -0.04em;
  `,
  ButtonLarge: css`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.6rem;
    letter-spacing: -0.04em;
  `,
}
