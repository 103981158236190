import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'

export const onFullscreen = '.vjs-fullscreen &'
export const onAppFullscreen = '.rc2-video.fullscreen &'

export const onSmall = '@media only screen and (max-width: 800px)' // mobile portrait or tablet landscape
export const onLarge = '@media only screen and (min-width: 801px)'

export const onSmaller = (breakpoint: number) =>
  `@media only screen and (max-width: ${breakpoint - 1}px)`

/* iOS에서 fullscreen시, 자체 플레이어로 전체화면을 띄우는 이슈 때문에 전체화면인 척 화면을 돌려서 보여줄 때 사용할 미디어쿼리. 현재는 모든 모바일 디바이스에 일괄적용 */
export const onFakeMobileLandscape = '@media only screen and (max-width: 420px)'

export const hideScroll = `
-ms-overflow-style: none; /* IE and Edge */
scrollbar-width: none; /* Firefox */
&::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
`

export const onTablet = (styles: FlattenSimpleInterpolation) => ({
  theme,
}: {
  theme: DefaultTheme
}) =>
  theme.isApp &&
  css`
    ${onLarge} {
      ${styles}
    }
  `

export const onDesktop = (styles: FlattenSimpleInterpolation) => ({
  theme,
}: {
  theme: DefaultTheme
}) =>
  theme.isWeb &&
  css`
    ${onLarge} {
      ${styles}
    }
  `

export const onLandscape = '@media (orientation: landscape)'
export const onPortrait = '@media (orientation: portrait)'

// z-flip landscape mobile web에도 small view를 보여주기 위한 미디어쿼리
export const onMobileWeb = `@media (orientation: landscape) and (min-aspect-ratio: 16/9) and (any-hover: none),
   (orientation: portrait) and (max-aspect-ratio: 9/16) and (any-hover: none)`
