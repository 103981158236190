import qs from 'querystring'
import { URL } from '@constants/paths/utils'
import { isDeployTypeLive } from '@utils/deployType'
import { asPath as asPathWithAllQuery } from '@utils/swr'
import { ControlType } from './useNavigationStateChangeBridge'

// 브라우저에서 개발중에는, 브릿지 호출 로그만 남긴다
const BRIDGE_DEBUG = process.env.NODE_ENV === 'development' || !isDeployTypeLive

function messageToNativeApp(message: { type: string; payload?: unknown }) {
  window.ReactNativeWebView.postMessage(JSON.stringify(message))
}

export function loadWebview(path: URL, screen: 'Study' | 'LiveStudy') {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({
        type: 'loadWebview',
        payload: { path: asPathWithAllQuery(path), screen },
      })
    }
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: loadWebview(${asPathWithAllQuery(path)})`)
      : console.error(error)
  }
}

export function loadCoaching(path: URL) {
  const isNative = !!window.ReactNativeWebView

  const ids = path.query?.ids as string
  const [courseId, dayId] = ids.split('-').map((id) => Number(id))

  if (!courseId) return console.info('courseId is undefined') //TODO: may needs to handle error.. but how?

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'loadCoaching', payload: { courseId, dayId } })
    }
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: loadCoaching(${path.pathname} ${ids})`)
      : console.error(error)
  }
}

export function loadRealLive(path: URL) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'loadRealLive', payload: { path: asPathWithAllQuery(path) } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: loadRealLive`) : console.error(error)
  }
}
function loadRealLiveDetail(path: URL) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'loadRealLiveDetail', payload: { path: asPathWithAllQuery(path) } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: loadRealLiveDetail`) : console.error(error)
  }
}
function closeRealLiveDetail() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'closeRealLiveDetail' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: closeRealLiveDetail`) : console.error(error)
  }
}

export function loadChallenge() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'loadChallenge' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: loadChallenge`) : console.error(error)
  }
}

function openWebviewModal(path: URL) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'openWebviewModal', payload: { path: asPathWithAllQuery(path) } })
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: openWebviewModal(${asPathWithAllQuery(path)})`)
      : console.error(error)
  }
}
function closeWebviewModal() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'closeWebviewModal' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: closeWebviewModal`) : console.error(error)
  }
}

type CloseWebviewParams = {
  onClose?: 'updateCourseLog'
  coachingLoaded?: boolean
  webview?: string // 어느 웹뷰를 닫는지 체크가 필요한 경우
}

export function closeWebview(params: CloseWebviewParams) {
  const isNative = !!window.ReactNativeWebView

  const param = qs.stringify(params)
  try {
    if (isNative) {
      return messageToNativeApp({ type: 'closeWebview', payload: params })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: closeWebview(${param})`) : console.error(error)
  }
}

interface SetTokenParams {
  accessToken: string
  refreshToken: string
  isAppRestart?: boolean
}

function setToken({ accessToken, refreshToken, isAppRestart = true }: SetTokenParams) {
  const isNative = !!window.ReactNativeWebView

  if (isNative) {
    return messageToNativeApp({
      type: 'setToken',
      payload: { accessToken, refreshToken, isAppRestart },
    })
  }
}

function clearToken() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'clearToken' })
    }
  } catch (error) {
    // Sentry.captureException(error)
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: clearToken()`) : console.error(error)
  }
}

export function openMyClassBridge({
  courseId,
  optionQuery,
}: {
  courseId: number | string
  optionQuery?: string
}) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openMyClassBridge', payload: { courseId, optionQuery } })
    }
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: openMyClassBridge(${courseId}, ${String(optionQuery)}})`)
      : console.error(error)
  }
}

function openClass() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'openClass' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openClass`) : console.error(error)
  }
}
function openClassDetail(path: URL) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'openClassDetail', payload: { path: asPathWithAllQuery(path) } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openClassDetail`) : console.error(error)
  }
}
function closeClassDetail() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (!isNative) throw Error('App is not native')
    messageToNativeApp({ type: 'closeClassDetail' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: closeClassDetail`) : console.error(error)
  }
}

function openAppStore() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openAppStore' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openAppStore()`) : console.error(error)
  }
}

function openRenewalAppStore() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openRenewalAppStore' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openRenewalAppStore()`) : console.error(error)
  }
}

function openAppReview() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openAppReview' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openAppReview()`) : console.error(error)
  }
}

export function openAdultVerification(url: string) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openAdultVerification', payload: { url } })
    }
  } catch (error) {
    // Sentry.captureException(error)
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openAdultVerification(${url})`) : console.error(error)
  }
}

function openBrowser(url: string) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({
        type: 'openBrowser',
        payload: { url },
      })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openBrowser(${url})`) : console.error(error)
  }
}

function openZoomLink(params: { id: string; key: string }) {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({
        type: 'openZoomLink',
        payload: { meetingNumber: params.id, tk: params.key },
      })
    }
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: openZoomLink(${JSON.stringify(params)})`)
      : console.error(error)
  }
}

function onBridgeReady() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'onBridgeReady' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: onBridgeReady()`) : console.error(error)
  }
}

function startLearningNoticeView() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'startLearningNoticeView' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: startLearningNoticeView()`) : console.error(error)
  }
}

function openInAppPurchasePage() {
  const isNative = !!window.ReactNativeWebView

  try {
    if (isNative) {
      return messageToNativeApp({ type: 'openInAppPurchasePage' })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: openInAppPurchasePage()`) : console.error(error)
  }
}

function bottomTabControl({ show }: { show: boolean }) {
  const isNative = !!window.ReactNativeWebView
  try {
    if (isNative) {
      return messageToNativeApp({ type: 'bottomTabControl', payload: show })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: bottomTabControl`) : console.error(error)
  }
}

export function buttonClicked(payload: { button: string }) {
  const isNative = !!window.ReactNativeWebView
  try {
    if (isNative) {
      return messageToNativeApp({ type: 'buttonClicked', payload })
    }
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: buttonClicked`) : console.error(error)
  }
}

function preventBackSwipe(payload: { prevent: boolean }) {
  const isNative = !!window.ReactNativeWebView
  try {
    if (!isNative) throw Error('App is not native')
    return messageToNativeApp({ type: 'preventBackSwipe', payload })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: preventBackSwipe`) : console.error(error)
  }
}

// 4.5.0 버전 이후 사용
function onNavigationStateChange(type: ControlType) {
  const isNative = !!window.ReactNativeWebView
  try {
    if (!isNative) throw Error('App is not native')
    return messageToNativeApp({ type: 'onNavigationStateChange', payload: { type } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: onNavigationStateChange`) : console.error(error)
  }
}

function requestMicPermissions() {
  try {
    return messageToNativeApp({ type: 'requestMicPermissions' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: requestMicPermissions`) : console.error(error)
  }
}

function requestNotificationPermissions() {
  try {
    return messageToNativeApp({ type: 'requestNotificationPermissions' })
  } catch (error) {
    BRIDGE_DEBUG
      ? console.info(`BRIDGE-CALL: requestNotificationPermissions`)
      : console.error(error)
  }
}

export type NotificationParams = {
  channelId: string
  date: Date
  body: string
  id?: number
  title?: string
  repeatType?: 'month' | 'week' | 'day' | 'minute'
}
/**
 *  제한사항: iOS에서 64개 이상 알림을 등록할 수 없습니다.
 *  @see https://stackoverflow.com/a/52614542
 */
function setNotifications(notifications: NotificationParams[]) {
  try {
    return messageToNativeApp({ type: 'setNotifications', payload: { notifications } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: setNotifications`) : console.error(error)
  }
}

function deleteNotifications(ids: number[]) {
  try {
    return messageToNativeApp({ type: 'deleteNotifications', payload: { ids } })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: deleteNotifications`) : console.error(error)
  }
}

function goSettings() {
  try {
    return messageToNativeApp({ type: 'goSettings' })
  } catch (error) {
    BRIDGE_DEBUG ? console.info(`BRIDGE-CALL: goSettings`) : console.error(error)
  }
}

const bridge = {
  bottomTabControl,
  buttonClicked,
  clearToken,
  closeClassDetail,
  closeRealLiveDetail,
  closeWebview,
  closeWebviewModal,
  goSettings,
  loadChallenge,
  loadRealLive,
  loadRealLiveDetail,
  openAppStore,
  openRenewalAppStore,
  openAppReview,
  openAdultVerification,
  openBrowser,
  openClass,
  openClassDetail,
  openZoomLink,
  openInAppPurchasePage,
  openWebviewModal,
  onBridgeReady,
  onNavigationStateChange,
  preventBackSwipe,
  setToken,
  startLearningNoticeView,
  requestMicPermissions,
  requestNotificationPermissions,
  setNotifications,
  deleteNotifications,
}

export default bridge
