import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useMe } from '@store/modules/user/swr'

export const LIVE_COURSE_URL = 'live_courseId_url'
export const LIVE_VOD_URL = 'live_vodId_url'

export default function useStoreLiveUrl(courseId: number, vodId: number) {
  const router = useRouter()
  const { data: userData } = useMe()

  function saveLiveUrl() {
    if (courseId && vodId) {
      localStorage.setItem(LIVE_VOD_URL, router.asPath)
    } else if (courseId) {
      localStorage.setItem(LIVE_COURSE_URL, router.asPath)
    }
  }

  useEffect(() => {
    if (userData?.type === 'guest') saveLiveUrl()
  }, [courseId, vodId])
}
