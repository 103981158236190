import dayjs from 'dayjs'
import { useEffect, useLayoutEffect } from 'react'
import shajs from 'sha.js'
import { URL_PREFIX } from '@constants/urls'
import { GNB_HEIGHT as DESKTOP_GNB_HEIGHT } from '@pages/Layout/Web/DesktopLayout/components/GNB/GNB.style'
import { GNB_HEIGHT as MOBILE_GNB_HEIGHT } from '@pages/Layout/Web/MobileLayout/components/GNB/GNB.style'
import bridge from '@components/WebviewInterface/bridgeMethods'
import { isDeployTypeLive } from './deployType'

// 아래 함수에서 obj의 value 값은 함수 실행과 관련 없으므로 any를 허용합니다.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (obj: Record<string, any>) =>
  Object.keys(obj).length === 0 && obj.constructor === Object

export const isNotEmptyArray = (array?: unknown[]) => array && array.length > 0

export const hex2rgba = (hex: string, alpha = 1) => {
  const matches = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16))
  const [r, g, b] = matches || [0, 0, 0]
  return `rgba(${r},${g},${b},${alpha})`
}

// 821012345678
export const normalizePhone = (phone: string) => {
  const numbers = phone.replace(/[^0-9]/g, '')
  if (numbers[0] === '0') {
    return '82' + numbers.slice(1)
  }
  return '82' + numbers
}

// 국제전화 번호체계 표준 형식 (+821012345678)
export const normalizePhoneE164 = (phone: string) => {
  const numbers = phone.replace(/[^0-9]/g, '')
  if (numbers[0] === '0') {
    return '+82' + numbers.slice(1)
  }
  return '+82' + numbers
}

// type utility
export function removeNulls<S>(value: S | undefined | null): value is S {
  return Boolean(value)
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getFullURL() {
  if (typeof window === 'undefined') return ''
  return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
}

export const IOS_APP_DOWNLOAD_LINK =
  'https://apps.apple.com/kr/app/%EB%A6%AC%EC%96%BC%ED%81%B4%EB%9E%98%EC%8A%A4-%EC%98%81%EC%96%B4%EC%9D%B8%EA%B0%95-%EB%A6%AC%EC%96%BC%EC%98%81%EC%83%81/id1322259727'
export const AOS_APP_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.qualson.realclass'

const APP_DYNAMIC_LINK = 'https://realclass.page.link/app_land'
export const appDownload = () => {
  if (typeof window === 'undefined') return

  window.open(APP_DYNAMIC_LINK)
}

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

//MAYBE SOMEDAY.. useEffect 내부에서 sleep 사용한 경우 cleanup 함수 구현
export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const getLocalKey = () => {
  if (typeof window === 'undefined') return
  return window.localStorage.getItem('rc2-debug-mode')
}
const localKey = getLocalKey()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function consoleLog(message: any, ...optionalParams: any[]) {
  const isDebugMode =
    localKey === 'true' || !isDeployTypeLive || process.env.NODE_ENV === 'development'

  if (!isDebugMode) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  console.info(message, ...optionalParams)
}

export const dev = {
  console: {
    log: consoleLog,
  },
}

export const openLink = (isApp: boolean, url: string) => {
  isApp ? bridge.openBrowser(url) : window.open(url, '_blank')?.focus()
}

export function numberToKorean(number: number, changeToSmallerUnits?: boolean) {
  const unitWords = ['', '만', '억', '조', '경']
  const splitUnit = 10000
  const splitCount = unitWords.length
  const resultArray = []
  let resultString = ''

  for (let i = 0; i < splitCount; i++) {
    let unitResult = (number % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i)
    unitResult = Math.floor(unitResult)
    if (unitResult > 0) {
      resultArray[i] = unitResult
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue
    if (changeToSmallerUnits) {
      //ex) 6천만, 6백만, 6600만
      const smallerUnit = smallNumberToKorean(resultArray[i])
      resultString = smallerUnit + unitWords[i] + resultString
    } else {
      //ex) 6000만, 600만, 6600만
      resultString = String(resultArray[i]) + unitWords[i] + resultString
    }
  }

  return resultString
}

function smallNumberToKorean(number: number) {
  const numberStr = number.toString()

  if (number < 100) return numberStr
  else if (number < 1000) {
    return number % 100 === 0 ? `${number / 100}백` : numberStr
  } else {
    return number % 1000 === 0 ? `${number / 1000}천` : numberStr
  }
}

export const convertRemToPixel = (rem: number | string) => {
  const convertRem = typeof rem === 'string' ? +rem.replace('rem', '') : rem
  return convertRem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const shuffle = <T>(arr: T[]): T[] => {
  const cloned = [...arr]
  for (let i = cloned.length; i; i--) {
    const j = Math.floor(Math.random() * i)
    const x = cloned[i - 1]
    cloned[i - 1] = cloned[j]
    cloned[j] = x
  }
  return cloned
}

export const divide2DArr = <T>(arr: T[], n: number): T[][] => {
  const count = arr.length / n
  const twoDimensionalArr = []
  for (let i = 0; i < count; i++) {
    twoDimensionalArr.push(arr.slice(i * n, (i + 1) * n))
  }
  return twoDimensionalArr
}
interface Option {
  inputHyphenType?: '-' | '_'
  outputHyphenType?: '-' | ' '
}

export const capitalizeCourseLevel = (level: string, option?: Option) => {
  const levelWords = level.split(option?.inputHyphenType || '-').map((word) => {
    const [firstLetter, ...restLetter] = word
    return [firstLetter, restLetter.map((l) => l.toLowerCase()).join('')].join('')
  })

  // 마지막 단어만 -로 변경 ex) [Grammar, Pre, Basic] -> Grammar Pre-Basic, [Pre, Basic] -> Pre-Basic
  if (level.includes('PRE'))
    return levelWords
      .join(' ')
      .replace(/ ([^ ]*)$/, '-$1')
      .replace(' ', '\n')
  return levelWords.join('\n')
}

type Key = string | number
type SortOption = { order: 'ascending' | 'descending' }
export function sortByKey<T>(
  data: T[],
  getKey: (item: T) => Key,
  option: SortOption = { order: 'ascending' }
) {
  const compare: [key: Key, index: number][] = data.map((item, index) => {
    const key = getKey(item)
    return [key, index]
  })
  const sorted = compare.sort(([a], [b]) => (a < b ? -1 : 1)).map(([, index]) => data[index])
  return option.order === 'descending' ? sorted.reverse() : sorted
}

// 최신순으로 정렬
export function sortByLatest<T>(data: T[], getKey: (item: T) => string) {
  return sortByKey(data, getKey, { order: 'descending' })
}

export function openOnlyChildWindow(pathname: string, width: number, height: number) {
  const url = `/${URL_PREFIX}${pathname}`

  window.childWindow = window.open(
    `${window.location.origin}${url}`,
    'auth_notification',
    `width=${width}, height=${height}, top=${screen.height / 2 - height / 2}, left=${
      screen.width / 2 - width / 2
    }`
  )
}

export function generateRealStorageKey(key: string) {
  return `rc2-${key}`
}

export const getGnbHeight = (isWeb: boolean, isLarge: boolean) =>
  isWeb ? convertRemToPixel(isLarge ? DESKTOP_GNB_HEIGHT : MOBILE_GNB_HEIGHT) : 0

/**
 * array에서 key를 기준으로 중복을 제거합니다. 순서는 보장되지 않습니다.
 * lodash의 uniqBy 로 대체할 수 있습니다.
 * @param arr 객체의 배열
 * @param key 중복여부를 판단할 객체의 key
 * @example
 * const array = [{ type: '포도' }, { type: '사과' }, { type: '포도' }, { type: '사과' }]
 * getUniqArray(array, 'type') // [{ type: '포도' }, { type: '사과' }]
 */
export function getUniqArray<T extends Record<string, unknown>>(arr: T[], key: keyof T) {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

/**
 * array를 order에 맞게 정렬합니다.
 * @param arr 객체의 배열
 * @param key 정렬 기준으로 삼을 객체의 key
 * @param order 원하는 순서
 * @example
 * const array = [{ type: '포도' }, { type: '사과' }, { type: '귤' }]
 * const order = ['사과', '귤', '포도']
 * sortObjectsByOrder(array, 'type', order) // [{ type: '사과' }, { type: '귤' }, { type: '포도' }]
 */
export function sortObjectsByOrder<T extends Record<string, unknown>>(
  arr: T[],
  key: keyof T,
  order: T[keyof T][]
) {
  return arr.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]))
}

/**
 * array를 order에 맞게 정렬합니다.
 * @param arr number | string의 배열
 * @param order 원하는 순서
 * @example
 * const array = ['포도', '사과', '귤']
 * const order = ['수박', '사과', '감', '귤', '딸기', '포도']
 * sortValuesByOrder(array, order) // ['사과', '귤', '포도']
 */
export function sortValuesByOrder<T>(arr: T[], order: T[]) {
  return arr.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export const regExpForValidUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/

export const fullHeight = (env: 'web' | 'app') => {
  const gnbHeight = env === 'web' ? DESKTOP_GNB_HEIGHT : MOBILE_GNB_HEIGHT
  return `100vh - ${gnbHeight}`
}

// 문장에 대문자 I있어야 할 곳에 l이 있거나 하는 이슈가 있어 l 과 I를 동일 시 취급해야함,  ’ ` '도 마찬가지.
export function replaceCharacter(char: string) {
  return char.replace(/l/g, 'I').replace(/`|’/gi, "'")
}

/**
 * @example { from: 5, to: 7 } => '5,6,7'
 */
export function getNumbersJoiningComma({ from = 1, to }: { from?: number; to: number }) {
  return Array.from({ length: to - from + 1 }, (_, i) => from + i).join(',')
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) return false
  return arr1.every((v, i) => v === arr2[i])
}

export const MS = {
  ONE_DAY: 24 * 60 * 60 * 1000,
}

export function isValidDate(date: string, dateFormat: string) {
  return dayjs(date, dateFormat).isValid()
}

export function getIsValidDates(dates: string[], dateFormat = 'YYYY-MM-DD') {
  return dates.every((date) => isValidDate(date, dateFormat))
}

export function hashString(str: string) {
  if (!str) {
    return ''
  }
  return shajs('sha256').update(str).digest('hex')
}

// css에서 text-overflow: ellipsis를 사용할 때, ellipsis가 적용되었는지 확인하는 함수
export function isEllipsisActive(element: HTMLElement) {
  return element.offsetHeight < element.scrollHeight
}

/**
 *
 * @param str
 * @description
 * 숫자 앞에 붙은 공백 유지하면서 줄바꿈 자연스럽게 유지
 * \u00A0: non-breaking space unicode
 * @see https://qualsonteam.slack.com/archives/G01C49AJH3N/p1718781132382709
 */
export function spaceBeforeNumberToUnicode(str: string) {
  return str.replace(/\s(\d+)/g, '\u00A0$1')
}
