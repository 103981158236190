import { InternalAPI, PrivateAPI, PublicAPI } from '@store/ajax'
import * as T from './types'

export const fetchMe = () => {
  return InternalAPI.get<T.SearchUsersResponse>(`/rc20/me`)
}

export const checkEligibility = ({
  eligibilityTypeCode,
}: {
  eligibilityTypeCode: T.EligibilityTypeCode
}) =>
  PublicAPI.get<T.CheckEligibility>(
    `/util/eligibility?eligibilityTypeCode=${eligibilityTypeCode}`
  ).then((res) => res.data)

export const fetchUsageRightPeriod = () =>
  PrivateAPI.get<T.UsageRightPeriodResponse>('/usage-right-period')
