import type { InAppMessage, ModalMessage } from '@braze/web-sdk'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useMe } from '@store/modules/user/swr'
import { useCouponDownloaders } from '@hooks/coupons/useCouponDownloaders'
import { useModal } from '@hooks/useModal'
import { prefixUserId } from '@utils/gtm'

type Braze = typeof import('@braze/web-sdk')

declare global {
  interface Window {
    braze: Braze
  }
}

/**
 * 브레이즈에서 메시지가 왔을 때, 이런 동작을 합니다.
 */
function withBraze<P>(Component: React.ComponentType<P>) {
  return function BrazeWrapper(props: P & JSX.IntrinsicAttributes) {
    const { data: userData } = useMe()
    const braze = useBraze()
    const { alreadyHasThisCoupon, downloadMCodeCoupon } = useCouponDownloaders()
    const { openedModals } = useModal()

    // 브레이즈 인앱메시지 띄울지 말지 결정하기 : 유저가 쿠폰을 가지고 있다면 띄우지 않기
    useEffect(() => {
      if (!braze) return

      // 브레이즈에서 인앱메시지를 띄우려고 한다. 띄울까 말까?
      braze.subscribeToInAppMessage(function (message) {
        // 다른 모달이 떠 있다면, 인앱메시지를 띄우지 않는다.
        if (openedModals.length > 0) return

        // A/B 테스트의 control 대조군(아무것도 띄우지 않음)인 경우, 실제로는 아무 메시지도 뜨지 않지만 메시지를 띄운 것으로 기록해야 한다.
        if (message.isControl) {
          return braze.showInAppMessage(message)
        }

        const inAppMessage = message as ModalMessage
        if (shouldShowThisMessage(inAppMessage)) {
          braze.showInAppMessage(inAppMessage)
          subscribeEventsFromMessage(inAppMessage)
        }
      })
      return () => braze.removeAllSubscriptions()
    }, [braze])

    // 유저 정보 설정 후 세션 오픈 -> 해당 유저에 맞는 인앱메시지 수신
    useEffect(() => {
      if (!userData) return
      if (!braze) return
      if (userData.type === 'user') {
        braze.changeUser(prefixUserId(userData.id))
        braze.getUser()?.setPhoneNumber(userData.info.phone)
      }
      braze.openSession()
    }, [userData?.type, braze])

    /**
     * mCode가 들어있는 인앱메시지라면, 유저가 이미 이 쿠폰을 가지고 있는지 확인한다.
     * 쿠폰 관련된 메시지가 아니라면 그냥 보여준다.
     */
    function shouldShowThisMessage(inAppMessage: InAppMessage) {
      const mCode = inAppMessage.extras.mCode as string

      if (!mCode) {
        console.info(`withBraze : Just a message Campaign`)
        return true
      }

      if (alreadyHasThisCoupon(mCode)) return false
      return true
    }

    function subscribeEventsFromMessage(message: ModalMessage) {
      const extras = message.extras as { [key: string]: string }
      // 버튼 클릭 관련 이벤트가 있는 경우에만 구독한다.
      if (_.isEmpty(extras)) return

      const { mCode, scrollToId } = extras

      message.buttons[0].subscribeToClickedEvent(() => {
        console.info('withBraze : clicked')

        if (mCode) void downloadMCodeCoupon(mCode)
        if (scrollToId) scrollToElementById(scrollToId)
      })
      message.subscribeToDismissedEvent(() => {
        // user clicked X or background of the message
        console.info('withBraze : dismissed')
      })
    }

    return <Component {...props} />
  }
}

export default withBraze

/**
 * braze SDK는 navigation을 참조하고 있으므로,
 * import * as braze from '@braze/web-sdk' 를 사용하면 SSR 시점에 에러가 난다.
 * SSR 시점에는 import 되지 않도록 dynamic import를 사용해야 한다.
 */
export function useBraze() {
  const [braze, setBraze] = useState<Braze>()
  useEffect(() => {
    void import('@braze/web-sdk').then((brazeSDK) => {
      if (typeof brazeSDK !== 'undefined') {
        setBraze(brazeSDK)

        // 브레이즈 초기화
        brazeSDK.initialize('bc5f02b2-6e10-4a34-a6bc-abd6064d1356', {
          baseUrl: 'sdk.iad-05.braze.com',
          enableLogging: true,
          minimumIntervalBetweenTriggerActionsInSeconds: 5,
        })

        // GTM에서 이벤트를 쏴주기 위해서는 window.braze가 필요하다
        window.braze = brazeSDK
      }
    })
  }, [])
  return braze
}

function scrollToElementById(id: string) {
  const element = document.getElementById(id)
  element?.scrollIntoView({ behavior: 'smooth' })
}
