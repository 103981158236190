import * as Sentry from '@sentry/nextjs'
import _ from 'lodash'
import { useRouter } from 'next/router'
import qs from 'querystring'
import { useEffect } from 'react'
import { saveUTMInfo } from '@store/modules/account/api'
import { LicenseType } from '@store/modules/coupon/models'
import { useMe } from '@store/modules/user/swr'

export const UTM_PARAMS = 'MKT_UTM_PARAMS'

function withMarketing<P>(Component: React.ComponentType<P>) {
  return function Marketing(props: P & JSX.IntrinsicAttributes) {
    const router = useRouter()
    const { data: userData } = useMe()

    // 최초 접속 시, utm_source 라는 쿼리가 있다면
    // 쿼리를 통째로 저장
    useEffect(() => {
      if (router.query.utm_source) {
        sessionStorage.setItem(UTM_PARAMS, qs.stringify(router.query))
      }
    }, [router.query])

    // 소셜 로그인 진행으로 외부 페이지 이탈 -> 소셜 콜백 페이지 복귀 시,
    // 저장했던 쿼리를 불러와 url에 입히는 로직은 SocialCallback에 있다.

    // utm 쿼리가 있는 상태로 진입한적이 있는 경우 서버에 기록
    useEffect(() => {
      if (!userData || userData?.type === 'guest') return

      try {
        const utm = pickUtmParams()
        if (_.isEmpty(utm)) return

        const payload = {
          licenseId: userData.id,
          licenseTypeCode: 'REALCLASS_2' as LicenseType,
          ...utm,
        }
        void saveUTMInfo(payload)
      } catch (e) {
        Sentry.captureException(e)
      }
    }, [userData?.type])

    return <Component {...props} />
  }
}

export default withMarketing

export function pickUtmParams() {
  const utmParams = qs.parse(sessionStorage.getItem(UTM_PARAMS) || '')
  const { utm_source, utm_campaign, utm_medium } = utmParams

  return _.pickBy(
    {
      utmSource: utm_source,
      utmCampaign: utm_campaign,
      utmMedium: utm_medium,
    },
    _.isString
  )
}
