import { fork } from 'typed-redux-saga'
import challengeSagas from '@modules/challenge/sagas'
import memorizeSaga from '@modules/memorize/sagas'
import playerSaga from '@modules/player/sagas'
import studySaga from '@modules/study/sagas'
import studyLogSaga from '@modules/studyLog/sagas'
import studyReportSaga from '@modules/studyReport/sagas'

export default function* rootSaga() {
  yield* fork(studySaga)
  yield* fork(studyLogSaga)
  yield* fork(studyReportSaga)
  yield* fork(memorizeSaga)
  yield* fork(challengeSagas)
  yield* fork(playerSaga)
}
