import styled, { css } from 'styled-components'
import { hex2rgba } from '@utils/utils'
import { onLarge, onSmall } from '@styles/mediaQuery'
import { Colors, Typography } from '@styles/system'

export const ToastWrapper = styled.div`
  position: relative;
  transition: height 0.5s, opacity 0.5s;
  opacity: 1;
  pointer-events: auto;
  &.hiding,
  &.closing {
    opacity: 0;
  }
`

// Action Button의 텍스트 길이에 따라 달라지는 값
// 텍스트 길이가 5자 이상일 경우 long, 4자 이하일 경우 short, 액션 버튼이 없을 경우 none
type ActionType = 'long' | 'short' | 'none'

// onSmall
const smallToastWithAction = ({ type }: { type: ActionType }) => {
  switch (type) {
    case 'long':
      return css`
        display: flex;
        flex-flow: column;
        text-align: left;
        ${ToastAction} {
          align-self: flex-end;
          margin-top: 1.6rem;
        }
      `
    case 'short':
      return css`
        display: flex;
        text-align: left;
        ${ToastAction} {
          margin-left: 2.4rem;
        }
      `
    case 'none':
      return ''
  }
}

// onLarge
const largeToastWithAction = ({ type }: { type: ActionType }) => {
  switch (type) {
    case 'long':
    case 'short':
      return css`
        display: flex;
        text-align: left;
        ${ToastAction} {
          margin-left: 3.2rem;
        }
      `
    case 'none':
      return ''
  }
}

export const Toast = styled.div<{ type: ActionType }>`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-radius: 0.8rem;
  overflow: hidden;
  background: ${hex2rgba(Colors.Grey090, 0.9)};
  text-align: center;
  ${onSmall} {
    width: 34.4rem;
    padding: 1.6rem;
    ${smallToastWithAction};
  }
  ${onLarge} {
    width: 64rem;
    padding: 1.6rem 2.4rem;
    ${largeToastWithAction};
  }
`

export const ToastContent = styled.div`
  flex: 1;
  ${Typography.Body2};
  color: white;
`

export const ToastAction = styled.button`
  flex-shrink: 0;
  top: 1.6rem;
  right: 1.6rem;
  ${Typography.ButtonMedium};
  color: ${Colors.Green050};
`
