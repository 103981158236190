globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"YTkWfkmJIAc1htEGXbh9-"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/new";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'
import { isDeployTypeLive } from './src/utils/deployType'

const { publicRuntimeConfig } = getConfig()

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

isDeployTypeLive &&
  Sentry.init({
    dsn: SENTRY_DSN || 'https://c9c18942107e4df5941658609ddcd820@o350493.ingest.sentry.io/5530356',
    release: publicRuntimeConfig.VERSION,
    sampleRate: 1, // 에러 샘플링
    tracesSampleRate: 0.00005, // 트랜잭션 샘플링
    normalizeDepth: 5,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['sentry-webpack-realclass2'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    beforeSend(event) {
      if (event.breadcrumbs) {
        for (const crumb of event.breadcrumbs) {
          const isFetch = crumb.category === 'fetch'
          const ignoredUrls = ['https://pagead2.googlesyndication.com/pagead/buyside_topics/set/']

          if (
            isFetch &&
            ignoredUrls.some(
              (url) => typeof crumb.data?.url === 'string' && crumb.data?.url.includes(url)
            )
          )
            return null
        }
      }
      return event // 그 외의 모든 이벤트는 그대로 보고함
    },
    ignoreErrors: [
      /^Loading chunk/, // ios에서 자주 발생하지만 유저 영향은 없음 https://qualsonteam.slack.com/archives/G01C49AJH3N/p1665558979337599?thread_ts=1665481569.969669&cid=G01C49AJH3N
      /^Load failed/, // 위와 동일
      'ResizeObserver loop limit exceeded', // 검색결과 유저에게 영향은 없고, 조치할 수 있는 것도 없는 에러라 ignore
      // 아래는 intersection video 때문에 발생하는 에러이나 영상 자동재생이 멈추는것 이외에 유저에게 영향은 없으므로 ignore
      /* start of error */
      /^AbortError: The play\(\) request was interrupted/i,
      'NotAllowedError: play() can only be initiated by a user gesture.',
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
      'AbortError: The operation was aborted.',
      'NotSupportedError: The operation is not supported.',
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      /* end of error */
      "undefined is not an object (evaluating 'e.originalEvent.origin')", // hotjar 에러로 handled error라 ignore
      "Can't find variable: _AutofillCallbackHandler", //user effect는 없는 에러로 보임. iOS 페이스북/인스타그램 인앱 브라우저 한정 발생.
      "Cannot read property 'document' of null", // 채널톡 에러로 handled error라 ignore
      /^Failed to execute 'postMessage' on 'Window'/, // 크로스워크라는 웹뷰 라이브러리를 사용하는 앱에서 접속한 경우
      /^TypeError: Argument not optional/, // ie11PostMessage
      /^timeout of 10000ms exceeded/,
      /^Can't find variable: appboy/, // braze not loaded. safari
      /^appboy is not defined/, // braze not loaded. chrome
      /^NotFoundError: Failed to execute 'transaction' on 'IDBDatabase'/, // moengage could not access to IndexedDB (특정 앱내 웹뷰에서 기능이 비활성화된 경우)
      /^Error: The provided `as` value/, // Nextjs baseURL 뒤로가기 버그
      /^Error: The provided `href`/, // 비슷한 에러로 보이나 정확하게 알 수 없음

      /^TypeError: window.updateWatchingLog is not a function/, // 웹뷰 관련, 유저에게 영향이 없는 에러로 보임.
    ],
  })
