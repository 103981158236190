import Image, { ImageProps } from 'next/image'
import ImageCF from '@utils/cloudfront'

interface Props extends ImageProps {
  src: string
}

const Imgix = ({ src, ...props }: Props) => {
  if (src.startsWith('https://public.realclass.co.kr/images') || src.startsWith('/')) {
    return <Image src={fromImgix(src)} {...props} />
  }
  return <ImageCF src={src} {...props} />
}

export default Imgix

/**
 * https://public.realclass.co.kr/path/img.png
 * => /path/img.png?auto=format
 */
function fromImgix(url: string): string {
  return url.replace(/^https:\/\/public.realclass.co.kr\/images\/(.+)\/(.+)/, '$1/$2?auto=format')
}

/**
 * for `background-image: url(${replaceWithImgix(url)});` />
 * https://public.realclass.co.kr/path/img.png
 * => https://realclass2-public.imgix.net/path/img.png?auto=format
 */
export function replaceWithImgix(url: string): string {
  if (url.startsWith('https://public.realclass.co.kr/images') || url.startsWith('/')) {
    return `https://realclass2-public.imgix.net/${fromImgix(url)}`
  }
  return url
}
