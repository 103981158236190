import { AxiosResponse } from 'axios'
import jwt from 'jsonwebtoken'
import debounce from 'lodash/debounce'
import { Key, SWRConfiguration, mutate, useSWRConfig } from 'swr'
import { ScopedMutator } from 'swr/dist/_internal'
import { B2B_SESSION_TIMEOUT_PATH } from '@constants/urls'
import bridge from '@components/WebviewInterface/bridgeMethods'
import { isB2BService } from '@utils/b2b'
import { blockGuestAndRedirectLogin } from '@utils/blockGuest'
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeAllToken,
  setAccessToken,
  setRefreshToken,
} from '@utils/token'
import { isAxiosError, notAuthorized } from './ajax'
import { DecodedTokenModel } from './modules/account/types'
import { refreshToken } from './modules/auth/api'
import { ME_KEY, USAGE_RIGHT_PERIOD_KEY } from './modules/user/swr'

const SECONDS = 1000
export const SWR_OPTIONS = {
  dedupingInterval: 15 * SECONDS,
  revalidateOnReconnect: false,
}

const withSWRMiddleware = (mutate: ScopedMutator): ScopedMutator => (key, ...params) => {
  const newKey = addUserTokenToPrivateKey(key)
  return mutate(newKey, ...params)
}
/**
 * useSWRConfig의 mutate 대신 이 함수를 사용해주세요.
 */
export function useSWRConfigWithMiddleware() {
  const { mutate, ...swrConfig } = useSWRConfig()
  return { ...swrConfig, mutate: withSWRMiddleware(mutate) }
}

export function createFetcher<T>(api: () => Promise<AxiosResponse<T>>) {
  return () => api().then((res) => res.data)
}

export const swrConfiguration: Partial<SWRConfiguration> = {
  use: [
    (useSWRNext) => (key, fetcher, config) => {
      const newKey = addUserTokenToPrivateKey(key)
      return useSWRNext(newKey, fetcher, config)
    },
  ],

  onError: (error: unknown) => {
    if (!isAxiosError(error)) return
    if (error.response?.status === 401) {
      void tryRefreshTokenOrLogout()
    }

    if (error.response?.status === 403) {
      void notAuthorized()
    }
  },

  errorRetryCount: 3,
  errorRetryInterval: 1000,
}

const tryRefreshTokenOrLogout = debounce(_tryRefreshTokenOrLogout, 200, {
  leading: true,
  trailing: false,
})
async function _tryRefreshTokenOrLogout() {
  // 더이상 accessToken을 사용할 수 없으므로 날린다
  removeAccessToken()

  const isApp = window.location.pathname.includes('/app/')

  try {
    const { data } = await refreshToken({ refreshToken: getRefreshToken() })
    const { access, refresh } = data.result
    setAccessToken(access)
    setRefreshToken(refresh)

    // 토큰이 갱신되었다면 유저 정보도 다시 갱신합니다.
    // 여기에서는 훅을 사용할 수 없기 때문에 global mutate를 사용합니다.
    await withSWRMiddleware(mutate)(ME_KEY)
    await withSWRMiddleware(mutate)(USAGE_RIGHT_PERIOD_KEY)

    if (isApp) {
      bridge.setToken({
        accessToken: access,
        refreshToken: refresh,
        isAppRestart: false,
      })
    }
  } catch (e) {
    removeAllToken()

    if (isB2BService) {
      window.location.href = B2B_SESSION_TIMEOUT_PATH
      return
    }
    blockGuestAndRedirectLogin()
  }
}

function addUserTokenToPrivateKey(key: Key) {
  const token = getAccessToken()
  const isPrivate = JSON.stringify(key)?.includes('@private')
  if (!isPrivate) return key
  if (!token) return null

  const { profile_id } = jwt.decode(token) as DecodedTokenModel
  const privateKey = [key, profile_id]
  return privateKey
}
