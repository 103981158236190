import { InternalAPI, PublicAPI } from '@store/ajax'
import * as T from './types'

export const fetchFormForUpload = (params: T.FetchFormForUploadPayload) =>
  PublicAPI.get<T.FetchFormForUploadResponse>(`/util/survey/form_for_upload`, { params })

export const fetchSurveys = <V>(params: T.FetchSurveysPayload) =>
  PublicAPI.get<T.FetchSurveysResponse<V>>(`/util/survey`, { params })

export const updateSurvey = <V>({ surveyTypeCode, value, key }: T.UpdateSurveyPayload<V>) =>
  PublicAPI.post<T.UpdateSurveyResponse<V>>(
    `/util/survey`,
    { text: value },
    { params: { surveyTypeCode, key } }
  )

export const fetchFileSize = ({ url }: { url: string }) =>
  InternalAPI.get<{ byte: number }>(`/util/file-size?url=${url}`).then((res) => res.data)

/**
 * @param index 삭제할 첨부 파일의 인덱스. 1부터 시작함
 */
export const deleteSurveyFile = ({ surveyTypeCode, index, key }: T.DeleteSurveyPayload) =>
  PublicAPI.post(`/util/survey/delete_attachment`, { index }, { params: { surveyTypeCode, key } })

export const getVideoDuration = (file: File) => {
  const formData = new FormData()
  formData.append('video', file)
  return InternalAPI.post<{ duration: number }>('/util/video-duration', formData)
}
