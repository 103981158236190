import router from 'next/router'
import { all, call, put, select, takeLatest } from 'typed-redux-saga'
import actions from './actions'
import * as API from './common.api'
import { MISSION_ID } from './common.models'

const checkStudyRewardsSaga = function* (
  action: ReturnType<typeof actions.checkStudyRewards.request>
) {
  const {
    // 보여줄 순서대로 입력하기
    missionIds = [MISSION_ID.WEEKLY_CHALLENGE, MISSION_ID.CLASS_COMPLETE],
    onSuccess,
  } = action.payload

  function* treatAsFailure(error: string) {
    yield put(actions.checkStudyRewards.failure({ request: action.payload, error }))
    return action.payload.onFailure?.(error)
  }

  // 챌린지 계정이 아닌 경우
  // 체크하지 않음 -> 보상이 없는 케이스로 빠지게 됨.

  // 보상 확인
  try {
    const { token } = yield* select((state) => state.challenge.token)
    const responses = yield* all(
      missionIds.map((missionId) => call(API.checkMissionReward, { missionId, token }))
    )
    // 획득한 보상이 없는 경우
    if (responses.every((r) => r.data.result.length === 0)) {
      return yield* treatAsFailure('No Rewards found')
    }

    // 보상이 있다면
    yield put(
      actions.checkStudyRewards.success({
        request: { ...action.payload, missionIds },
        response: responses.map((r) => r.data),
      })
    )
    return onSuccess?.(responses.map((r) => r.data))
  } catch (e) {
    return yield* treatAsFailure('REQUEST FAILED')
  }
}

// 챌린지 유저가 아니거나 획득한 보상이 없을 때: 그냥 하던대로 계속 하자
function* checkStudyRewardsFailureSaga(
  action: ReturnType<typeof actions.checkStudyRewards.failure>
) {
  const { noRewardRedirect } = action.payload.request
  if (noRewardRedirect) {
    yield router.push(noRewardRedirect)
  }
}

// 새로 획득한 보상이 있다! 보상 페이지로 이동하자!
function* checkStudyRewardsSuccessSaga() {
  const isApp = window.location.pathname.includes('/app/')
  const path = isApp ? '/app/reward' : '/reward'
  yield router.push(path)
}

export default function* challengeSaga() {
  yield all([
    takeLatest(actions.checkStudyRewards.request, checkStudyRewardsSaga),
    takeLatest(actions.checkStudyRewards.failure, checkStudyRewardsFailureSaga),
    takeLatest(actions.checkStudyRewards.success, checkStudyRewardsSuccessSaga),
  ])
}
