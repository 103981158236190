import { SWRConfig } from 'swr'
import { swrConfiguration } from '@store/swr'

const withSWR = <P,>(Component: React.ComponentType<P>) => {
  const WithSWR = (props: P & JSX.IntrinsicAttributes) => (
    <SWRConfig value={swrConfiguration}>
      <Component {...props} />
    </SWRConfig>
  )
  return WithSWR
}

export default withSWR
