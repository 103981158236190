import styled, { css } from 'styled-components'
import { PromotionTypography } from '@pages/promotion/promotion.typography'
import { onLarge } from '@styles/mediaQuery'
import { COLORS } from '@styles/palette'
import { Colors } from '@styles/system'
import Z_INDEX from '@styles/zIndex'

export const cta = {
  bottom: 'max(1rem, env(safe-area-inset-bottom))',
  height: {
    mo: '6.3rem',
    pc: '8rem',
  },
}

export const CallToAction = styled.button<{ hide?: boolean; disabled?: boolean }>`
  --cta-color: ${COLORS.PROMOTION_GREEN};
  --cta-text-color: white;
  position: fixed;
  bottom: ${cta.bottom};
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s;
  width: 34.5rem;
  height: ${cta.height.mo};
  margin: 0 auto;
  padding: 1.5rem 0;
  background: black;
  border-radius: 0.5rem;
  z-index: ${Z_INDEX.CTA};
  cursor: pointer;

  ${({ hide }) =>
    hide &&
    css`
      transform: translate(-50%, 250%);
    `}
  ${onLarge} {
    width: 49rem;
    height: ${cta.height.pc};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      --cta-color: ${Colors.Grey020};
      --cta-text-color: ${Colors.Grey050};
    `}
`

export const ApplyButton = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: flex-end;

  background: var(--cta-color);
  border-radius: 0.5rem;
  transition: all 0.3s;
  overflow: hidden;

  ${({ active }) =>
    active &&
    css`
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    `}
`

export const MainText = styled.span`
  margin: auto;
  display: flex;
  align-items: center;
  gap: 4.6rem;
  color: var(--cta-text-color);

  ${PromotionTypography.ButtonLarge}
  text-align: center;
  ${onLarge} {
    ${PromotionTypography.H6}
    gap: 9.2rem;
  }
`

export const ArrowWrapper = styled.div`
  position: absolute;

  display: flex;
  margin-right: 4.4rem;

  ${onLarge} {
    margin-right: 6rem;
  }
`
