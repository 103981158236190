import { takeEvery, all } from 'typed-redux-saga'
import { createAPISaga } from '@store/ajax'
import { fetchDayProgressLog, fetchSimpleDaySummary } from './actions'
import * as API from './api'

const fetchSimpleDaySummarySaga = createAPISaga(fetchSimpleDaySummary, API.fetchSimpleDaySummary)
const fetchDayProgressLogSaga = createAPISaga(fetchDayProgressLog, API.fetchDayProgressLog)

export default function* progressStepsSaga() {
  yield all([
    takeEvery(fetchSimpleDaySummary.request, fetchSimpleDaySummarySaga),
    takeEvery(fetchDayProgressLog.request, fetchDayProgressLogSaga),
  ])
}
