import { AxiosError } from 'axios'
import useSWR from 'swr'
import { useMe } from '../user/swr'
import * as API from './api'
import { DefaultCoupon, UserCoupon } from './models'
import * as T from './types'

export const COUPON_PRIVATE_KEY = (params?: T.UserCouponPayload) => ['@coupon/private', params]

export function useUserCoupons(params?: T.UserCouponPayload) {
  const { data: userData, isLoading } = useMe()

  const swr = useSWR<UserCoupon[], AxiosError>(
    userData?.type === 'user' ? COUPON_PRIVATE_KEY(params) : null,
    () => API.fetchUserCoupon(params).then((res) => res.data.result)
  )

  return {
    ...swr,
    isLoading: isLoading || swr.isLoading,
  }
}

export function useDefaultCoupons() {
  return useSWR<DefaultCoupon[], AxiosError>(
    '@coupons/default',
    () => API.fetchDefaultCoupon().then((res) => res.data.result),
    { revalidateOnFocus: false, shouldRetryOnError: false }
  )
}

export function useCouponByHash({ hash }: T.CouponByHashPayload) {
  return useSWR(
    hash ? `@coupons/coupon_plan?hash=${hash}` : null,
    () => API.fetchCouponByHash({ hash }).then((res) => res.data.result),
    { revalidateOnFocus: false, shouldRetryOnError: false }
  )
}

export function useCouponByCode({ code }: T.CouponByCodePayload) {
  return useSWR(
    code ? `@coupons/coupon_plan?code=${code}` : null,
    () => API.fetchCouponByCode({ code }).then((res) => res.data.result),
    { revalidateOnFocus: false, shouldRetryOnError: false }
  )
}
