import { ImageLoaderProps, ImageProps, default as NextImage } from 'next/image'

const IMAGE_PATH_BASE = `https://public.realclass.co.kr/images/`

export function cfLoader({ src }: ImageLoaderProps): string {
  if (src.startsWith('/')) {
    const url = new URL(`${IMAGE_PATH_BASE}${normalizeSrc(src)}`)
    return url.href
  }
  return src
}

function normalizeSrc(src: string): string {
  return src[0] === '/' ? src.slice(1) : src
}

export default function Image(props: ImageProps) {
  return <NextImage {...props} loader={cfLoader} unoptimized />
}
