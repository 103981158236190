import { DRMVideoUrls } from '@modules/study/models'

export enum PageSize {
  SUBTITLE = 10,
  WORD = 20,
}

export interface Clip {
  id: number
  startTime: number
  endTime: number
  name: string
  thumbnailUrl: string
}

export interface Media extends DRMVideoUrls {
  id: number
  seasonId: number
  name: string
  length: number
  videoUrl: string
}

export interface LectureSummary {
  id: number
  titleEng: string
  titleKor: string
  thumbnailUrl: string
}

export interface Lecture extends LectureSummary {
  courseId: number
  dayId: number
  length: number
  videoUrl: string
  thumbnailUrl: string
  lectureNoteMarkdown: string
  teacher: {
    id: string
    name: string
    thumbnailUrl: string
  }
}

export interface Explanation {
  id: number
  explanationMarkdown: string
  explanationSpeaker: {
    id: number
    name: string
    thumbnailUrl: string
  }
  subtitle: number
  subtitleHighlight: string
}

export interface Subtitle {
  id: number
  startTime: number
  endTime: number
  textKor: string
  textEng: string
  thumbnailUrl: string
}

export enum SubtitleFluency {
  NEW,
  CAN_HEAR,
  CAN_SPEAK,
}

export type SubtitleFluencyCounts = [newCount: number, canHearCount: number, canSpeakCount: number]

export interface MemorizeSubtitleSummary {
  id?: number // 사용자 문장 아이디
  subtitleId: number // 문장 아이디
}

export interface MemorizeSubtitle extends MemorizeSubtitleSummary {
  startTime: number
  endTime: number
  textKor: string
  textEng: string
  clip: Clip
  media: Media
  lectures?: Lecture[]
  explanations?: Explanation[]
  thumbnailUrl: string
  isDeleted?: boolean
}

export interface MemorizeWordSummary {
  id: number
  text: string
}

export interface MemorizeWord extends MemorizeWordSummary {
  courseId: number
  dayId: number
  subtitle?: Subtitle
  lecture?: Lecture
  clip?: Clip
  isDeleted?: boolean
}

export interface MemorizeClipWord extends MemorizeWordSummary {
  subtitleId: number
}
