import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import type { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage'
import { generateRealStorageKey } from './utils'

const jotaiSessionStorage = createJSONStorage(() => sessionStorage)
const jotaiLocalStorage = createJSONStorage(() => localStorage)

export function atomWithSessionStorage<T>(key: string, initialValue: T) {
  return atomWithStorage(
    generateRealStorageKey(key),
    initialValue,
    jotaiSessionStorage as SyncStorage<T>
  )
}

export function atomWithLocalStorage<T>(key: string, initialValue: T) {
  return atomWithStorage(
    generateRealStorageKey(key),
    initialValue,
    jotaiLocalStorage as SyncStorage<T>
  )
}
