import { createGlobalStyle } from 'styled-components'

// 웹페이지인 경우 리얼클래스 폰트 사용
const customFont = 'Realclass Sans'

const DEFAULT_FONT_SIZE = 10
const MOBILE_DESIGN_WIDTH = 375
const MOBILE_MIN_WIDTH = 320
const MOBILE_MAX_WIDTH = 440

const FontStyle = createGlobalStyle`

  /* 폰트 스타일 */
  html {
    font: normal 10px ${customFont}; 
    -webkit-font-smoothing: antialiased;
  }

  /* 폰트 사이즈 */
  html {
    /* screen width 441px 이상은 더 커지지 않도록 고정 (11.73px) */
    @media only screen and (min-width: 441px) and (max-width: 800px) {
      font-size: ${DEFAULT_FONT_SIZE * (MOBILE_MAX_WIDTH / MOBILE_DESIGN_WIDTH)}px;

    // root font-size를 10px로 고정시킨다.
    ${({ theme }) => theme.isRootFontFixedTo10px && `font-size: 10px`}

    }
    
    /* 321 ~ 440 screen width에서 375디자인 비율을 유지하도록 root font-size를 vw로 조정 */
    /* 375보다 작은 화면에서 삐져나옴 방지, 375보다 큰 화면에서 양 옆 비어보임 방지 */
    /* 440은 iPhone 12 pro max width 428px 보다 크게 잡음 */
    @media only screen and (min-width: 321px) and (max-width: 440px) {
      font-size: ${(DEFAULT_FONT_SIZE / MOBILE_DESIGN_WIDTH) * 100}vw;
    }
    
    /* screen width 320px 이하는 더 작아지지 않도록 고정 */
    @media only screen and (max-width: 320px) {
      font-size: ${DEFAULT_FONT_SIZE * (MOBILE_MIN_WIDTH / MOBILE_DESIGN_WIDTH)}px;
    }

    // 기본 font-size는 10px 이지만, 데스크탑에서 모바일 디자인을 크게 보여주어햐 하는 경우는 12px 적용
    ${({ theme }) => theme.isMobileDesignOnlySellingPage && `font-size: 12px`}
  }

  /* 기본 텍스트 스타일 */
  body {
    line-height: 1.4;
    word-spacing: -0.07rem;
  }
`
export default FontStyle
