import { all, put, select, takeEvery, delay } from 'typed-redux-saga'
import { createAPISaga } from '@store/ajax'
import {
  fetchMemorizeMediaSubtitles,
  saveMemorizeMediaSubtitles,
  unsaveMemorizeMediaSubtitles,
  fetchMemorizeSubtitles,
  updateMemorizeSubtitle,
  unsaveMemorizeSubtitle,
  fetchMemorizeSubtitleLogs,
  fetchMemorizeWords,
  fetchMemorizeWordSummaries,
  unsaveMemorizeWord,
  fetchMemorizeLectureWords,
  saveMemorizeLectureWord,
  unsaveMemorizeLectureWord,
  fetchMemorizeClipWords,
  saveMemorizeClipWord,
  unsaveMemorizeClipWord,
} from './actions'
import * as API from './api'
import { PageSize } from './models'

const fetchMemorizeMediaSubtitleSaga = createAPISaga(
  fetchMemorizeMediaSubtitles,
  API.fetchMemorizeMediaSubtitles
)

const saveMemorizeMediaSubtitleSaga = createAPISaga(
  saveMemorizeMediaSubtitles,
  API.saveMemorizeMediaSubtitles
)
const unsaveMemorizeMediaSubtitleSaga = createAPISaga(
  unsaveMemorizeMediaSubtitles,
  API.unsaveMemorizeMediaSubtitles
)

const fetchMemorizeSubtitlesSaga = createAPISaga(fetchMemorizeSubtitles, API.fetchMemorizeSubtitles)

const updateMemorizeSubtitleSaga = createAPISaga(updateMemorizeSubtitle, API.updateMemorizeSubtitle)
const updateMemorizeSubtitleSuccessSaga = function* (
  action: ReturnType<typeof updateMemorizeSubtitle.success>
) {
  yield delay(300)
  yield* refetchMemorizeSubtitlesSaga(action.payload.request.subtitleId)
}

const unsaveMemorizeSubtitleSaga = createAPISaga(unsaveMemorizeSubtitle, API.unsaveMemorizeSubtitle)
const unsaveMemorizeSubtitleSuccessSaga = function* (
  action: ReturnType<typeof unsaveMemorizeSubtitle.success>
) {
  yield* refetchMemorizeSubtitlesSaga(action.payload.request.subtitleId)
}

function* refetchMemorizeSubtitlesSaga(subtitleId: number) {
  const { current, subtitles } = yield* select((state) => state.memorize.savedSubtitle)

  const { fluency, categoryId, titleId, seasonId, page, infinite } = current

  // 다시 불러올 페이지 선택
  const subtitleIndex = subtitles.findIndex((s) => s.subtitleId === subtitleId)

  // 무한스크롤: 변경된 자막이 존재하던 페이지를 새로 불러온다
  // 페이지네이션: 현재 페이지를 새로 불러온다
  let reloadPage = infinite ? Math.floor(subtitleIndex / PageSize.SUBTITLE) + 1 : page
  // 단, 현재 페이지의 마지막 한 단어를 삭제한 경우, 이전 페이지를 불러온다.
  if (subtitles.length === 1 && subtitleIndex % PageSize.SUBTITLE === 0) {
    reloadPage = Math.max(1, page - 1)
  }

  yield put(
    fetchMemorizeSubtitles.request({
      categoryId,
      titleId,
      seasonId,
      fluency,
      page: reloadPage,
    })
  )
}

const fetchMemorizeMediaSubtitlesSaga = createAPISaga(
  fetchMemorizeMediaSubtitles,
  API.fetchMemorizeMediaSubtitles
)

const fetchMemorizeSubtitleLogsSaga = createAPISaga(
  fetchMemorizeSubtitleLogs,
  API.fetchMemorizeSubtitleLogs
)

const fetchMemorizeWordsSaga = createAPISaga(fetchMemorizeWords, API.fetchMemorizeWords)

const fetchMemorizeWordSummariesSaga = createAPISaga(
  fetchMemorizeWordSummaries,
  API.fetchMemorizeWordSummaries
)

const unsaveMemorizeWordSaga = createAPISaga(unsaveMemorizeWord, API.unsaveMemorizeWord)
const unsaveMemorizeWordSuccessSaga = function* (
  action: ReturnType<typeof unsaveMemorizeWord.success>
) {
  yield* refetchMemorizeWordsSaga(action.payload.request.memorizeWordId)
  yield put(fetchMemorizeWordSummaries.request({}))
}

function* refetchMemorizeWordsSaga(wordId: number) {
  const { words, current } = yield* select((state) => state.memorize.savedWords)
  const { infinite, page } = current

  const wordIndex = words.findIndex((w) => w.id === wordId)

  // 무한스크롤: 변경된 단어가 존재하던 페이지를 새로 불러온다
  // 페이지네이션: 현재 페이지를 새로 불러온다
  let reloadPage = infinite ? Math.floor(wordIndex / PageSize.WORD) + 1 : page
  // 단, 현재 페이지의 마지막 한 단어를 삭제한 경우, 이전 페이지를 불러온다.
  if (words.length === 1 && wordIndex % PageSize.WORD === 0) {
    reloadPage = Math.max(1, page - 1)
  }

  yield put(fetchMemorizeWords.request({ page: reloadPage }))
}

const fetchMemorizeLectureWordsSaga = createAPISaga(
  fetchMemorizeLectureWords,
  API.fetchMemorizeLectureWords
)

const saveMemorizeLectureWordSaga = createAPISaga(
  saveMemorizeLectureWord,
  API.saveMemorizeLectureWord
)

const unsaveMemorizeLectureWordSaga = createAPISaga(
  unsaveMemorizeLectureWord,
  API.unsaveMemorizeLectureWord
)

const fetchMemorizeClipWordsSaga = createAPISaga(fetchMemorizeClipWords, API.fetchMemorizeClipWords)

const saveMemorizeClipWordSaga = createAPISaga(saveMemorizeClipWord, API.saveMemorizeClipWord)

const unsaveMemorizeClipWordSaga = createAPISaga(unsaveMemorizeClipWord, API.unsaveMemorizeClipWord)

export default function* memorizeSaga() {
  yield all([
    takeEvery(fetchMemorizeMediaSubtitles.request, fetchMemorizeMediaSubtitleSaga),
    takeEvery(saveMemorizeMediaSubtitles.request, saveMemorizeMediaSubtitleSaga),
    takeEvery(unsaveMemorizeMediaSubtitles.request, unsaveMemorizeMediaSubtitleSaga),
    takeEvery(fetchMemorizeSubtitles.request, fetchMemorizeSubtitlesSaga),
    takeEvery(updateMemorizeSubtitle.request, updateMemorizeSubtitleSaga),
    takeEvery(updateMemorizeSubtitle.success, updateMemorizeSubtitleSuccessSaga),
    takeEvery(unsaveMemorizeSubtitle.request, unsaveMemorizeSubtitleSaga),
    takeEvery(unsaveMemorizeSubtitle.success, unsaveMemorizeSubtitleSuccessSaga),
    takeEvery(fetchMemorizeMediaSubtitles.request, fetchMemorizeMediaSubtitlesSaga),
    takeEvery(fetchMemorizeSubtitleLogs.request, fetchMemorizeSubtitleLogsSaga),
    takeEvery(fetchMemorizeWords.request, fetchMemorizeWordsSaga),
    takeEvery(fetchMemorizeWordSummaries.request, fetchMemorizeWordSummariesSaga),
    takeEvery(unsaveMemorizeWord.request, unsaveMemorizeWordSaga),
    takeEvery(unsaveMemorizeWord.success, unsaveMemorizeWordSuccessSaga),
    takeEvery(fetchMemorizeLectureWords.request, fetchMemorizeLectureWordsSaga),
    takeEvery(saveMemorizeLectureWord.request, saveMemorizeLectureWordSaga),
    takeEvery(unsaveMemorizeLectureWord.request, unsaveMemorizeLectureWordSaga),
    takeEvery(fetchMemorizeClipWords.request, fetchMemorizeClipWordsSaga),
    takeEvery(saveMemorizeClipWord.request, saveMemorizeClipWordSaga),
    takeEvery(unsaveMemorizeClipWord.request, unsaveMemorizeClipWordSaga),
  ])
}
