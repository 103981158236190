import useSWR from 'swr'
import { fetchV40ChallengeInfo } from '@store/modules/challenge/main/api'
import { useMe } from '@store/modules/user/swr'
import { SWR_OPTIONS } from '@store/swr'
import useProfile from '@hooks/useProfile'

/**
 * @WARN 게스트 여부는 useMe() 로 별도로 체크해야 한다.
 * @INFO 챌린지 관련 페이지는 대부분 withProtectRoute로 막혀있다.
 *
 * @case 계정/챌린지 정보 로딩중: { isChallengeAccount: null, isChallengeableProfile: null }
 * @case 비 챌린지 계정: { isChallengeAccount: false, isChallengeableProfile: false }
 * @case 챌린지 계정: { isChallengeAccount: true, isChallengeableProfile: boolean }
 */
export const CHALLENGE_INFO_KEY = '@challenge/regular-info'
export function useRegularChallengeInfo() {
  const { data: userData } = useMe()
  const { activeProfile } = useProfile()

  const { data } = useSWR(
    userData?.serviceVersion ? [CHALLENGE_INFO_KEY, userData?.id] : null,
    () => fetchV40ChallengeInfo().then((r) => r?.data.result),
    SWR_OPTIONS
  )

  if (!userData?.serviceVersion || !data || !activeProfile) {
    return {
      // challengeType: data?.realVersion ?? userData?.serviceVersion,
      isChallengeAccount: null,
      isChallengeableProfile: null,
    }
  }
  return {
    isChallengeAccount: !!data.challengeProfileId,
    isChallengeableProfile: data.challengeProfileId === activeProfile?.id,
  }
}
