import { PublicAPI, QMS_API } from '@store/ajax'
import { getRandomThumbnail } from '@utils/thumbnail'
import { QMSLicense } from '../user/models'
import { Tokens } from './models'
import * as T from './types'
import { getLatestTermId } from './utils'

export const emailLogin = async (payload: T.EmailLoginPayload) => {
  const tokens = await QMS_API.post<{ result: Tokens }>('/account/license/v3/login/email', {
    ...payload,
    licenseTypeCode: 'REALCLASS_2',
  }).then((res) => res.data.result)
  return tokens
}

export const emailSignup = async (payload: T.EmailSignupPayload) => {
  const tokens = await QMS_API.post<{ result: { serviceToken: Tokens } }>(
    '/account/license/v3/email',
    {
      ...payload,
      licenseTypeCode: 'REALCLASS_2',
      nickname: payload.accountName?.split('@')[0],
      thumnail: getRandomThumbnail(),
      termId: await getLatestTermId(),
      agreementPush: payload.agreementPush ? 1 : 0,
      profileName: payload.accountName?.split('@')[0],
    }
  ).then((res) => res.data.result.serviceToken)
  return tokens
}

export const socialLogin = async (payload: T.SocialLoginPayload) => {
  const tokens = await QMS_API.post<{ result: Tokens }>('/account/license/v3/login/sns', {
    licenseTypeCode: 'REALCLASS_2',
    snsTypeCode: payload.snsTypeCode,
    snsUniqueId: payload.snsUniqueId,
  }).then((res) => res.data.result)
  return tokens
}

export const socialSignup = async (payload: T.SocialSignupPayload) => {
  const tokens = await QMS_API.post<{ result: { serviceToken: Tokens } }>(
    '/account/license/v3/social',
    {
      ...payload,
      licenseTypeCode: 'REALCLASS_2',
      nickname: payload.email?.split('@')[0],
      thumnail: getRandomThumbnail(),
      termId: await getLatestTermId(),
      agreementPush: payload.agreementPush ? 1 : 0,
      profileName: payload.email?.split('@')[0],
      accountName: payload.email,
      snsClientId: payload.email,
    }
  ).then((res) => res.data.result.serviceToken)
  return tokens
}

export const refreshToken = ({ refreshToken }: { refreshToken: string }) =>
  PublicAPI.post<{ result: Tokens }>('/users/me/token/refresh', {
    refresh: refreshToken,
  })

export const validateToken = async ({ token }: { token: string }) => {
  try {
    await PublicAPI.post('/users/me/token/validate', { access: token })
    return true
  } catch (error) {
    return false
  }
}

export const fetchLicense = async ({ licenseId, token }: { licenseId: number; token: string }) =>
  await QMS_API.get<{ result: QMSLicense }>(`/account/license/v3/${licenseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
