import { createAsyncAction } from '@store/async'
import * as T from './types'

/**
2단계와 정주행의 내 문장 가져오기, 담기, 담기 취소
 */

export const fetchMemorizeMediaSubtitles = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_MEDIA_SUBTITLES_REQUEST',
  '@memorize/FETCH_MEMORIZE_MEDIA_SUBTITLES_SUCCESS',
  '@memorize/FETCH_MEMORIZE_MEDIA_SUBTITLES_FAILURE'
)<T.FetchMemorizeMediaSubtitlesPayload, T.FetchMemorizeMediaSubtitlesResponse>()

export const saveMemorizeMediaSubtitles = createAsyncAction(
  '@memorize/SAVE_MEMORIZE_MEDIA_SUBTITLES_REQUEST',
  '@memorize/SAVE_MEMORIZE_MEDIA_SUBTITLES_SUCCESS',
  '@memorize/SAVE_MEMORIZE_MEDIA_SUBTITLES_FAILURE'
)<T.ToggleMemorizeMediaSubtitlesPayload>()

export const unsaveMemorizeMediaSubtitles = createAsyncAction(
  '@memorize/UNSAVE_MEMORIZE_MEDIA_SUBTITLES_REQUEST',
  '@memorize/UNSAVE_MEMORIZE_MEDIA_SUBTITLES_SUCCESS',
  '@memorize/UNSAVE_MEMORIZE_MEDIA_SUBTITLES_FAILURE'
)<T.ToggleMemorizeMediaSubtitlesPayload>()

/**
 내 문장 쪽에서 사용
 */

export const fetchMemorizeSubtitles = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_SUBTITLES_REQUEST',
  '@memorize/FETCH_MEMORIZE_SUBTITLES_SUCCESS',
  '@memorize/FETCH_MEMORIZE_SUBTITLES_FAILURE'
)<T.FetchMemorizeSubtitlesPayload, T.FetchMemorizeSubtitlesResponse>()

export const updateMemorizeSubtitle = createAsyncAction(
  '@memorize/UPDATE_MEMORIZE_SUBTITLE_REQUEST',
  '@memorize/UPDATE_MEMORIZE_SUBTITLE_SUCCESS',
  '@memorize/UPDATE_MEMORIZE_SUBTITLE_FAILURE'
)<T.UpdateMemorizeSubtitlesPayload, null>()

export const unsaveMemorizeSubtitle = createAsyncAction(
  '@memorize/UNSAVE_MEMORIZE_SUBTITLE_REQUEST',
  '@memorize/UNSAVE_MEMORIZE_SUBTITLE_SUCCESS',
  '@memorize/UNSAVE_MEMORIZE_SUBTITLE_FAILURE'
)<T.UnsaveMemorizeSubtitlePayload, null>()

export const fetchMemorizeSubtitleLogs = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_SUBTITLE_LOG_SEASONS_REQUEST',
  '@memorize/FETCH_MEMORIZE_SUBTITLE_LOG_SEASONS_SUCCESS',
  '@memorize/FETCH_MEMORIZE_SUBTITLE_LOG_SEASONS_FAILURE'
)<undefined, T.FetchMemorizeSubtitleLogsResponse>()

/**
 내 단어 쪽에서 사용
 */

export const fetchMemorizeWords = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_WORDS_REQUEST',
  '@memorize/FETCH_MEMORIZE_WORDS_SUCCESS',
  '@memorize/FETCH_MEMORIZE_WORDS_FAILURE'
)<T.FetchMemorizeWordsPayload, T.FetchMemorizeWordsResponse>()

export const unsaveMemorizeWord = createAsyncAction(
  '@memorize/UNSAVE_MEMORIZE_WORD_REQUEST',
  '@memorize/UNSAVE_MEMORIZE_WORD_SUCCESS',
  '@memorize/UNSAVE_MEMORIZE_WORD_FAILURE'
)<T.UnsaveMemorizeWordPayload, null>()

export const fetchMemorizeWordSummaries = createAsyncAction(
  '@memorize/SEARCH_MEMORIZE_WORD_SUMMARIES_REQUEST',
  '@memorize/SEARCH_MEMORIZE_WORD_SUMMARIES_SUCCESS',
  '@memorize/SEARCH_MEMORIZE_WORD_SUMMARIES_FAILURE'
)<undefined, T.FetchMemorizeWordSummariesResponse>()

/**
 학습의 단어 담기 쪽에서 사용
 */

export const fetchMemorizeLectureWords = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_LECTURE_WORDS_REQUEST',
  '@memorize/FETCH_MEMORIZE_LECTURE_WORDS_SUCCESS',
  '@memorize/FETCH_MEMORIZE_LECTURE_WORDS_FAILURE'
)<T.FetchMemorizeLectureWordsPayload, T.FetchMemorizeLectureWordsResponse>()

export const saveMemorizeLectureWord = createAsyncAction(
  '@memorize/SAVE_MEMORIZE_LECTURE_WORD_REQUEST',
  '@memorize/SAVE_MEMORIZE_LECTURE_WORD_SUCCESS',
  '@memorize/SAVE_MEMORIZE_LECTURE_WORD_FAILURE'
)<T.SaveMemorizeLectureWordPayload, T.SaveMemorizeLectureWordResponse>()

export const unsaveMemorizeLectureWord = createAsyncAction(
  '@memorize/UNSAVE_MEMORIZE_LECTURE_WORD_REQUEST',
  '@memorize/UNSAVE_MEMORIZE_LECTURE_WORD_SUCCESS',
  '@memorize/UNSAVE_MEMORIZE_LECTURE_WORD_FAILURE'
)<T.UnsaveMemorizeLectureWordPayload, null>()

export const fetchMemorizeClipWords = createAsyncAction(
  '@memorize/FETCH_MEMORIZE_CLIP_WORDS_REQUEST',
  '@memorize/FETCH_MEMORIZE_CLIP_WORDS_SUCCESS',
  '@memorize/FETCH_MEMORIZE_CLIP_WORDS_FAILURE'
)<T.FetchMemorizeClipWordsPayload, T.FetchMemorizeClipWordsResponse>()

export const saveMemorizeClipWord = createAsyncAction(
  '@memorize/SAVE_MEMORIZE_CLIP_WORD_REQUEST',
  '@memorize/SAVE_MEMORIZE_CLIP_WORD_SUCCESS',
  '@memorize/SAVE_MEMORIZE_CLIP_WORD_FAILURE'
)<T.SaveMemorizeClipWordPayload, T.SaveMemorizeClipWordResponse>()

export const unsaveMemorizeClipWord = createAsyncAction(
  '@memorize/UNSAVE_MEMORIZE_CLIP_WORD_REQUEST',
  '@memorize/UNSAVE_MEMORIZE_CLIP_WORD_SUCCESS',
  '@memorize/UNSAVE_MEMORIZE_CLIP_WORD_FAILURE'
)<T.UnsaveMemorizeClipWordPayload, null>()

export default {
  fetchMemorizeMediaSubtitles,
  saveMemorizeMediaSubtitles,
  unsaveMemorizeMediaSubtitles,
  fetchMemorizeSubtitles,
  unsaveMemorizeSubtitle,
  updateMemorizeSubtitle,
  fetchMemorizeSubtitleLogs,
  fetchMemorizeWords,
  fetchMemorizeWordSummaries,
  unsaveMemorizeWord,
  fetchMemorizeLectureWords,
  saveMemorizeLectureWord,
  unsaveMemorizeLectureWord,
  fetchMemorizeClipWords,
  saveMemorizeClipWord,
  unsaveMemorizeClipWord,
}
