export type DismissTime = 'session' | number // 'session' | number (days) | -1 (permanent)

export type TargetPage =
  | 'selling'
  | 'payments'
  | 'login/signUp'
  | 'liveHome'
  | 'liveDetail'
  | 'classHome'
  | 'classDetail'
  | 'challengeHome'
  | 'support'

export type TargetPlatform = 'all' | 'webOnly' | 'aosOnly' | 'iosOnly' | 'appOnly'

export type TargetUser = 'all' | 'live' | 'class' | 'challenge' | 'speakingCertified'

export interface NoticeList {
  updated: string
  notices: Omit<Notice, 'body' | 'actions'>[]
}

export interface Notice {
  id: string
  title: string
  exposure: NoticeExposureCondition
  body: { type: 'markdown'; src: string }
  actions: NoticeActions
}

export interface NoticeExposureCondition {
  targetPlatform: TargetPlatform
  targetUser: TargetUser
  targetVersion: string | null
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  targetPages: (TargetPage | string)[]
  blockPages: string[]
  period: { startDate: string; endDate: string }
  priority?: Priority // z-index
}

export interface NoticeActions {
  primaryButton: {
    text: string
    onClick: {
      dismissTime: DismissTime
      outerLink?: string
    }
  }
  secondaryButton?: {
    text: string
    onClick: {
      dismissTime: DismissTime
      outerLink?: string
    }
  }
}

type Priority = 'high' | 'middle' | 'low' // z-index

export function getPriorityClassName(priority?: Priority) {
  switch (priority) {
    case 'high':
      return 'high-priority'
    case 'low':
      return 'low-priority'
    default:
      return 'middle-priority'
  }
}
