import { createContext, useContext, useState, useEffect } from 'react'
import useAppVersion, { DEVICE_TYPE, AppVersion, getAppVersion } from '@hooks/useAppVersion'
import { useScreenContext } from './screen'

interface TNativeAppContext {
  // isApp은 screenContext와 중복이지만, app 분기에 같이 사용하는 경우가 많으므로 한번 더 내려준다
  isApp: boolean
  isIOSApp: boolean
  isAOSApp: boolean
  isTabletApp: boolean
  isNativeApp: boolean // RN
  appVersion: AppVersion
  osVersion: string
}

const NativeAppContext = createContext({} as TNativeAppContext)

export const useNativeAppContext = () => useContext(NativeAppContext)

export const withNativeApp = <P,>(Component: React.ComponentType<P>) => {
  const WithNativeApp = (props: P & JSX.IntrinsicAttributes) => {
    const { isApp, isSmall } = useScreenContext()
    const [isNativeApp, setIsNativeApp] = useState(false)

    const { deviceType, osVersion } = getAppVersion()

    /* userAgent가 있다는 것 자체가 앱이라는 의미이므로 isApp 생략 */
    const isIOSApp = deviceType === DEVICE_TYPE.iPad || deviceType === DEVICE_TYPE.iPhone
    const isAOSApp = deviceType === DEVICE_TYPE.Android
    const isTabletApp = isApp && !isSmall

    const appVersion = useAppVersion()

    useEffect(() => {
      if (typeof window === 'undefined') return
      if (window.ReactNativeWebView) setIsNativeApp(true)
    }, [])

    return (
      <NativeAppContext.Provider
        value={{ isApp, isIOSApp, isAOSApp, isTabletApp, isNativeApp, appVersion, osVersion }}
      >
        <Component {...props} />
      </NativeAppContext.Provider>
    )
  }
  return WithNativeApp
}
