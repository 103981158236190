// import makeServer from 'mocks'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import 'normalize.css'
import { compose } from 'redux'
import 'swiper/css/bundle'
import wrapper from '@store/wrapper'
import withAppContext from '@contexts/index'
import { useNativeAppContext } from '@contexts/nativeApp'
import withBraze from '@hoc/withBraze'
import withJotaiProvider from '@hoc/withJotaiProvider'
import withMarketing from '@hoc/withMarketing'
import withModal from '@hoc/withModal'
import withSWR from '@hoc/withSWR'
import withTheme from '@hoc/withTheme'
import withToast from '@hoc/withToast'
import Notices from '@hooks/notice/Notices'
import useChallengeGuideModal from '@hooks/serviceJson/useChallengeGuideModal'
import usePageRefresh from '@hooks/usePageRefresh'
import { useRouteBreadcrumbStore } from '@hooks/useRouteBreadcrumb'
import BranchIndicator from '@components/BranchIndicator'
import withDefaultErrorBoundary from '@components/ErrorBoundary/DefaultErrorBoundary'
import { useThirdParties } from '@components/Head/ThirdParties'
import Title from '@components/Head/Title'
import { TOOLTIP_ROOT_ID } from '@components/Tooltip/Tooltip'
// dayjs plugin preload
import '@utils/dayjs'
import { useSentryIdentification } from '@utils/sentry'
import Styles from '@styles/index'

const UseAppHook = dynamic(() => import('@hooks/UseAppHook'))
const UseWebHook = dynamic(() => import('@hooks/UseWebHook'))

// if (process.env.NODE_ENV === 'development') {
//   makeServer({ environment: 'development' })
// }

function RealClass({ Component, pageProps }: AppProps) {
  const { isApp } = useNativeAppContext()

  useSentryIdentification()
  useChallengeGuideModal()

  const isMounted = usePageRefresh()

  useThirdParties()
  useRouteBreadcrumbStore()

  return (
    <SessionProvider basePath="/new/api/auth">
      <Title>콘텐츠로 배우는 진짜 영어, 리얼클래스</Title>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"
        />
        {/* https://searchadvisor.naver.com/guide/structured-data-channel */}
        {/* dangerouslySetInnerHTML 을 사용하지 않으면 " => &quot; 로 변해버려 네이버가 파싱을 못한다 */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>
      <Styles />
      {isApp ? <UseAppHook /> : <UseWebHook />}
      <Notices />
      {isMounted && <Component {...pageProps} />}
      <div key="tooltip" id={TOOLTIP_ROOT_ID} />
      <BranchIndicator />
    </SessionProvider>
  )
}

const withGlobalSettings = compose(
  wrapper.withRedux,
  withJotaiProvider,
  withAppContext,
  withTheme,
  withSWR,
  withToast({ autoClose: 2000 }),
  withModal,
  withBraze,
  withMarketing,
  withDefaultErrorBoundary
)
export default withGlobalSettings(RealClass)

export const structuredData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Realclass',
  url: 'https://realclass.co.kr',
  sameAs: [
    'https://blog.naver.com/qualson_realclass',
    'https://tv.naver.com/realclass',
    'https://www.youtube.com/channel/UCfZVS5jlVK2lfOgoTOXrF4g',
    'https://www.facebook.com/realclass',
    'https://www.instagram.com/realclass_official/',
    'https://play.google.com/store/apps/details?id=com.qualson.realclass',
  ],
}
