import { useRouter } from 'next/router'
import { isAxiosError } from '@store/ajax'
import { registerCoupon } from '@store/modules/coupon/api'
import { useUserCoupons } from '@store/modules/coupon/swr'
import { useMe } from '@store/modules/user/swr'
import { useToast } from '@hoc/withToast'
import usePaths from '@constants/paths'
import { logGTMEvent } from '@utils/gtm'
import { generateRealStorageKey } from '@utils/utils'

export const INVITATION_CODE = generateRealStorageKey('INVITATION_CODE')
export const INVITATION_VERSION = generateRealStorageKey('INVITATION_VERSION')
export const MCODE = generateRealStorageKey('MCODE')

/**
 * "쿠폰 받기" 버튼을 눌렀을 때, 이런 동작을 합니다.
 */
export function useCouponDownloaders() {
  const paths = usePaths()
  const router = useRouter()
  const { toast } = useToast()
  const { mutate: mutateUser } = useMe() // 클릭 시 유저 정보를 새로 받아온 것으로 판단한다
  const { data: userCoupons, mutate: mutateCoupons, isLoading } = useUserCoupons()

  function alreadyHasThisCoupon(mCode: string) {
    return Boolean(userCoupons?.some((coupon) => coupon.couponPlan.codeValue === mCode))
  }

  async function downloadMCodeCoupon(mCode: string) {
    const user = await mutateUser()
    if (user?.type !== 'user') {
      localStorage.setItem(MCODE, mCode)
      return router.push(paths.login())
    }
    try {
      await registerCoupon({ codeValue: mCode })
      window.postMessage({ to: '@hoc/withToast/couponDownloaded' })
      logGTMEvent({ event: 'coupon_downloaded', mCode })
      void mutateCoupons()
    } catch (err) {
      // 쿠폰코드로 쿠폰을 찾지 못함 or 이미 가지고 있음
      console.info(`mCode : Cannot download coupon - ${mCode}`)
      toast({ content: '유효하지 않거나 만료된 쿠폰 입니다.' }, { autoClose: 5000 })
    }
  }

  async function downloadInvitationCoupon(invitationCode: string, version: string | null) {
    const user = await mutateUser()
    // 로그인 이후 쿠폰을 받을 수 있도록 초대 쿠폰 코드를 저장한다
    if (user?.type === 'guest') {
      localStorage.setItem(INVITATION_CODE, invitationCode)
      version && localStorage.setItem(INVITATION_VERSION, version)
      return
    }

    try {
      // 로그인 되었다면 쿠폰 다운로드
      await registerCoupon({ invitationCode })

      const content = version
        ? `클래스 메이트 초대 쿠폰 발급 성공! \n내 정보 → 내 쿠폰에서 확인할 수 있어요. 기한 내에 꼭 사용해주세요 :)`
        : `2024년 7월 2일부터 클래스 메이트 초대 쿠폰 금액이 3만원으로 변경되었습니다. \n발급된 쿠폰을 기한 내 사용해 주세요 :)`

      window.postMessage({
        to: '@hoc/withToast/couponDownloaded',
        payload: { content },
      })
      void mutateCoupons()
    } catch (err) {
      console.info(`invitationCode : Cannot download coupon - ${JSON.stringify(err)}`)
      if (isAxiosError(err) && err.response?.status === 404) {
        // invitationCode가 유효하지 않음. 쿠폰 못찾음
        toast({ content: '유효하지 않은 초대 코드 입니다.' }, { autoClose: 5000 })
      }
    }
  }

  return {
    alreadyHasThisCoupon,
    mutateCoupons,
    downloadMCodeCoupon,
    downloadInvitationCoupon,
    isLoading,
  }
}
