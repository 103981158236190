import axios from 'axios'
import { all, call, put, takeLatest, take, race } from 'typed-redux-saga'
import { saveStudyToken } from '@modules/challenge/actions'
import { createAPISaga, createLoggingSaga, errorHandlerSaga } from '@store/ajax'
import {
  fetchClipLogs,
  updateClipLog,
  fetchTrainingLogs,
  updateTrainingLog,
  fetchCoachingLogs,
  skipCoachingLog,
  updateCoachingLog,
  submitCoachingLog,
  fetchCourseMediaLogs,
  fetchSeasonMediaLogs,
  fetchMediaLog,
  updateMediaLog,
  fetchDailyCoachingLogs,
} from './actions'
import * as API from './api'

const fetchClipLogsSaga = createAPISaga(fetchClipLogs, API.fetchClipLogs)
const updateClipLogSaga = createLoggingSaga(updateClipLog, API.updateClipLog)
const fetchTrainingLogsSaga = createAPISaga(fetchTrainingLogs, API.fetchTrainingLogs)
const updateTrainingLogSaga = createLoggingSaga(updateTrainingLog, API.updateTrainingLog)
const fetchCoachingLogsSaga = createAPISaga(fetchCoachingLogs, API.fetchCoachingLogs)
const updateCoachingLogSaga = createLoggingSaga(updateCoachingLog, API.updateCoachingLog)
const fetchCourseMediaLogsSaga = createAPISaga(fetchCourseMediaLogs, API.fetchCourseMediaLogs)
const fetchSeasonMediaLogsSaga = createAPISaga(fetchSeasonMediaLogs, API.fetchSeasonMediaLogs)
const fetchMediaLogSaga = createAPISaga(fetchMediaLog, API.fetchMediaLog)
const updateMediaLogSaga = createLoggingSaga(updateMediaLog, API.updateMediaLog)
const fetchDailyCoachingLogsSaga = createAPISaga(fetchDailyCoachingLogs, API.fetchDailyCoachingLogs)

function* skipCoachingLogSaga(action: ReturnType<typeof skipCoachingLog>) {
  const { courseId, dayId, coachingId, onSuccess, onFinish } = action.payload
  try {
    yield* put(
      updateCoachingLog.request({
        courseId,
        dayId,
        coachingId,
        isCompleted: true,
        isSkipped: true,
      })
    )
    // wait for log response
    const { success } = yield* race({
      success: take(updateCoachingLog.success),
      failure: take(updateCoachingLog.failure),
    })

    if (success) {
      onSuccess?.(true)
    }
  } catch (error) {
    yield* errorHandlerSaga(error, action)
  } finally {
    onFinish?.()
  }
}
function* submitCoachingLogSaga(action: ReturnType<typeof submitCoachingLog>) {
  try {
    const { courseId, dayId, coachingId, record, onSuccess, onFailure } = action.payload

    // STEP 1. get upload url
    // * 이때 서버에서는 어떤 사용자의 어떤 코칭 기록이 어떤 url에 업로드될지 (응답을 주면서) 알게 된다
    const response = yield* call(API.fetchUploadUrl, action.payload)
    const { formData: S3Credentials, postUrl } = response.data.result

    // STEP 2. upload
    const file = new File([record], `${coachingId}.wav`, { type: 'audio/wav' })
    const formData = new FormData()
    for (const key in S3Credentials) {
      formData.append(key, S3Credentials[key])
    }
    formData.append('file', file)
    yield* call(axios.post, postUrl, formData)

    // STEP 3. send log
    // * 서버는 STEP 1에서 건네주었던 url을 확인하고, 파일이 업로드 되었는지 확인한다.
    yield* put(
      updateCoachingLog.request({
        courseId,
        dayId,
        coachingId,
        isCompleted: true,
        isSkipped: false,
      })
    )
    // wait for log response
    const { success, failure } = yield* race({
      success: take(updateCoachingLog.success),
      failure: take(updateCoachingLog.failure),
    })

    // STEP 4. callback with submit result

    // 인식 성공. 이지만 정확도가 50% 일 수도 있다.
    if (success) {
      const successAction = success
      const analyzeLog = successAction.payload.response.result
      onSuccess?.(analyzeLog)
    }

    // 아예 못 알아들은 경우.
    if (failure) {
      onFailure?.(failure)
    }
  } catch (error) {
    yield* errorHandlerSaga(error, action)
  }
}

type commonLoggerAction =
  | ReturnType<typeof fetchClipLogs.success>
  | ReturnType<typeof fetchTrainingLogs.success>
  | ReturnType<typeof fetchCoachingLogs.success>

function* updateChallengeTokenSaga(action: commonLoggerAction) {
  const { request, response } = action.payload
  const stepRegex = /@studyLog\/FETCH_(\w+)_LOGS_SUCCESS/
  const match = stepRegex.exec(action.type)
  const step = match ? match[1] : 'UNKNOWN'
  const startDatetimeToken = response.common.startDatetimeToken
  const payload = { dayStep: `${request.dayId}-${step}`, token: startDatetimeToken }

  yield put(saveStudyToken(payload))
}

export default function* studyLogSagas() {
  yield all([
    takeLatest(fetchClipLogs.request, fetchClipLogsSaga),
    takeLatest(updateClipLog.request, updateClipLogSaga),
    takeLatest(fetchTrainingLogs.request, fetchTrainingLogsSaga),
    takeLatest(updateTrainingLog.request, updateTrainingLogSaga),
    takeLatest(fetchCoachingLogs.request, fetchCoachingLogsSaga),
    takeLatest(updateCoachingLog.request, updateCoachingLogSaga),
    takeLatest(skipCoachingLog, skipCoachingLogSaga),
    takeLatest(submitCoachingLog, submitCoachingLogSaga),
    takeLatest(fetchCourseMediaLogs.request, fetchCourseMediaLogsSaga),
    takeLatest(fetchSeasonMediaLogs.request, fetchSeasonMediaLogsSaga),
    takeLatest(fetchMediaLog.request, fetchMediaLogSaga),
    takeLatest(updateMediaLog.request, updateMediaLogSaga),
    takeLatest(fetchDailyCoachingLogs.request, fetchDailyCoachingLogsSaga),
    takeLatest(
      [fetchClipLogs.success, fetchTrainingLogs.success, fetchCoachingLogs.success],
      updateChallengeTokenSaga
    ),
  ])
}
