import { useEffect, useState } from 'react'
import { useMe } from '@store/modules/user/swr'
import { useModal } from '@hooks/useModal'
import { useIsomorphicLayoutEffect } from '@utils/utils'

export default function usePageRefresh() {
  const { closeAll } = useModal()
  const [isMounted, setState] = useState(true)
  const { data: userData, mutate: mutateUser } = useMe()

  // 참고: 챌린지/내 정보 탭은 웹뷰 자체를 새로고침 -> 리덕스 정보도 날라감
  // 예외: 유저정보가 없을 때(로그인/가입 페이지에서)는 유저정보를 부르지 않음
  // (클래스/학습내역) 탭 변경이 일어남. 언마운트 앤드 리마운트 -> 페이지 렌더링에 필요한 API를 새로 불러옴
  // 하지만 redux는 그대로 보존됨. -> 유저정보는 리덕스에 저장된 정보를 사용하기 때문에 새로 불러오지 않아 유저정보만 다시 호출해줌.
  // 열려있던 모달은 돌아왔을 때 모두 닫아줌!
  const refresh = () => {
    setState(false)
    closeAll()
    if (userData?.id) {
      void mutateUser()
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (!isMounted) {
      setState(true)
    }
  }, [isMounted])

  useEffect(() => {
    window.refreshPage = refresh
  }, [])

  return isMounted
}
