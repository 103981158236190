import { PrivateAPI } from '@store/ajax'
import * as T from './types'

export const fetchLogReport = () => PrivateAPI.get<T.FetchLogReportResponse>(`/study/report`)
export const fetchRecentMedia = () =>
  PrivateAPI.get<T.FetchRecentMediaResponse>(`/study/recent/media`)

export const fetchRecentCourses = () =>
  PrivateAPI.get<T.FetchRecentCoursesResponse>(`/study/recent/courses`)

export const fetchRecentCoaching = () =>
  PrivateAPI.get<T.FetchRecentCoachingResponse>(`/study/recent/coaching`)

export const fetchRecentCoachingDay = ({ courseId }: { courseId: number }) =>
  PrivateAPI.get<T.FetchRecentCoachingResponse>(`/study/recent/coaching?courseId=${courseId}`)

export const fetchCourseIdsOfCoachingLogExists = () =>
  PrivateAPI.get<T.FetchCourseIdsOfCoachingLogExistsResponse>(`/study/coaching-log-courses`)

export const fetchCalendar = (payload: T.FetchCalendarPayload) =>
  PrivateAPI.get<T.FetchClassCalendarResponse>('/study/calendar', { params: payload })

export const fetchLiveCalendar = (payload: T.FetchCalendarPayload) =>
  PrivateAPI.get<T.FetchLiveCalendarResponse>('/live/study/calendar', { params: payload })

export const fetchTokTokCalendar = (payload: T.FetchCalendarPayload) =>
  PrivateAPI.get<T.FetchTokTokCalendarResponse>('/talks/calendar', { params: payload })
