import { PrivateAPI40, QMS_API } from '@store/ajax'
import * as T from './common.types'

// 아래 API는 구매한 상품에 따라 서버쪽 분기가 타므로, API 주소 분기가 필요하지 않음.
export const fetchChallengeDayStatus = ({ courseId }: { courseId: number }) =>
  PrivateAPI40.get<T.FetchChallengeDayStatusResponse>(`/challenge/courses/${courseId}/days`)

// 챌린지 일자 선택 버튼 클릭 로그를 남기기 위한 API
export const tryChallengeDay = ({ courseId, dayId }: { courseId: number; dayId: number }) =>
  PrivateAPI40.get(`/challenge/courses/${courseId}/days/${dayId}`)

export const selectChallengeDay = ({
  courseId,
  dayId,
  coords,
  retry,
}: T.SelectChallengeDayPayload) =>
  PrivateAPI40.post(
    `/challenge/courses/${courseId}/days/${dayId}`,
    { retry },
    {
      params: { ...coords },
    }
  )

// 디버깅용: 일자 선택 취소
// export const unselectChallengeDay = () => PrivateAPI40.delete('/challenge/unselect')

// 챌린지 일자 선택 팝업에서 "다시 선택할게요"를 눌렀다는 로그를 남기기 위한 API
export const cancelChallengeDay = ({ courseId, dayId, coords }: T.SelectChallengeDayPayload) =>
  PrivateAPI40.delete(`/challenge/courses/${courseId}/days/${dayId}`, { params: { ...coords } })

export const checkMissionReward = ({ missionId, token }: T.CheckMissionRewardPayload) =>
  QMS_API.get<T.CheckMissionRewardResponse>('/mission-reward/check', {
    params: { missionId, startDateTimeToken: token },
  })
