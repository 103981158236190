import useSWR from 'swr'
import { useRegularChallengeInfo } from '@hooks/challenge/useChallengeInfo'
import { RewardTypeForClient } from '@pages/challenge/history/reward/RewardDetail/utils'
import * as API from './api'
import * as domain from './domain'
import { ChallengeGroupTypeCode, MissionType } from './domain'
import { mergeContinuousChallenges } from './swr.helpers'

const SECONDS = 1000

// https://qualsonteam.slack.com/archives/C018JEH2FGQ/p1690337606384019?thread_ts=1689843884.798929&cid=C018JEH2FGQ
interface UseChallengesProps {
  hideEnded?: boolean
}
/**
 * @props hideEnded profiles/me/challenges 최적화 옵션
 * @return hideEnded가 true일 경우, 종료된지 14일이 되지 않은 챌린지만 api가 리턴
 */
export function useRawChallenges({ hideEnded = false }: UseChallengesProps = {}) {
  const { isChallengeableProfile } = useRegularChallengeInfo()
  const key = isChallengeableProfile ? [`@private/challenges`, hideEnded] : null

  return useSWR(
    key,
    () =>
      API.fetchV40Challenges(hideEnded).then((res) => ({ ...res.data, date: res.headers.date })),
    { dedupingInterval: 2 * SECONDS }
  )
}

/**
 *
 * @description challenges api를 사용할 때 더이상 레거시 데이터는 사용하지 않기 때문에 일반적으로 레거시는 제외하고 사용합니다.
 */
export function useChallenges(props: UseChallengesProps = {}) {
  const challengesResponse = useRawChallenges(props)
  const challenges = challengesResponse?.data?.result

  const POINT_CHALLENGE_ID = 3 // 2.5BM 포인트 챌린지
  const ONBOARDING_WEEKLY_CHALLENGE_ID = 10
  const legacyChallengeIds = [POINT_CHALLENGE_ID, ONBOARDING_WEEKLY_CHALLENGE_ID]

  return {
    ...challengesResponse,
    // 제외된 이유에 대한 스레드
    // https://qualsonteam.slack.com/archives/C018JEH2FGQ/p1675732444016549?thread_ts=1675667630.929479&cid=C018JEH2FGQ
    data: challenges?.filter((c) => !legacyChallengeIds.includes(c.challengeId)),
    date: challengesResponse?.data?.date,
  }
}

/**
 * @memo 같은 타입의 챌린지를 병합
 * 단, 앞 항목의 endDate와 뒤 항목의 startDate가 하루차이 이내라면 병합
 */
export function useMergedChallenges({ hideEnded = false }: UseChallengesProps = {}) {
  const { data: challenges, ...props } = useChallenges({ hideEnded })

  return { data: mergeContinuousChallenges(challenges), ...props }
}

export function useChallengesByType(type: ChallengeGroupTypeCode) {
  const { data: allChallenges } = useMergedChallenges()

  return domain.getChallengesByType(type, allChallenges)
}

export function useOngoingChallenges() {
  const { data: allChallenges } = useChallenges({ hideEnded: true })
  if (!allChallenges) return undefined

  return allChallenges.filter(domain.isOngoingChallenge)
}

export function useOngoingChallengesByMissionType(type: MissionType) {
  const ongoingChallenges = useOngoingChallenges()
  const challenges = domain.getChallengesByMissionType(type, ongoingChallenges)

  return challenges
}

/**
 * 리워드 관련
 */
export function useRewardIds() {
  const { data: allChallenges } = useMergedChallenges()

  return domain.getRewardIds(allChallenges)
}

/**
 * 특정 챌린지 타입의 모든 리워드id가 필요할 때 사용
 */
export function useRewardIdsByType(type: RewardTypeForClient) {
  const { data: allChallenges } = useChallenges()
  if (!allChallenges) return undefined
  if (!isChallengeGroupTypeCode(type)) return undefined

  const challenges = domain.getChallengesByType(type, allChallenges)
  return domain.getRewardIds(challenges)
}

export function isChallengeGroupTypeCode(
  type: RewardTypeForClient
): type is ChallengeGroupTypeCode {
  return Object.values(domain.CHALLENGES_TYPE).includes(type)
}
