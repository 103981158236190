import { createReducer } from '@reduxjs/toolkit'
import {
  fetchClipLogs,
  updateClipLog,
  fetchTrainingLogs,
  updateTrainingLog,
  fetchCoachingLogs,
  updateCoachingLog,
  fetchCourseMediaLogs,
  fetchSeasonMediaLogs,
  fetchMediaLog,
  updateMediaLog,
  fetchDailyCoachingLogs,
} from './actions'
import { LectureLog, ClipLog, TrainingLog, CoachingLog, MediaLog } from './models'
import { UpdateLogResponse } from './types'

interface State {
  token: string
  profileNickname: string
  lectures: {
    [lectureId: number]: LectureLog
  }
  clip: {
    [clipId: number]: ClipLog
  }
  training: {
    currentTrainingId: number
    [trainingId: number]: TrainingLog
  }
  coaching: {
    [coachingId: number]: CoachingLog
  }
  media: {
    [mediaId: number]: MediaLog
  }
  // 내 코칭내역, n일차에 학습기록이 있는지- 없는지-
  myCoachings: {
    [courseId: number]: { day: number; dayId: number }[]
  }
}

const initialState: State = {
  token: '',
  profileNickname: '',
  lectures: {},
  clip: {},
  training: {
    currentTrainingId: 0,
  },
  coaching: {},
  media: {},
  myCoachings: {},
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchClipLogs.success, (state, action) => {
      const { common, result } = action.payload.response
      return {
        ...state,
        token: common.startDatetimeToken,
        clip: {
          ...state.clip,
          ...convertLogs(result, 'clipId'),
        },
      }
    })
    .addCase(updateClipLog.success, updateToken)
    .addCase(fetchTrainingLogs.success, (state, action) => {
      const { common, result } = action.payload.response

      return {
        ...state,
        token: common.startDatetimeToken,
        training: {
          ...state.training,
          currentTrainingId: common.currentTrainingId,
          ...convertLogs(result, 'trainingId'),
        },
      }
    })
    .addCase(updateTrainingLog.success, updateToken)
    .addCase(fetchCoachingLogs.success, (state, action) => {
      const { common, result } = action.payload.response
      return {
        ...state,
        token: common.startDatetimeToken,
        profileNickname: common.profileNickname,
        coaching: {
          ...state.coaching,
          ...convertLogs(result, 'coachingId'),
        },
      }
    })
    .addCase(updateCoachingLog.success, updateToken)
    .addCase(fetchCourseMediaLogs.success, (state, action) => {
      const { result } = action.payload.response
      return {
        ...state,
        media: {
          ...state.media,
          ...convertLogs(result, 'mediaId'),
        },
      }
    })
    .addCase(fetchSeasonMediaLogs.success, (state, action) => {
      const { result } = action.payload.response
      return {
        ...state,
        media: {
          ...state.media,
          ...convertLogs(result, 'mediaId'),
        },
      }
    })
    .addCase(fetchMediaLog.success, (state, action) => {
      const { common, result } = action.payload.response
      return {
        ...state,
        token: common.startDatetimeToken,
        media: {
          ...state.media,
          [result.mediaId]: result,
        },
      }
    })
    .addCase(updateMediaLog.success, (state, action) => {
      const { common, result } = action.payload.response

      return {
        ...state,
        token: common.startDatetimeToken,
        media: {
          ...state.media,
          [result.mediaId]: result,
        },
      }
    })
    .addCase(fetchDailyCoachingLogs.success, (state, action) => {
      const { courseId } = action.payload.request
      const { common, result } = action.payload.response

      // dayId === 0 means: LOG NOT DETECTED
      const days = Array(common.numberOfDays)
        .fill(0)
        .map((_, i) => ({ day: i + 1, dayId: 0 }))
      result.forEach(({ dayId, day }) => {
        days[day - 1].dayId = dayId
      })

      return {
        ...state,
        myCoachings: {
          ...state.myCoachings,
          [courseId]: days,
        },
      }
    })
)

/**
 * transform Log[] to { [log.Id]: Log }
 */
function convertLogs<L>(logs: L[], key: keyof L) {
  const entries: [number, L][] = logs.map((l) => [(l[key] as unknown) as number, l])
  return Object.fromEntries(entries)
}

type UpdateLogAction = { type: string; payload: { response: UpdateLogResponse } }
function updateToken(state: State, action: UpdateLogAction) {
  const { common } = action.payload.response
  return {
    ...state,
    token: common.startDatetimeToken,
  }
}
