import { createAsyncAction } from '@store/async'
import playlistActions from './playlist/actions'
import progressStepsActions from './progressSteps/actions'
import {
  FetchClipPayload,
  FetchClipResponse,
  FetchTrainingsPayload,
  FetchTrainingsResponse,
  FetchCoachingsPayload,
  FetchCoachingsResponse,
  FetchFurtherMediaPayLoad,
  FetchFurtherMediaResponse,
} from './types'

export const fetchClip = createAsyncAction(
  '@study/FETCH_CLIPS_REQUEST',
  '@study/FETCH_CLIPS_SUCCESS',
  '@study/FETCH_CLIPS_FAILURE'
)<FetchClipPayload, FetchClipResponse>()

export const fetchTrainings = createAsyncAction(
  '@study/FETCH_TRAININGS_REQUEST',
  '@study/FETCH_TRAININGS_SUCCESS',
  '@study/FETCH_TRAININGS_FAILURE'
)<FetchTrainingsPayload, FetchTrainingsResponse>()

export const fetchCoachings = createAsyncAction(
  '@study/FETCH_COACHINGS_REQUEST',
  '@study/FETCH_COACHINGS_SUCCESS',
  '@study/FETCH_COACHINGS_FAILURE'
)<FetchCoachingsPayload, FetchCoachingsResponse>()

export const fetchFurtherMedia = createAsyncAction(
  '@study/FETCH_FURTHER_MEDIA_REQUEST',
  '@study/FETCH_FURTHER_MEDIA_SUCCESS',
  '@study/FETCH_FURTHER_MEDIA_FAILURE'
)<FetchFurtherMediaPayLoad, FetchFurtherMediaResponse>()

export default {
  fetchClip,
  fetchTrainings,
  fetchCoachings,
  fetchFurtherMedia,
  ...playlistActions,
  ...progressStepsActions,
}
