import { css, keyframes } from 'styled-components'
import { fullHeight } from '@utils/utils'
import { onLarge } from './mediaQuery'
import { Colors } from './system'

export const aspectRatio16_9 = css`
  position: relative;
  height: 0;
  padding-top: 56.25%;
`

export const aspectRatio9_16 = css`
  position: relative;
  height: 0;
  padding-top: 177.78%;
`

export const aspectRatio_inside = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const fillParent = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const makeGradient = (
  gradientFrom: string,
  gradientTo: string,
  direction = '90deg',
  gradientStart = '',
  gradientEnd = ''
) => `linear-gradient(${direction}, ${gradientFrom} ${gradientStart},${gradientTo} ${gradientEnd})`

export const gradientText = (
  gradientFrom: string,
  gradientTo: string,
  direction = '90deg',
  gradientStart = '',
  gradientEnd = ''
) => css`
  color: ${gradientFrom}; /* for IE */
  background: ${makeGradient(gradientFrom, gradientTo, direction, gradientStart, gradientEnd)};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const packageGradient = (defaultColor: string, gradient?: string) =>
  gradient
    ? css`
        color: ${defaultColor}; /* for IE */
        background: ${gradient};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
    : css`
        color: ${defaultColor};
      `

export const responsiveVh = (vh?: number) => `calc(var(--responsive-vh, 1vh) * ${vh ?? 100})`

export const absoluteFill = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const multipleLineEllipsis = (clamp: number) => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${clamp};
  -webkit-box-orient: vertical;
`

export const TextMatrix = css`
  transform-origin: 0 100%;
  transform: matrix(1, -0.11, -0.02, 0.99, 0, 0);
`

const shimmerAnimation = keyframes`
  0% {
    background-position: -100rem 0;
  }
  100% {
    background-position: 100rem 0;
  }
`
export const Shimmer = css`
  animation: ${shimmerAnimation} 2s infinite linear;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 100rem 100%;
`

interface InfoMiniModalProps {
  width: string
  padding?: string
  borderColor?: string
  tailPosition?: { left?: string; right?: string }
}
export function infoMiniModal({ width, padding, borderColor, tailPosition }: InfoMiniModalProps) {
  const boxStyle = css`
    position: relative;
    padding: ${padding ?? '1.2rem 1.4rem'};
    width: ${width};
    height: fit-content;
    border: 1px solid ${borderColor ?? Colors.Green050};
    border-radius: 0.8rem;
    background: white;
    filter: drop-shadow(0px 0px 12px rgba(162, 167, 173, 0.24));
  `

  const tailBaseStyle = css`
    content: '';
    display: block;
    position: absolute;
    top: -0.4rem;
    left: 50%;
    width: 0.8rem;
    height: 0.8rem;

    border: 1px solid ${borderColor ?? Colors.Green050};
    border-left-color: white;
    border-bottom-color: white;
    background-color: white;
    border-radius: 0.1rem;
    transform: translateX(-50%) rotate(-45deg);
  `
  const tailStyle =
    tailPosition &&
    css`
      left: ${tailPosition.left ?? 'initial'};
      right: ${tailPosition.right ?? 'initial'};
      transform: rotate(-45deg);
    `

  return css`
    ${boxStyle}
    ::after {
      ${tailBaseStyle} // tail 위치가 없으면 default는 가운데
      ${tailStyle}
    }
  `
}

const TABLET_MODAL_HEADER = '72px'
export const fillScreenHeight = css`
  ${({ theme }) =>
    theme.isApp &&
    css`
      ${onLarge} {
        min-height: calc(100vh - ${TABLET_MODAL_HEADER});
      }
      min-height: calc(${fullHeight('app')});
    `}

  ${({ theme }) =>
    theme.isWeb &&
    css`
      min-height: calc(${fullHeight('web')});
    `}
`

export const redDot = css`
  width: 4px;
  height: 4px;
  background-color: ${Colors.ErrorRed};
  border-radius: 50%;
`

/** Real Class Design system에 보이는 스크롤바 */
export const innerContainerScrollbar = css`
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Grey040};
    background-clip: padding-box;
    border-radius: 4px;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`

type Direction = 'top' | 'left' | 'right' | 'bottom'

interface TooltipTailProps {
  direction: Direction
  base: string // 밑변
  height: string // 높이
  color: string
}

export function tooltipTail({ direction, base, height, color }: TooltipTailProps) {
  // border는 이음새 보정용
  const baseStyle = `
    content: '';
    position: absolute;
    width: ${base};
    height: ${height};
    border: 1px solid ${color}; 
    background: ${color};
  `

  const directionStyle = (function () {
    switch (direction) {
      case 'top':
        return css`
          top: calc(${height} * -1);
          left: 50%;
          transform: translateX(-50%);
          clip-path: polygon(50% 0%, 0 100%, 100% 100%);
        `
      case 'left':
        return css`
          width: ${height};
          height: ${base};
          left: calc(${height} * -1);
          top: 50%;
          transform: translateY(-50%);
          clip-path: polygon(0% 50%, 100% 0, 100% 100%);
        `
      case 'right':
        return css`
          width: ${height};
          height: ${base};
          right: calc(${height} * -1);
          top: 50%;
          transform: translateY(-50%);
          clip-path: polygon(100% 50%, 0 0, 0 100%);
        `
      case 'bottom':
        return css`
          bottom: calc(${height} * -1);
          left: 50%;
          transform: translateX(-50%);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
        `
      default:
        return
    }
  })()

  return css`
    ${baseStyle}
    ${directionStyle}
  `
}
