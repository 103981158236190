import { createContext, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Z_INDEX from '@styles/zIndex'
import Toast from '../components/Toast'

interface Message {
  content: React.ReactNode
  action?: {
    label: string
    onClick: () => void
  }
}

interface MessageOptions {
  /**
   * auto close ToastMessage in milliseconds
   * 0: never close automatically
   * n: close automatically after n millisecond
   */
  autoClose?: number
}

export interface ToastMessage extends Message, MessageOptions {
  id: string
}

const ToastContext = createContext<{
  messages: ToastMessage[]
  pushMessage(message: ToastMessage): void
  closeMessage(id: string): void
}>({
  messages: [],
  pushMessage: () => void 0,
  closeMessage: () => void 0,
})

export default function withToast(defaultOptions?: Partial<MessageOptions>) {
  return <P,>(Component: React.ComponentType<P>) => {
    const WithToast = (props: P & JSX.IntrinsicAttributes) => {
      const [messages, setMessages] = useState<ToastMessage[]>([])
      function pushMessage(message: ToastMessage) {
        setMessages((prev) => [...prev, { ...defaultOptions, ...message }])
      }
      function closeMessage(id: string) {
        setMessages((prev) => prev.filter((m) => m.id !== id))
      }

      // can open toast with window.postMessage
      useEffect(() => {
        if (typeof window === 'undefined') return
        const messageHandler = (e: MessageEvent<{ to: string; payload: ToastMessage }>) => {
          // message to @hoc/withToast
          switch (e.data?.to) {
            case '@hoc/withToast':
              return pushMessage(e.data.payload)
            //FIXME: 함수, jsx등을 받을 수 없어서 postMessage로 toast 호출 시 함수 및 jsx를 사용하는 부분들은 case로 분리
            case '@hoc/withToast/couponDownloaded':
              return pushMessage({
                id: `${Date.now()}`,
                content: e.data?.payload?.content ?? (
                  <p>
                    쿠폰 발급 성공!
                    <br />내 정보 → 내 쿠폰에서 확인할 수 있어요. 기한 내에 꼭 사용해주세요 :)
                  </p>
                ),
                autoClose: 0,
                action: {
                  label: '확인',
                  onClick: () => void 0,
                },
              })
          }
        }
        window.addEventListener('message', messageHandler)
        return () => window.removeEventListener('message', messageHandler)
      }, [])

      return (
        <ToastContext.Provider value={{ messages, pushMessage, closeMessage }}>
          <Component {...props} />
          <ToastContainer>
            {messages.map((message) => (
              <Toast key={message.id} message={message} close={() => closeMessage(message.id)} />
            ))}
          </ToastContainer>
        </ToastContext.Provider>
      )
    }
    return WithToast
  }
}

export function useToast() {
  const { pushMessage } = useContext(ToastContext)

  function toast(message: Message, options?: MessageOptions) {
    const id = `${Date.now()}`
    pushMessage({ id, ...message, ...options })
    return id
  }

  return { toast }
}

const ToastContainer = styled.div`
  white-space: pre-line;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: ${Z_INDEX.OVERLAY};
  pointer-events: none;
  > div:nth-last-child(1) {
    opacity: 1;
  }
  > div:nth-last-child(n + 2) {
    opacity: 0;
    pointer-events: none;
  }
`
