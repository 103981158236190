import { ParsedUrlQuery } from 'querystring'

export type B2BCompanyQuery = ParsedUrlQuery['company']

export const b2bCompanyTypeCode = {
  MULTICAMPUS: 'MULTICAMPUS',
  HUNET: 'HUNET',
  MEGANEXT: 'MEGANEXT',
  FIRSTBRAIN: 'FIRSTBRAIN',
}
export type B2BPlatformTypeCode = keyof typeof b2bCompanyTypeCode
