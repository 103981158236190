import {
  Action,
  ActionCreator,
  createAction as createActionRTK,
  PayloadActionCreator,
} from '@reduxjs/toolkit'

interface Callbacks<Result> {
  onSuccess?: (result: Result) => void
  onFailure?: (error: unknown) => void
  onFinish?: () => void
}
export type WithCallback<Result, RequestPayload = undefined> = RequestPayload extends undefined
  ? Callbacks<Result>
  : RequestPayload & Callbacks<Result>

export interface AsyncAction<
  Payload,
  Response,
  R extends string = string,
  S extends string = string,
  F extends string = string
> {
  request: PayloadActionCreator<WithCallback<Response, Payload>, R>
  success: PayloadActionCreator<{ request: Payload; response: Response }, S>
  failure: PayloadActionCreator<{ request: Payload; error: unknown }, F>
}

export const createAction = <T extends string>(type: T) => <Payload = undefined>() =>
  createActionRTK<Payload, T>(type)

export const createAsyncAction = <R extends string, S extends string, F extends string>(
  request: R,
  success: S,
  failure: F
) => <Payload = undefined, Response = unknown>(): AsyncAction<Payload, Response, R, S, F> => ({
  request: createActionRTK<WithCallback<Response, Payload>, R>(request),
  success: createActionRTK<{ request: Payload; response: Response }, S>(success),
  failure: createActionRTK<{ request: Payload; error: unknown }, F>(failure),
})

export function matchOneOf(actions: ActionCreator<{ type: string }>[]) {
  return (action: Action) => actions.some((ac) => ac().type === action.type)
}
