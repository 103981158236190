import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { v4 as uuidv4 } from 'uuid'
import { useNativeAppContext } from '@contexts/nativeApp'
import { useRenderingContext } from '@contexts/rendering'
import { useScreenContext } from '@contexts/screen'
import { isDeployTypeLive } from './deployType'

/**
 * dev  : 5000123 => d5000123
 * stage: 5000123 => s5000123
 * live : 5000123 =>  5000123
 * local: 5000123 => l5000123
 * undef: 5000123 => T5000123
 */
export function prefixUserId(userId?: number) {
  const deployType = process.env.DEPLOY_TYPE
  const prefix = deployType?.[0] ?? 'T'
  if (!userId) {
    return `${prefix}-GUEST-${uuidv4()}`
  }
  if (isDeployTypeLive) return `${userId}`
  return `${prefix}${userId}`
}

const APP_TYPE_KEY = 'rc2-appType-for-log'

interface GTMEvent {
  event: string
  [key: string]: unknown
}
export function useGTMLogger() {
  const { isInitialRender } = useRenderingContext()
  const appType = useAppType()
  const [events, setEvents] = useState<GTMEvent[]>([])

  // 큐에 쌓아둔 이벤트를 모두 푸시한다.
  function flush() {
    events.forEach((event) => {
      _logGTMEvent(event)
    })
    setEvents([])
  }

  useEffect(() => {
    // 혹시나 이벤트 전송 못 하고 페이지 이탈하는 경우
    // appType이 이상하게 들어가겠지만, 이벤트는 쏘고 보자
    if (isInitialRender) return flush

    // 이벤트가 들어온 경우
    if (events.length > 0) {
      flush()
    }
  }, [isInitialRender, events])

  useEffect(() => {
    localStorage.setItem(APP_TYPE_KEY, appType)
  }, [appType])

  function _logGTMEvent(event: GTMEvent) {
    if (event.user_id) {
      event.user_id = prefixUserId(event.user_id as number)
    }

    TagManager.dataLayer({ dataLayer: { ...event, app_type: appType ?? 'unknown' } })
  }

  // 이벤트 큐에 쌓아두기
  function logGTMEvent(event: GTMEvent) {
    setEvents([...events, event])
  }

  return { logGTMEvent }
}

// 훅 사용하지 않고 log event 추가 시 사용할 함수
export function logGTMEvent(event: GTMEvent) {
  const appType = localStorage.getItem(APP_TYPE_KEY) ?? 'unknown'

  if (event.user_id) {
    event.user_id = prefixUserId(event.user_id as number)
  }

  TagManager.dataLayer({ dataLayer: { ...event, app_type: appType } })
}

function useAppType() {
  const { isLarge, isApp } = useScreenContext()

  const { isAOSApp, isIOSApp } = useNativeAppContext()
  if (isApp) {
    if (isAOSApp) return 'app_aos'
    if (isIOSApp) return 'app_ios'
    return 'app_unknown'
  }
  if (isLarge) {
    return 'pc_web'
  } else {
    return 'mobile_web'
  }
}
