import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useEffect,
} from 'react'
import useInteractOutside from '@hooks/useInteractOutside'
import * as S from './DropdownMenu.style'
import { MenuContextType, MenuProps, MenuListProps } from './types'

const MenuContext = createContext({} as MenuContextType)
export const useMenuContext = () => useContext(MenuContext)

export default function Menu({
  openOnClick = false,
  openOnHover = false,
  closeOnBlur = true,
  style,
  className,
  children,
  as,
  onToggle,
}: MenuProps & React.HTMLProps<HTMLDivElement>) {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const openMenu = useCallback(() => setIsOpen(true), [])
  const closeMenu = useCallback(() => setIsOpen(false), [])
  const toggleMenu = useCallback(() => setIsOpen((s) => !s), [])

  useInteractOutside({
    ref: menuRef,
    onInteractOutside: () => {
      if (closeOnBlur) closeMenu()
    },
  })

  useEffect(() => {
    onToggle?.(isOpen)
  }, [isOpen])

  const context = useMemo(
    () => ({
      isOpen,
      buttonRef,
      toggleMenu,
      openMenu,
      closeMenu,
      openOnClick,
      openOnHover,
    }),
    [isOpen, buttonRef, openOnClick, openOnHover]
  )
  const onHover = {
    onMouseEnter: openOnHover ? openMenu : undefined,
    onMouseLeave: openOnHover ? closeMenu : undefined,
  }
  return (
    <MenuContext.Provider value={context}>
      <S.Menu ref={menuRef} as={as} style={style} className={className} {...onHover}>
        {children}
      </S.Menu>
    </MenuContext.Provider>
  )
}

export function MenuButton({
  style,
  className,
  children,
  onClick,
}: React.HTMLProps<HTMLButtonElement>) {
  const { buttonRef, toggleMenu, openOnClick } = useMenuContext()

  const toggleMenuWithCallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleMenu()
    onClick?.(e)
  }

  return (
    <S.MenuButton
      style={style}
      ref={buttonRef}
      className={className}
      onClick={openOnClick ? toggleMenuWithCallback : onClick}
    >
      {children}
    </S.MenuButton>
  )
}

export function MenuList({
  style,
  placement = 'bottom',
  offset = 0,
  className,
  children,
}: MenuListProps & React.HTMLProps<HTMLDivElement>) {
  const { isOpen, buttonRef } = useMenuContext()
  const buttonHeight = buttonRef.current?.getBoundingClientRect().height || 0
  const buttonWidth = buttonRef.current?.getBoundingClientRect().width || 0
  return (
    <>
      {isOpen && (
        <S.MenuListContainer
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          placement={placement}
          offset={offset}
        >
          <S.MenuList style={style} className={className}>
            {children}
          </S.MenuList>
        </S.MenuListContainer>
      )}
    </>
  )
}

export type MenuItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  'data-event'?: string
  'data-event-value'?: string
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(function MenuItem(props, ref) {
  const { onClick, style, className, children } = props
  const { openOnClick, openOnHover, closeMenu } = useMenuContext()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(e)
    if (openOnClick || openOnHover) closeMenu()
  }

  return (
    <S.MenuItem
      ref={ref}
      style={style}
      className={className}
      onClick={handleClick}
      data-event={props['data-event']}
      data-event-value={props['data-event-value']}
    >
      {children}
    </S.MenuItem>
  )
})

export function MenuDivider({ style, className }: React.HTMLProps<HTMLHRElement>) {
  return <S.MenuDivider style={style} className={className} />
}
