import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNativeAppContext } from '@contexts/nativeApp'
import { useScreenContext } from '@contexts/screen'
import * as S from './Tooltip.style'
import { Placement, Position } from './types'

interface TooltipProps {
  target: HTMLElement | null
  placement?: Placement
  noPadding?: boolean
  offset?: number
  className?: string
}

export const TOOLTIP_ROOT_ID = 'tooltip-root'

export default function Tooltip({
  children,
  target,
  placement = 'bottom',
  noPadding = false,
  offset = 5,
  className,
}: PropsWithChildren<TooltipProps>) {
  const { isApp } = useNativeAppContext()
  const { isSmall } = useScreenContext()
  const [mounted, setMounted] = useState(false)
  const [visible, setVisible] = useState(false)
  const [closeToTop, setCloseToTop] = useState(false)
  const [closeToBottom, setCloseToBottom] = useState(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const position = useRef<Position>([0, 0, 0])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!target) return
    target.addEventListener('mouseenter', show)
    target.addEventListener('mouseleave', hide)

    return () => {
      target.removeEventListener('mouseenter', show)
      target.removeEventListener('mouseleave', hide)
    }
  }, [target])

  if (isApp || isSmall) return null
  if (!mounted) return null

  let tooltipRoot = document.getElementById(TOOLTIP_ROOT_ID)
  if (!tooltipRoot) {
    tooltipRoot = document.createElement('div')
    tooltipRoot.id = TOOLTIP_ROOT_ID
    document.body.appendChild(tooltipRoot)
  }

  function show() {
    if (!target) return
    const targetRect = target.getBoundingClientRect()
    const windowHeight = window.innerHeight

    setCloseToTop(targetRect.top < windowHeight * 0.1)
    setCloseToBottom(targetRect.top + targetRect.height > windowHeight * 0.9)

    const top = targetRect.top + targetRect.height + offset
    const bottom = windowHeight - (targetRect.top - offset)
    const left = targetRect.left + targetRect.width / 2
    position.current = [top, bottom, left]

    setVisible(true)
  }

  function hide() {
    setVisible(false)
  }

  return ReactDOM.createPortal(
    <S.Tooltip
      ref={tooltipRef}
      position={position.current}
      placement={(closeToTop && 'bottom') || (closeToBottom && 'top') || placement}
      visible={visible}
      noPadding={noPadding}
      className={className}
    >
      {children}
    </S.Tooltip>,
    tooltipRoot
  )
}
