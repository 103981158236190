import { createReducer } from '@reduxjs/toolkit'
import { removeNulls } from '@utils/utils'
import * as actions from './actions'
import { PlayerState } from './types'

const initialState: PlayerState = {
  isCompleted: false,
  isError: false,
  isPlaying: false,
  isReady: false,
  isReadyToControl: false,
  isRepeatEnabled: false,
  playbackRate: 1.0,
  onKor: true,
  onEng: true,
  currentTime: 0,
  startTime: 0,
  endTime: 1,
  duration: 0,
  fullscreen: false,
  showsLectureSubtitle: true,
  subtitles: [],
  qualityLevels: [],
  currentQualityIndex: 0,
}

export const RC2_PLAYBACKRATE_KEY = 'rc2-playbackrate'
export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.ready, (state) => {
      return { ...state, isReady: true }
    })
    .addCase(actions.setControlReady, (state) => {
      return { ...state, isReadyToControl: true }
    })
    .addCase(actions.clearControlReady, (state) => {
      return { ...state, isReadyToControl: false }
    })
    .addCase(actions.setError, (state, { payload }) => {
      return { ...state, isError: payload }
    })
    .addCase(actions.setVideoRange, (state, { payload }) => {
      return { ...state, ...payload }
    })
    .addCase(actions.play, (state) => {
      return {
        ...state,
        isPlaying: true,
        isCompleted: false,
        isError: false,
      }
    })

    .addCase(actions.pause, (state) => {
      return { ...state, isPlaying: false }
    })
    .addCase(actions.complete, (state) => {
      return { ...state, isPlaying: false, isCompleted: true }
    })
    .addCase(actions.setPlaybackRate, (state, { payload }) => {
      return { ...state, playbackRate: payload }
    })
    .addCase(actions.toggleKor, (state, { payload }) => {
      return { ...state, onKor: payload }
    })
    .addCase(actions.toggleEng, (state, { payload }) => {
      return { ...state, onEng: payload }
    })
    .addCase(actions.toggleLectureSubtitle, (state) => {
      return { ...state, showsLectureSubtitle: !state.showsLectureSubtitle }
    })
    .addCase(actions.setSubtitleToRepeat, (state, { payload }) => {
      return { ...state, subtitleToRepeat: payload }
    })
    .addCase(actions.freezeSubtitle, (state) => {
      return { ...state, isSubtitleFreezed: true }
    })
    .addCase(actions.unfreezeSubtitle, (state) => {
      return { ...state, isSubtitleFreezed: false }
    })
    .addCase(actions.toggleRepeat, (state) => {
      return { ...state, isRepeatEnabled: !state.isRepeatEnabled }
    })
    .addCase(actions.setCurrentTime, (state, { payload: currentTime }) => {
      return { ...state, currentTime }
    })
    .addCase(actions.toggleFullscreen, (state, { payload: fullscreen }) => {
      return { ...state, fullscreen: fullscreen || !state.fullscreen }
    })
    .addCase(actions.setSubtitles, (state, { payload: subtitles }) => {
      return { ...state, subtitles }
    })
    .addCase(actions.setQualityLevels, (state, { payload }) => {
      const savedQualityLabel = localStorage.getItem('rc2-vjs.qualityLabel.study') // Auto, 1080p, 720p, 540p, 360p, 270p

      // 내림차순으로 정렬합니다. SettingButton을 눌렀을 때 이 순서대로 화면에 보여집니다.
      const qualityLevels = payload.levels
        .map((level) => ({
          ...level,
          label: level.height ? `${level.height}p` : '0',
        }))
        .sort((a, b) => b.bitrate - a.bitrate)

      // 영상마다 제공되는 해상도가 다릅니다. 따라서 비슷한 해상도를 찾아서 매칭합니다.
      const heights = qualityLevels
        .map((level) => level.height)
        .filter(removeNulls)
        .sort((a, b) => b - a)

      const smallestHeight = heights[heights.length - 1]
      const similarHeight =
        heights.find((height) => height <= Number(savedQualityLabel?.replace('p', ''))) ||
        smallestHeight

      const autoQuality = qualityLevels.findIndex(
        (level) => level.height === payload.defaultQuality
      )

      const similarQuality = qualityLevels.findIndex((level) => level.height === similarHeight)

      const currentQualityIndex =
        savedQualityLabel === 'Auto' || savedQualityLabel === null ? autoQuality : similarQuality

      return { ...state, qualityLevels, currentQualityIndex }
    })
    .addCase(actions.setCurrentQuality, (state, { payload: currentQualityIndex }) => {
      localStorage.setItem(
        'rc2-vjs.qualityLabel.study',
        state.qualityLevels[currentQualityIndex].label
      )
      return { ...state, currentQualityIndex }
    })
    .addCase(actions.remove, (state) => {
      window.sessionStorage.setItem(RC2_PLAYBACKRATE_KEY, state.playbackRate.toString())
      return {
        ...initialState,
        playbackRate: state.playbackRate,
        onKor: state.onKor,
        onEng: state.onEng,
      }
    })
)
