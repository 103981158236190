import { ModalProvider } from '@reactleaf/modal'
import register from '@modals/register'

const defaultOverlayOptions = { 'template/BlurringModal': { dim: false, closeDelay: 300 } }

export default function withModal<P>(Component: React.ComponentType<P>) {
  function WithModal(props: P & JSX.IntrinsicAttributes) {
    return (
      <ModalProvider register={register} defaultOverlayOptions={defaultOverlayOptions}>
        <Component {...props} />
      </ModalProvider>
    )
  }
  return WithModal
}
