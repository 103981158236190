import * as Sentry from '@sentry/nextjs'
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import getConfig from 'next/config'
import { put, call, select } from 'typed-redux-saga'
import { refreshToken } from '@modules/auth/api'
import { B2B_SESSION_TIMEOUT_PATH, NO_CACHE_PATH, URL_PREFIX } from '@constants/urls'
import bridge from '@components/WebviewInterface/bridgeMethods'
import { isB2BService } from '@utils/b2b'
import { blockGuestAndRedirectLogin } from '@utils/blockGuest'
import { isDeployTypeDev } from '@utils/deployType'
import {
  getAccessToken,
  setRefreshToken,
  setAccessToken,
  removeAllToken,
  getRefreshToken,
} from '@utils/token'
import { AsyncAction, WithCallback } from './async'

const { publicRuntimeConfig } = getConfig()

const serviceEndpoints: { [k: string]: string } = {
  local: 'http://localhost:8090',
  dev: 'https://api-v2-dev.realclass.co.kr',
  stage: 'https://api-v2-stage.realclass.co.kr',
  stage2: 'https://api-v2-stage.realclass.co.kr',
  live: 'https://api-v2.realclass.co.kr',
}

const QMS_API_ENDPOINTS: { [k: string]: string } = {
  local: 'http://localhost:8080',
  dev: 'https://qms-api-dev.realclass.co.kr/v2/user-api',
  stage: 'https://qms-api-stage.realclass.co.kr/v2/user-api',
  stage2: 'https://qms-api-stage.realclass.co.kr/v2/user-api',
  live: 'https://qms-api.realclass.co.kr/v2/user-api',
}

export const DEPLOY_TYPE = process.env.DEPLOY_TYPE || 'dev'
const SERVICE_URL = serviceEndpoints[DEPLOY_TYPE] || serviceEndpoints['dev']
export const API_URL_V2 = SERVICE_URL + '/v2/api'
const API_URL_V2_5 = SERVICE_URL + '/v2.5/api'
const API_URL_V3_0 = SERVICE_URL + '/v3.0/api'
const API_URL_V3_5 = SERVICE_URL + '/v3.5/api'
const API_URL_V4_0 = SERVICE_URL + '/v4.0/api'

export const QMS_API_URL = QMS_API_ENDPOINTS[DEPLOY_TYPE] || QMS_API_ENDPOINTS['dev']

const axiosConfig = {
  withCredentials: true,
  timeout: isDeployTypeDev ? 60000 : 10000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
}

export const NoCacheAPI = axios.create({ baseURL: NO_CACHE_PATH, ...axiosConfig })

// 토큰을 사용하지 않는 Anonymous Axios
// SSR시에는 토큰 없이 이 API만 사용하도록 한다
export const AnonAPI = axios.create({
  baseURL: API_URL_V2,
  ...axiosConfig,
})

export const InternalAPI = axios.create({
  baseURL: `${URL_PREFIX}/api`,
  ...axiosConfig,
})

export const PublicAPI = axios.create({
  baseURL: API_URL_V2,
  ...axiosConfig,
})

export const HeaderAuthAPI = axios.create({
  baseURL: API_URL_V2,
  headers: {
    'X-Auth-User': process.env.X_AUTH_USER ?? '',
    'X-Auth-Key': process.env.X_AUTH_KEY ?? '',
  },
})

export const PublicAPI25 = axios.create({
  baseURL: API_URL_V2_5,
  ...axiosConfig,
})

export const PublicAPI30 = axios.create({
  baseURL: API_URL_V3_0,
  ...axiosConfig,
})

export const PublicAPI35 = axios.create({
  baseURL: API_URL_V3_5,
  ...axiosConfig,
})

export const PublicAPI40 = axios.create({
  baseURL: API_URL_V4_0,
  ...axiosConfig,
})

export const PrivateAPI = axios.create({
  baseURL: `${API_URL_V2}/profiles/me`,
  ...axiosConfig,
})

export const PrivateAPI25 = axios.create({
  baseURL: `${API_URL_V2_5}/profiles/me`,
  ...axiosConfig,
})

export const PrivateAPI30 = axios.create({
  baseURL: `${API_URL_V3_0}/profiles/me`,
  ...axiosConfig,
})

export const PrivateAPI35 = axios.create({
  baseURL: `${API_URL_V3_5}/profiles/me`,
  ...axiosConfig,
})

export const PrivateAPI40 = axios.create({
  baseURL: `${API_URL_V4_0}/profiles/me`,
  ...axiosConfig,
})

export const QMS_API = axios.create({
  baseURL: QMS_API_URL,
  ...axiosConfig,
})

export const PUBLIC_QMS_API = axios.create({
  baseURL: QMS_API_URL,
  ...axiosConfig,
})

function useToken(config: AxiosRequestConfig): AxiosRequestConfig {
  const tokenHelper = config.url === '/token/refresh' ? getRefreshToken : getAccessToken
  const token = tokenHelper()
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      Version: publicRuntimeConfig.VERSION,
    }
  }
  return config
}

InternalAPI.interceptors.request.use(useToken)
PrivateAPI.interceptors.request.use(useToken)
PrivateAPI25.interceptors.request.use(useToken)
PrivateAPI30.interceptors.request.use(useToken)
PrivateAPI35.interceptors.request.use(useToken)
PrivateAPI40.interceptors.request.use(useToken)
PublicAPI.interceptors.request.use(useToken)
PublicAPI25.interceptors.request.use(useToken)
PublicAPI30.interceptors.request.use(useToken)
PublicAPI35.interceptors.request.use(useToken)
PublicAPI40.interceptors.request.use(useToken)
QMS_API.interceptors.request.use(useToken)

type APICall<P, Data> = (payload: P) => Promise<AxiosResponse<Data>>

export function createAPISaga<P, R>(asyncAction: AsyncAction<P, R>, api: APICall<P, R>) {
  return function* apiSaga(action: ReturnType<typeof asyncAction['request']>) {
    const { onSuccess, onFailure, onFinish } = action.payload as WithCallback<R, P>
    const payload = action.payload as P
    try {
      const response = yield* call(api, payload)
      yield put(asyncAction.success({ request: payload, response: response.data }))
      onSuccess?.(response.data)
    } catch (error: unknown) {
      yield put(asyncAction.failure({ request: payload, error }))
      onFailure?.(error)
      yield* errorHandlerSaga(error, action)
    } finally {
      onFinish?.()
    }
  }
}

export function createLoggingSaga<P, R>(
  asyncAction: AsyncAction<P, R>,
  api: APICall<P & { token: string }, R>
) {
  return function* loggingSaga(action: ReturnType<typeof asyncAction['request']>) {
    const payload = action.payload as P
    try {
      const token = yield* select((state) => state.studyLog.token)
      const response = yield* call(api, { ...payload, token })
      yield put(asyncAction.success({ request: payload, response: response.data }))
    } catch (error: unknown) {
      yield put(asyncAction.failure({ request: payload, error }))
      yield* errorHandlerSaga(error, action)
    }
  }
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError
}

export function* errorHandlerSaga<P>(error: unknown, retryAction?: Action<P>) {
  console.error(error)

  // CODE ERROR!
  if (!isAxiosError(error)) {
    console.info('is not an Axios error. code error')
    Sentry.captureException(error)
    return
  }

  // 4xx, 5xx network error
  // ACCESS 토큰이 유효하지 않은 경우 or 현재 디바이스에 로그인 된 프로필을 다른 디바이스에서 삭제한 경우
  if (error.response?.status === 401) {
    console.info('status 401')
    yield* invalidTokenSaga(retryAction)
  }

  // 구매하지 않은 강의에 진입한경우 or 학습 중 이용권이 만료된 경우
  if (error.response?.status === 403) {
    console.info('status 403')
    yield notAuthorized()
  }
}

interface Action<P> {
  type: string
  payload: P
}

// retry 시 action.payload.retry 필드를 집어넣는다.
// 첫 시도시 undefined, 1회 재시도시 1, 2회 재시도시 2
interface RetryAction<P> extends Action<P> {
  type: string
  payload: P & {
    retry: number
  }
}

function isRetryAction<P>(action: Action<P>): action is RetryAction<P> {
  const payload = (action.payload || {}) as { retry: number }
  return payload.retry > 0
}
function* invalidTokenSaga<P>(action?: Action<P>) {
  const isApp = window.location.pathname.includes('/app/')

  // handleDeletedActiveIdByOther(isApp, isAndroid)

  try {
    const { data } = yield* call(refreshToken, { refreshToken: getRefreshToken() })
    const { refresh, access } = data.result
    setAccessToken(access)
    setRefreshToken(refresh)

    if (isApp) {
      bridge.setToken({ accessToken: access, refreshToken: refresh, isAppRestart: false })
    }

    if (action) {
      console.info('RETRY', action.type)
      if (isRetryAction(action)) {
        const { retry } = action.payload
        if (retry >= 3) {
          throw Error('RETRY COUNT EXCEEDED')
        }
        yield* put({ type: action.type, payload: { ...action.payload, retry: retry + 1 } })
      } else {
        yield* put({ type: action.type, payload: { ...action.payload, retry: 1 } })
      }
    }
  } catch (err) {
    removeAllToken()

    if (isB2BService) {
      window.location.href = B2B_SESSION_TIMEOUT_PATH
      return
    }
    blockGuestAndRedirectLogin()
  }
}

export function notAuthorized() {
  window.postMessage({
    to: '@reactleaf/modal',
    payload: {
      type: 'account/Kickout',
    },
  })
}
