import { css } from 'styled-components'

export const Typography = {
  H1: css`
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.8rem;
  `,
  H2: css`
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
  `,
  H2_5: css`
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 3.6rem;
  `,
  H3: css`
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.2rem;
  `,
  H4: css`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.8rem;
  `,
  H5: css`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
  `,
  H6: css`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.2rem;
  `,
  H7: css`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2rem;
  `,
  H8: css`
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.8rem;
  `,
  Title1: css`
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
  `,
  Title2: css`
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
  `,
  Body0: css`
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.16px;
  `,
  Body1: css`
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.16px;
  `,
  Body2: css`
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.16px;
  `,
  Body3: css`
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.16px;
  `,
  Body4: css`
    font-size: 1.2rem;
    line-height: 1.8rem;
  `,
  ButtonLarge: css`
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.16px;
  `,
  ButtonMedium: css`
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  ButtonSmall: css`
    font-size: 1.2rem;
    line-height: 1.8rem;
  `,
  LabelLarge: css`
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 500;
  `,
  LabelMedium: css`
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
  `,
  /**
   * @deprecated 기존 사용하는곳에서 LabelMedium으로 대체 후 스타일 문제 없는지 확인 필요. 모두 변경되면 삭제 예정
   */
  LegacyLabelSmall: css`
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.16px;
  `,
  LabelSmall: css`
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 500;
  `,
  LabelXSmall: css`
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
  `,
  Caption1: css`
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.16px;
  `,
  Caption2: css`
    font-size: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.16px;
  `,
}

export const Colors = {
  // Green - Primary
  Green005: '#F1FEF5',
  Green010: '#E6F9ED',
  Green020: '#C2EED3',
  Green030: '#91E6B3',
  Green040: '#59DC94',
  Green050: '#00D37A',
  Green060: '#00C170',
  Green070: '#0AA865',
  Green080: '#0E955C',

  // Blue - Secondary
  Blue005: '#EBF7FE',
  Blue010: '#CFEDFF',
  Blue020: '#88D0FF',
  Blue030: '#00ADFF',
  Blue040: '#029DFF',
  Blue050: '#008DFF',
  Blue060: '#0179FF',
  Blue070: '#0066FF',
  Blue080: '#2340E0',

  // Black - Grey
  Grey005: '#F9FAFA',
  Grey010: '#F3F4F6',
  Grey020: '#EBEDEF',
  Grey030: '#D8DBE0',
  Grey040: '#B1B8C2',
  Grey050: '#A2A7AD',
  Grey060: '#787D83',
  Grey070: '#64696E',
  Grey080: '#45494E',
  Grey090: '#16181B',

  // Other
  Class101: '#FE4B00',
  Red: '#FF4954',
  SkyBlue: '#44B9EF',
  Orange: '#FF7448',
  Yellow: '#FFDC5F',
  CNNRed: '#BB261A',
  CNNBlue: '#1C43C6',
  ErrorRed: '#FF414D',
  LiveBlue: '#45A2FA',
  LivePink: '#FF949E',
  LiveSpain: '#FFCF55',
} as const

export const SantaSayColors = {
  SS_BLUE01: '#ACE9FC',
  SS_BLUE02: '#59C8FC',
  S_BLUE01: '#3284FF',
  SSS_BLUE01: '#0332D1',
  S_BLUE02: '#051A60',
}

export const BoxShadow = {
  Bold: css`
    box-shadow: 0 0.4rem 1.6rem rgba(162, 167, 173, 0.34);
  `,
  Basic: css`
    box-shadow: 0 0 1.2rem rgba(162, 167, 173, 0.24);
  `,
  Thin: css`
    box-shadow: 0 0 0.6rem rgba(162, 167, 173, 0.3);
  `,
  TokTok: css`
    box-shadow: 0 0.2rem 0.4rem 0 rgba(185, 215, 233, 0.25);
  `,
}

export const DimColors = {
  Thick: 'rgba(22, 24, 27, 0.75)',
  Basic: 'rgba(22, 24, 27, 0.5)',
  Thin: 'rgba(22, 24, 27, 0.15)',
}
