import { AgeRestrictionType } from '../profile/models'

export interface Category {
  id: number
  order: number
  name: string
  subCategories: SubCategory[]
  courses: CourseSummary[]
}

export interface SubCategory {
  id: number
  order: number
  name: string
}

export interface CourseSummary {
  id: number
  name: string
  type: COURSE_TYPE
  parentCourse?: number // 영상 전용 코스의 경우 해당 영상의 원래 courseId
  order: number
  categoryId: number
  subCategoryId: number | null
  seasonIndex: number
  selectorName: string
  hasCoaching: boolean
}

export enum COURSE_TYPE {
  NORMAL = 'NORMAL',
  NO_CHALLENGE = 'NO_CHALLENGE', // 복습 전용 코스
  NO_CLASS = 'NO_CLASS', // 영상 전용 코스 (평생 이용권)
  NO_COACHING = 'NO_COACHING',
  NO_LECTURE = 'NO_LECTURE',
  NO_LECTURE_NO_COACHING = 'NO_LECTURE_NO_COACHING',
  NO_TRAINING_NO_COACHING = 'NO_TRAINING_NO_COACHING',
  MIXED = 'MIXED',
  ONLY_LECTURE = 'ONLY_LECTURE',
}
export interface Course {
  id: number
  name: string
  parentCourse?: number // 영상 전용 코스의 경우 해당 영상의 원래 courseId
  type: COURSE_TYPE
  level: string
  dayCount: number
  lectureCount: number
  clipCount: number
  descriptionCount: number
  ageRestriction: AgeRestrictionType
  logoUrl: string
  isPopsong: boolean
  teachers: Teacher[]
  subCategory?: number
  textbook?: {
    id: number
    url: string
  }
  season: {
    id: number
    name: string
    index: number
    hasFullEpisodes: boolean
  }
  imageCoverDesktop: string
  imageCoverMobile: string
  imageCoverTablet: string
}

export enum ASPECT_RATIO {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export interface Teacher {
  id: number
  name: string
  thumbnailUrl: string | null
  interviewVideoThumbnailUrl: string
  interviewVideoUrl: string
  interviewVideoAspectRatio: ASPECT_RATIO
}

export interface DaySummary {
  id: number
  type: COURSE_TYPE
  day: number
  isPreview: boolean
  dayName: string
  artist?: string
}

export interface Day extends DaySummary {
  lectures: Array<{
    id: number
    titleEng: string
    titleKor: string
    length: number
    thumbnailUrl: string
    lectureNoteMarkdown: string | null
  }>
  clips: Array<{
    id: number
    startTime: number
    endTime: number
    name: string
    thumbnailUrl: string
    mediaId: number
  }>
  trainingCount: number
  coachingCount: number | null
}

//TODO: 정주행쪽 타입에서 가져다 쓰기
export interface MediaSummary {
  id: number
  name: string
  length: number
  thumbnailUrl: string
  artist: string | null
}
