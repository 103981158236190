import { useRouter } from 'next/router'
import { createContext, useContext, useEffect } from 'react'
import usePaths from '@constants/paths'
import { isB2BService } from '@utils/b2b'
import { getAccessToken } from '@utils/token'
import { useRenderingContext } from './rendering'

const B2BContext = createContext<{ isB2B: boolean }>({ isB2B: false })
export const useB2BContext = () => useContext(B2BContext)

export function withB2B<P>(Component: React.ComponentType<P>) {
  return function WithB2BDomain(props: P & JSX.IntrinsicAttributes) {
    const { isInitialRender } = useRenderingContext()
    const router = useRouter()
    const paths = usePaths()

    const isLoginPath =
      router.pathname === paths.b2bLogin({ company: router.query.company ?? '' }).pathname

    useEffect(() => {
      if (!isInitialRender) return
      if (!isB2BService) return

      const token = getAccessToken()
      if (!token && !isLoginPath) void router.push(paths.b2bSessionTimeOut())
    }, [])

    return (
      <B2BContext.Provider value={{ isB2B: isB2BService }}>
        <Component {...props} />
      </B2BContext.Provider>
    )
  }
}
