import { createReducer } from '@reduxjs/toolkit'
import { matchOneOf } from '@store/async'
import { fetchClip, fetchTrainings, fetchCoachings, fetchFurtherMedia } from './actions'
import { Lecture, Clip, Training, Coaching, FurtherMedia } from './models'
import * as playlist from './playlist/reducer'
import * as progressSteps from './progressSteps/reducer'

interface State {
  lectures: {
    [lectureId: number]: Lecture
  }
  clip: {
    [clipId: number]: Clip
  }
  training: {
    [dayId: number]: Training[]
  }
  coaching: {
    [dayId: number]: Coaching[]
  }
  furtherMedia: {
    [mediaId: number]: FurtherMedia // 한 페이지에서 동일한 mediaId, 다른 seasonId 인 경우가 없을 것 같아서 리스트 삭제
  }
  playlist: typeof playlist.initialState
  progressSteps: typeof progressSteps.initialState
}
const initialState: State = {
  lectures: {},
  clip: {},
  training: {},
  coaching: {},
  furtherMedia: {},
  playlist: playlist.initialState,
  progressSteps: progressSteps.initialState,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchClip.success, (state, action) => {
      const { result } = action.payload.response
      const clip = result[0]
      return {
        ...state,
        clip: {
          ...state.clip,
          [clip.id]: clip,
        },
      }
    })
    .addCase(fetchTrainings.success, (state, action) => {
      const { dayId } = action.payload.request
      const { common, result: trainings } = action.payload.response
      const video = common.medias[0]
      return {
        ...state,
        training: {
          ...state.training,
          [dayId]: trainings.map((t) => ({ ...t, video })),
        },
      }
    })
    .addCase(fetchCoachings.success, (state, action) => {
      const { dayId } = action.payload.request
      const { result: coachings } = action.payload.response
      return {
        ...state,
        coaching: {
          ...state.coaching,
          [dayId]: coachings,
        },
      }
    })
    .addCase(fetchFurtherMedia.success, (state, action) => {
      const { result: furtherMedia } = action.payload.response

      return {
        ...state,
        furtherMedia: {
          ...state.furtherMedia,
          [furtherMedia.id]: furtherMedia,
        },
      }
    })
    .addMatcher(matchOneOf(progressSteps.actions), (state, action) => ({
      ...state,
      progressSteps: progressSteps.reducer(state.progressSteps, action),
    }))
    .addMatcher(matchOneOf(playlist.actions), (state, action) => ({
      ...state,
      playlist: playlist.reducer(state.playlist, action),
    }))
)
