import { all, select, takeEvery, put } from 'typed-redux-saga'
import { setCurrentTime, handleTime } from './actions'

// onTime 이벤트 핸들링
// pause on explanation에서 자막이 다음자막으로 넘어가는 이슈를 방지합니다.
function* handleTimeSaga(action: ReturnType<typeof handleTime>) {
  const currentTime = action.payload
  const { isSubtitleFreezed } = yield* select((state) => state.player)

  // useSubtitleTrinity 의 currentSubtitle이 다음 자막으로 넘어가지 않도록 freeze
  if (isSubtitleFreezed) return

  yield put(setCurrentTime(currentTime))
}

export default function* playerSaga() {
  yield all([takeEvery(handleTime, handleTimeSaga)])
}
