import { createReducer } from '@reduxjs/toolkit'
import _groupBy from 'lodash/groupBy'
import _uniqBy from 'lodash/uniqBy'
import { fetchLogReport, fetchRecentMedia, fetchRecentCourses, clearRecentCourses } from './actions'
import { LogReport, RecentMedia, RecentCourse } from './models'

interface State {
  report: LogReport
  recents: {
    media: RecentMedia
    courses: RecentCourse[]
  }
}

const initialState: State = {
  report: {} as LogReport,
  recents: {
    media: {} as RecentMedia,
    courses: [] as RecentCourse[],
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchLogReport.success, (state, action) => {
      const { result: report } = action.payload.response
      return {
        ...state,
        report,
      }
    })
    .addCase(fetchRecentMedia.success, (state, action) => {
      const { result: media } = action.payload.response
      return {
        ...state,
        recents: {
          ...state.recents,
          media,
        },
      }
    })
    .addCase(fetchRecentCourses.success, (state, action) => {
      const { result: courses } = action.payload.response

      return {
        ...state,
        recents: {
          ...state.recents,
          courses: [...state.recents.courses, ...courses],
        },
      }
    })
    .addCase(clearRecentCourses, (state) => {
      return {
        ...state,
        recents: {
          ...state.recents,
          courses: [],
        },
      }
    })
)
