import { createAsyncAction } from '@store/async'
import { CourseAndDayID, SimpleDaySummaryResponse, DayProgressResponse } from './types'

export const fetchSimpleDaySummary = createAsyncAction(
  '@progressSteps/FETCH_SIMPLE_DAY_SUMMARY/REQUEST',
  '@progressSteps/FETCH_SIMPLE_DAY_SUMMARY/SUCCESS',
  '@progressSteps/FETCH_SIMPLE_DAY_SUMMARY/FAILURE'
)<CourseAndDayID, SimpleDaySummaryResponse>()

export const fetchDayProgressLog = createAsyncAction(
  '@progressSteps/FETCH_DAY_PROGRESS_LOG/REQUEST',
  '@progressSteps/FETCH_DAY_PROGRESS_LOG/SUCCESS',
  '@progressSteps/FETCH_DAY_PROGRESS_LOG/FAILURE'
)<CourseAndDayID, DayProgressResponse>()

export default {
  fetchSimpleDaySummary,
  fetchDayProgressLog,
}
