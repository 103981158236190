import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { v4 } from 'uuid'
import { useMe } from '@store/modules/user/swr'
import useProfile from '@hooks/useProfile'

const uuid = v4()

export const useSentryIdentification = () => {
  const { data: userData } = useMe()
  const { activeProfile } = useProfile()

  useEffect(() => {
    userData?.id && Sentry.setUser({ id: userData.id.toString() })
    activeProfile && Sentry.setTag('profile-id', activeProfile?.id)

    // 비로그인 유저는 임의 id 부여
    if (!userData?.id || !activeProfile?.id) {
      Sentry.setUser({ id: uuid })
      Sentry.setTag('profile-id', uuid)
    }
  }, [userData, activeProfile])
}
