import type { ParsedUrlQueryInput } from 'querystring'
import type { UrlObject } from 'url'
import { removeNulls } from '@utils/utils'

export interface URL extends UrlObject {
  pathname: string
  query: ParsedUrlQueryInput
}

const noPrefix = (path: string) => path

/**
 * const classHome = makePath('/class')
 *
 * classHome()
 * // return { pathname: '/class' }
 *
 * classHome({ cid: 99 })
 * // return { pathname: '/class', query: { cid: 99 } }
 * // onBrowser: '/class?cid=99'
 *
 *
 * const classPath = makePath('/class/[courseId]')
 *
 * classPath()
 * // throws Error
 *
 * classPath({ courseId: 99 })
 * // return { pathname: '/class/[courseId]', query: { courseId: 99 } }
 * // onBrowser: '/class/99'
 *
 * * classPath({ courseId: 99, from: 'challenge' })
 * // return { pathname: '/class/[courseId]', query: { courseId: 99, from: 'challenge' } }
 * // onBrowser: '/class/99?from=challenge'
 */
export const makePath = (path: string, prefixer = noPrefix) => (
  query: ParsedUrlQueryInput = {}
): URL => {
  const pathname = prefixer(path)

  // ex. pathname = "/class/[courseId]"
  const regex = /\[(.*?)\]/g

  // ex. matches = ["[courseId]"]
  const matches = pathname.match(regex) ?? []

  // ex. keys = ["courseId"]
  const keys = matches.map((matched) => regex.exec(matched)?.[1]).filter(removeNulls)

  // check query for dynamic path supplied
  const notSuppliedQueries = keys.filter((key) => query[key] === undefined)
  if (notSuppliedQueries.length > 0) {
    throw new Error(`PathError: query ${notSuppliedQueries.join(',')} is missing in ${pathname}.`)
  }

  return { pathname, query }
}
