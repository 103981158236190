import { useRouter } from 'next/router'
import { createContext, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useRenderingContext } from './rendering'

interface TScreenContext {
  isApp: boolean
  isWeb: boolean
  isSmall: boolean
  isLarge: boolean
}

export const ScreenContext = createContext({} as TScreenContext)

export const useScreenContext = () => useContext(ScreenContext)

/* 반응형 대응을 위해 컴포넌트 분리가 필요하거나, 다른 데이터를 보여줘야 하는 경우 사용 */
export default function useScreen<T>({ onSmall, onLarge }: { onSmall: T; onLarge: T }) {
  // isLarge is always false on initial rendering
  const { isLarge } = useScreenContext()
  return isLarge ? onLarge : onSmall
}

export const withScreen = <P,>(Component: React.ComponentType<P>) => {
  const WithScreen = (props: P & JSX.IntrinsicAttributes) => {
    const { isInitialRender } = useRenderingContext()

    /* useRouter는 server rendering 때도 정확한 pathname을 리턴함. 따라서 서버에서부터 app용 컴포넌트를 렌더링할 수 있음 */
    const { asPath } = useRouter()

    const wasApp = typeof window === 'undefined' ? false : localStorage.getItem('isApp') === 'true'
    const isApp = asPath.startsWith('/app') || wasApp
    const isWeb = !isApp

    /**
     * useMediaQuery는 server rendering 때 false를 리턴하기 때문에,
     * 최초 CSR 때 hydration mismatch가 발생하지 않도록
     * isInitialRender 에 따라 isSmall = true, isLarge = false 가 되도록 설정
     * 따라서 SSR 시에 렌더링이 되기를 바라는 컴포넌트는 `isSmall &&` 을 사용하여 지정하고
     * SSR시 렌더링이 되지 않기를 바라는 컴포넌트는 !isSmall, isLarge 을 사용해 지정해야 한다!
     */
    const isSmall = useMediaQuery({ maxWidth: 800 }) || isInitialRender
    const isLarge = useMediaQuery({ minWidth: 801 }) && !isInitialRender

    return (
      <ScreenContext.Provider value={{ isApp, isWeb, isSmall, isLarge }}>
        <Component {...props} />
      </ScreenContext.Provider>
    )
  }
  return WithScreen
}
