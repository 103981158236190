import { createAsyncAction } from '@store/async'
import { FetchMediaSummariesPayload, FetchMediaSummariesResponse } from './types'

export const fetchMediaSummaries = createAsyncAction(
  '@study/FETCH_MEDIA_SUMMARIES/REQUEST',
  '@study/FETCH_MEDIA_SUMMARIES/SUCCESS',
  '@study/FETCH_MEDIA_SUMMARIES/FAILURE'
)<FetchMediaSummariesPayload, FetchMediaSummariesResponse>()

export default {
  fetchMediaSummaries,
}
