const Z_INDEX = {
  LAYER: 10,
  STICKY: 100,
  APP_HEADER: 150,
  CTA: 200,
  CHANNEL_TALK_ENTRY: 250,
  GNB: 300, // 모바일 side drawer가 이 값을 따라감. GNB가 채널톡보다 높으면 채널톡 닫기버튼이 안 눌리는 이슈가 있음. 따라서 열렸을 때는 300, 닫혔을 때는 200으로 조절.
  OVERLAY: 1000,
} as const

export default Z_INDEX

/**
 * z-index가 높은 것보터 메모
 *
 * STICKY : ScrollNavigation
 *
 * braze -> z-index: 1050
 */
