import styled, { css } from 'styled-components'
import { flexCenter } from '@styles/styleUtils'
import Z_INDEX from '@styles/zIndex'

export const GNB_HEIGHT = '6.4rem'

export const GNB = styled.header<{
  hidden?: boolean
  isDrawerOpened?: boolean
}>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: ${GNB_HEIGHT};
  background-color: #fff;
  transition: transform 0.3s;
  z-index: ${Z_INDEX.CTA};
  ${({ hidden }) =>
    hidden &&
    css`
      transform: translateY(-100%);
      pointer-events: none;
    `}
  ${({ isDrawerOpened }) =>
    isDrawerOpened &&
    css`
      z-index: ${Z_INDEX.GNB};
    `}
`

export const GNBColumn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
`

export const Logo = styled.a`
  margin-left: 0.5rem;
  font-size: 0;
  svg {
    fill: #000;
  }
`

export const AccountButton = styled.a`
  font-size: 0;
`

export const SignupButton = styled.button`
  ${flexCenter};
  width: 8.9rem;
  height: 3.2rem;
  background: #000;
  border-radius: 2rem;

  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8rem;
`
