import useSWR from 'swr'
import { NoCacheAPI } from '@store/ajax'
import { ServiceVersion } from '@store/modules/account/types'
import { useNativeAppContext } from '@contexts/nativeApp'
import { isDeployTypeLive } from '@utils/deployType'

export interface TargetVersion {
  Android: string
  iPhone: string
  iPad: string
}

export interface OptionalUpdate {
  enabled: boolean
  targetVersion: TargetVersion
  targetUser: TargetUser
}
export interface TargetUser {
  serviceVersion: ServiceVersion
  purchased: boolean
}
interface ChallengeGuide extends OptionalUpdate {
  targetPages: string[]
  contents: {
    title: string
    message: string
    actionText: string
    cancelText: string
    outLink: string
  }
}

interface ServiceJsonType {
  isInReview: boolean
  versionInReview: {
    Android?: string
    iPhone?: string
    iPad?: string
    unknown?: string
  }
  nonPurchaserOutLink: {
    title?: string
    message: string
    primaryButton: {
      text: string
      outerLink: string
    }
  }
  forceUpdateActivated: boolean
  forceUpdateTargetVersion: TargetVersion
  forceOptionalTargetUpdate: boolean
  optionalUpdate: OptionalUpdate
  challengeGuide: ChallengeGuide
}

const SECONDS = 1000

export default function useServiceJson() {
  const { isApp } = useNativeAppContext()

  const key = isDeployTypeLive ? '/service.json' : '/service_dev.json'
  return useSWR(
    isApp ? key : null,
    (url: string) => NoCacheAPI.get<ServiceJsonType>(url).then((res) => res.data),
    { revalidateOnFocus: false, revalidateOnReconnect: false, dedupingInterval: 10 * SECONDS }
  )
}
