import { Provider, createStore } from 'jotai'
import React from 'react'

const globalStore = createStore()

export default function withJotaiProvider<P>(Component: React.ComponentType<P>) {
  return function WithJotaiProvider(props: P & JSX.IntrinsicAttributes) {
    return (
      <Provider store={globalStore}>
        <Component {...props} />
      </Provider>
    )
  }
}
