import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import { Challenge, STANDALONE_CHALLENGES, getRewardIds, sortByStartDate } from './domain'

/**
 * 기간이 이어지는 챌린지를 병합합니다.
 * 단, 개별적으로 진행되는 일부 챌린지는 병합하지 않습니다.
 * 1. [{}, {}, {}, {}, {}, {}] 모든 챌린지를
 * 2. [ [{}, {}, {}], [{}, {}, {}] ] 타입별 2중배열로 묶고
 * 3. 타입별 배열을 startDate 기준으로 정렬한 다음
 * 4. [ [{}, {}], [{}, {}] ] 앞 항목의 endDate와 뒤 항목의 startDate가 하루차이 이내라면 병합합니다.
 * 5. [{}, {}, {}, {}] flat화 하여 사용합니다.
 */
export function mergeContinuousChallenges(challenges?: Challenge[]) {
  if (!challenges) return undefined

  const groupedByType = groupByType(challenges) // 2. [ [{daily}, {daily}, {daily}], [{weekly}, {weekly}, {weekly}] ]
  const sortedChallenges = groupedByType.map(sortByStartDate) // 3. sorted by start date
  const groupedByPeriod = sortedChallenges.map(groupByPeriod) // 4-1. [ [ [{daily}, {daily}], {daily}], [ [{weekly}, {weekly}], {weekly}] ]
  const mergedChallenges = groupedByPeriod.flatMap(
    (groupedByType) => groupedByType.map(mergeChallenges) // 4-2 [ [{daily}, {daily}], [{weekly}, {weekly}] ]
  )
  return mergedChallenges
}
function groupByType(challenges: Challenge[]) {
  const groupedByType = groupBy(challenges, 'challengeGroupTypeCode') // { DAILY_MISSION: [{}, {}], WEEKLY_MISSION: [{}, {}] }
  return Object.values(groupedByType) // [ [{}, {}], [{}, {}] ]
}

/**
 * endDate: 2023-01-01, startDate: 2023-01-01 => true
 * endDate: 2023-01-01, startDate: 2023-01-02 => true
 * endDate: 2023-01-01, startDate: 2023-01-03 => false // 중간에 하루가 비어있다
 * endDate: 2023-01-03, startDate: 2023-01-01 => true // 종료/시작 일자가 겹쳐있다
 */
function isMergable(endDate: YYYYMMDD, startDate: YYYYMMDD) {
  return dayjs(endDate).diff(dayjs(startDate), 'day') >= -1
}
/**
 * 기간이 연속되더라도 개별적으로 진행되는 챌린지
 * 참고) https://qualsonteam.slack.com/archives/C018JEH2FGQ/p1674206392230379?thread_ts=1674012527.642849&cid=C018JEH2FGQ
 */
function isStandaloneChallenge(challenges: Challenge[]) {
  return challenges.some((c) => STANDALONE_CHALLENGES.includes(c.challengeGroupTypeCode))
}
function groupByPeriod(challenges: Challenge[]) {
  if (isStandaloneChallenge(challenges)) {
    return challenges.map((c) => [c])
  }

  let key = '' //key = startDate
  const groupedByPeriod = challenges.reduce((acc, c, idx, arr) => {
    if (idx === 0) {
      key = c.startDate
      return { [c.startDate]: [c] }
    }
    const prevItem = arr[idx - 1]
    if (isMergable(prevItem.endDate, c.startDate)) {
      return { ...acc, [key]: [...acc[key], c] }
    }
    key = c.startDate
    return { ...acc, [c.startDate]: [c] }
  }, {} as { [key: string]: Challenge[] })

  return Object.values(groupedByPeriod)
}

function pickPeriod(challenges: Challenge[]) {
  const startDates = challenges.map((c) => dayjs(c.startDate))
  const endDates = challenges.map((c) => dayjs(c.endDate))
  return {
    startDate: dayjs.min(startDates).format('YYYY-MM-DD'),
    endDate: dayjs.max(endDates).format('YYYY-MM-DD'),
  }
}
function mergeChallenges(challenges: Challenge[]): Challenge {
  if (challenges.length === 1) return challenges[0]

  const isV40Marathon = challenges[0].challengeGroupTypeCode === 'V4_0_MARATHON_CHALLENGE'

  // 4.0 마라톤 챌린지의 경우, 먼저 종료된 챌린지가 대표한다.
  // ongoing 상태인 챌린지가 없다면 이미 종료된 챌린지 그룹이므로 아무거나 추가한다
  const representativeChallenge = isV40Marathon
    ? challenges.find((c) => c.challengeStatus === 'ENDED')
    : challenges.find((c) => c.challengeStatus === 'ONGOING')
  const _representativeChallenge = representativeChallenge || challenges[0]

  return {
    ..._representativeChallenge,
    ...pickPeriod(challenges),
    missionRewardIds: getRewardIds(challenges),
  }
}
