import { CoachingLog } from '@modules/studyLog/models'
import { createAction, createAsyncAction, WithCallback } from '@store/async'
import * as T from './types'

export const fetchClipLogs = createAsyncAction(
  '@studyLog/FETCH_CLIP_LOGS_REQUEST',
  '@studyLog/FETCH_CLIP_LOGS_SUCCESS',
  '@studyLog/FETCH_CLIP_LOGS_FAILURE'
)<T.FetchClipLogsPayload, T.FetchClipLogsResponse>()

export const updateClipLog = createAsyncAction(
  '@studyLog/UPDATE_CLIP_LOG_REQUEST',
  '@studyLog/UPDATE_CLIP_LOG_SUCCESS',
  '@studyLog/UPDATE_CLIP_LOG_FAILURE'
)<T.UpdateClipLogPayload, T.UpdateClipLogResponse>()

export const fetchTrainingLogs = createAsyncAction(
  '@studyLog/FETCH_TRAINING_LOGS_REQUEST',
  '@studyLog/FETCH_TRAINING_LOGS_SUCCESS',
  '@studyLog/FETCH_TRAINING_LOGS_FAILURE'
)<T.FetchTrainingLogsPayload, T.FetchTrainingLogsResponse>()

export const updateTrainingLog = createAsyncAction(
  '@studyLog/UPDATE_TRAINING_LOG_REQUEST',
  '@studyLog/UPDATE_TRAINING_LOG_SUCCESS',
  '@studyLog/UPDATE_TRAINING_LOG_FAILURE'
)<T.UpdateTrainingLogPayload, T.UpdateTrainingLogResponse>()

export const fetchCoachingLogs = createAsyncAction(
  '@studyLog/FETCH_COACHING_LOGS_REQUEST',
  '@studyLog/FETCH_COACHING_LOGS_SUCCESS',
  '@studyLog/FETCH_COACHING_LOGS_FAILURE'
)<T.FetchCoachingLogsPayload, T.FetchCoachingLogsResponse>()

export const updateCoachingLog = createAsyncAction(
  '@studyLog/UPDATE_COACHING_LOG_REQUEST',
  '@studyLog/UPDATE_COACHING_LOG_SUCCESS',
  '@studyLog/UPDATE_COACHING_LOG_FAILURE'
)<T.UpdateCoachingLogPayload, T.UpdateCoachingLogResponse>()

export const skipCoachingLog = createAction('@studyLog/SKIP_COACHING_LOG')<
  WithCallback<boolean, T.SkipCoachingLogPayload>
>()

export const submitCoachingLog = createAction('@studyLog/SUBMIT_COACHING_LOG_REQUEST')<
  WithCallback<CoachingLog, T.SubmitCoachingLogPayload>
>()

export const fetchCourseMediaLogs = createAsyncAction(
  '@studyLog/FETCH_COURSE_MEDIA_LOGS/REQUEST',
  '@studyLog/FETCH_COURSE_MEDIA_LOGS/SUCCESS',
  '@studyLog/FETCH_COURSE_MEDIA_LOGS/FAILURE'
)<T.FetchCourseMediaLogsPayload, T.FetchMediaLogsResponse>()

export const fetchSeasonMediaLogs = createAsyncAction(
  '@studyLog/FETCH_SEASON_MEDIA_LOGS/REQUEST',
  '@studyLog/FETCH_SEASON_MEDIA_LOGS/SUCCESS',
  '@studyLog/FETCH_SEASON_MEDIA_LOGS/FAILURE'
)<T.FetchSeasonMediaLogsPayload, T.FetchMediaLogsResponse>()

export const fetchMediaLog = createAsyncAction(
  '@studyLog/FETCH_MEDIA_LOG/REQUEST',
  '@studyLog/FETCH_MEDIA_LOG/SUCCESS',
  '@studyLog/FETCH_MEDIA_LOG/FAILURE'
)<T.FetchMediaLogPayload, T.FetchMediaLogResponse>()

export const updateMediaLog = createAsyncAction(
  '@studyLog/UPDATE_MEDIA_LOG/REQUEST',
  '@studyLog/UPDATE_MEDIA_LOG/SUCCESS',
  '@studyLog/UPDATE_MEDIA_LOG/FAILURE'
)<T.UpdateMediaLogPayload, T.UpdateMediaLogResponse>()

export const fetchDailyCoachingLogs = createAsyncAction(
  '@studyLog/FETCH_DAILY_COACHING_LOGS/REQUEST',
  '@studyLog/FETCH_DAILY_COACHING_LOGS/SUCCESS',
  '@studyLog/FETCH_DAILY_COACHING_LOGS/FAILURE'
)<T.FetchDailyCoachingLogsPayload, T.FetchDailyCoachingLogsResponse>()

export default {
  fetchClipLogs,
  updateClipLog,
  fetchTrainingLogs,
  updateTrainingLog,
  fetchCoachingLogs,
  updateCoachingLog,
  skipCoachingLog,
  submitCoachingLog,
  fetchCourseMediaLogs,
  fetchSeasonMediaLogs,
  fetchMediaLog,
  updateMediaLog,
  fetchDailyCoachingLogs,
}
