export enum COLORS {
  // Primary Color
  REAL_GREEN = '#2bde73',
  REAL_BLACK = '#13141a',
  WHITE = '#fff',

  // Secondary Color
  REAL_BLUE_01 = '#44b9ef',
  REAL_BLUE_02 = '#2cdbbb',
  REAL_BLUE_03 = '#3472ff',

  // Black - Grey
  GREY_01 = '#000',
  GREY_02 = '#26272e',
  GREY_03 = '#434449',
  GREY_04 = '#6b727a',
  GREY_045 = '#838c97',
  GREY_05 = '#a2a9b2',
  GREY_06 = '#c0c5cb',
  GREY_07 = '#d4d7da',
  GREY_08 = '#eceef0',
  GREY_09 = '#f3f3f3',
  GREY_10 = '#f7f7f7',
  GREY_11 = '#f9f9f9',
  GREY_LINE_01 = '#e5e5e5',
  GREY_LINE_02 = '#ebebeb',

  // System
  SYSTEM_ERROR = '#ff414d',

  // Green Variation
  GREEN_01 = '#29c668',
  GREEN_02 = '#5ddb90',
  GREEN_03 = '#7ce7a7',

  // Red Variation
  RED_01 = '#FF4889',

  // Level / Step
  STEP_01 = '#75ef8f',
  STEP_02 = '#5cd99d',
  STEP_03 = '#4ccbba',
  STEP_04 = '#66acd9',

  // Promotion
  PROMOTION_GREEN = '#00d37a',
  PROMOTION_RED = '#ff4859',
  PROMOTION_RED_02 = '#ff313d',
  // PROMOTION_BLUE = '#0073f0',
  PROMOTION_BLUE = '#4573F6',
  PROMOTION_YELLOW = '#ffbe35',
  PROMOTION_CYAN = '#36c6ff',
  PROMOTION_SKY_BLUE = '#3FAAFD',
  PROMOTION_PURPLE = '#4F72F7',
  PROMOTION_CNN = '#CC0000',

  // Others
  OTHERS_01_PURPLE = '#6262fb',
  OTHERS_02_YELLOW = '#ffdc4e',
  OTHERS_03_PURPLE = '#816cff',
  OTHERS_04_GREY = '#161e2d',
  OTHERS_05_SKY = '#66C4FA',
}

export enum GRADIENTS {
  GREEN_01 = 'linear-gradient(90deg, #53E897, #6ADDC1)',
  GREEN_02 = 'linear-gradient(90deg, #5CE3A3, #6ADDDC)',
  GREEN_03 = 'linear-gradient(90deg, #81DE83, #BADD56)',
  GREEN_04 = 'linear-gradient(90deg, #3DD093, #7570EB)',
  BLUE_01 = 'linear-gradient(90deg, #4B9BF8, #55F8C7)',
  LIVE_01 = 'linear-gradient(90deg, #00D37A 0%, #36C6FF 102.07%)',
}

export enum SOCIAL {
  KAKAO_YELLOW = '#FCE750',
  KAKAO_BROWN = '#3C1E1E',
  APPLE = '#13141a',
  NAVER = '#06BE34',
  OTHER = '#F3F3F3',
}

export const COLORS_V2 = {
  // Black - Grey
  GREY_01: '#F3F4F6',
  GREY_02: '#EBEDEF',
  GREY_03: '#D8DBE0',
  GREY_04: '#A7A7A7',
  GREY_05: '#A2A7AD',
  GREY_06: '#787D83',
  GREY_07: '#64696E',
  GREY_08: '#45494E',
  GREY_09: '#16181B',
  GREY_10: '#161616',
  GREY_BOX: '#F5F5F7',

  // Promotion
  PROMOTION_REAL_GREEN: '#00D37A',
  PROMOTION_REAL_BLUE: '#3472FF',
  PROMOTION_REAL_RED: '#EA4746',
  PROMOTION_REAL_PURPLE: '#6776FF',
}
