import styled, { css } from 'styled-components'
import { MenuButton, MenuItem, MenuList } from '@components/DropdownMenu'
import { COLORS } from '@styles/palette'
import { flexCenter, redDot } from '@styles/styleUtils'
import { Colors } from '@styles/system'

export const GNB_HEIGHT = '7.2rem'
export const DESKTOP_CONTAINER_WIDTH = '128rem'

export const GNB = styled.header`
  position: relative;
  top: 0;
  width: 100%;
  height: ${GNB_HEIGHT};
  background-color: #fff;
  border-bottom: 0.1rem solid '#eee';
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${DESKTOP_CONTAINER_WIDTH};
  height: 100%;
  padding: 0 40px;
  margin: 0 auto;

  ${({ theme }) =>
    theme.isB2B &&
    css`
      width: 100%;
      max-width: ${DESKTOP_CONTAINER_WIDTH}; ;
    `}
`

export const Nav = styled.nav`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 74px;

  height: 100%;
`

export const NavColumn = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
`

export const Divider = styled.div`
  margin: 12px;
  border-bottom: solid 1px #eeeeee;
`

export const NavItem = styled.a<{ show?: boolean; current?: boolean }>`
  position: relative;
  padding: 0 12px;

  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: ${Colors.Green050};
  }

  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }

  &.red-dot {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 10px;
      ${redDot};
    }
  }

  ${({ show }) =>
    show &&
    css`
      color: #00d595;
    `}

  ${({ current }) =>
    current &&
    css`
      color: ${Colors.Green050};
    `}
`

export const NavItemWithIcon = styled.a`
  padding: 0 12px;
  font-size: 12px;
  font-weight: 700;

  display: flex;
  align-items: center;

  span {
    margin-inline-start: 4px;
  }
`

export const SignupButton = styled.a`
  margin-left: 10px;
  width: 89px;
  height: 32.46px;
  ${flexCenter};

  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  border-radius: 100px;
  background: #000;
`

export const MyMenuList = styled(MenuList)`
  min-width: 90px;
  border-radius: 10px;
  border: solid 1px #00d595;
  overflow: hidden;
`

export const MyMenuButton = styled(MenuButton)`
  && {
    border: 0;
    font-size: 12px;
    font-weight: 700;
    padding: 0 5px;

    &:hover {
      background-color: white;
    }
  }
`

export const MyMenuItem = styled(MenuItem)`
  font-size: 12px;
  line-height: 28px;

  &:hover {
    background-color: initial;
    font-weight: 700;
    color: ${COLORS.PROMOTION_GREEN};
  }
`

export const PictogramWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Pictogram = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 70%;
  overflow: hidden;
  margin-right: 6px;
  display: inline-block;
  flex-shrink: 0;
`

export const PictogramTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const PictogramBadge = styled.div`
  box-sizing: border-box;
  padding: 0.2rem 0.6rem;
  color: ${Colors.Green050};
  font-weight: 700;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  background: ${Colors.Grey090};
  border-radius: 5rem;
  text-transform: uppercase;
`

export const MultiProfileButton = styled(MenuButton)`
  && {
    padding: 0;
    border: 0;

    &:hover {
      background-color: white;
    }
  }
`

export const MultiProfileList = styled(MenuList)`
  min-width: 150px;
  border-radius: 10px;
  border: solid 1px #00d595;
  overflow: hidden;
  padding: 1.2rem 0;
`

export const MultiProfileItem = styled(MenuItem)`
  font-size: 12px;
  padding: 4px 29px 4px 10px;

  &:hover {
    background-color: initial;
    font-weight: 700;
    color: ${COLORS.PROMOTION_GREEN};

    ${PictogramBadge} {
      color: white;
      background-color: ${COLORS.PROMOTION_GREEN};
    }
  }
`

export const EventSpeechBubble = styled.button`
  position: relative;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.15px;
  color: #ffffff;

  width: 4rem;
  height: 1.6rem;

  background: #ff414d;
  border-radius: 4px;
  margin-left: 0.8rem;

  &::after {
    content: '';
    position: absolute;
    left: -0.25rem;
    top: 50%;

    transform: translateY(-50%) rotate(-45deg);

    width: 0.5rem;
    height: 0.5rem;

    background: #ff414d;
    border-radius: 1px;
  }
`
