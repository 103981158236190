import { createReducer } from '@reduxjs/toolkit'
import {
  fetchMemorizeSubtitles,
  fetchMemorizeMediaSubtitles,
  saveMemorizeMediaSubtitles,
  unsaveMemorizeMediaSubtitles,
  fetchMemorizeSubtitleLogs,
  fetchMemorizeWords,
  fetchMemorizeWordSummaries,
  fetchMemorizeLectureWords,
  saveMemorizeLectureWord,
  unsaveMemorizeLectureWord,
  fetchMemorizeClipWords,
  saveMemorizeClipWord,
  unsaveMemorizeClipWord,
} from './actions'
import {
  MemorizeSubtitle,
  MemorizeSubtitleSummary,
  SubtitleFluency,
  SubtitleFluencyCounts,
  MemorizeWord,
  MemorizeWordSummary,
  MemorizeClipWord,
  PageSize,
} from './models'

/**
 * savedMediaSubtitles: 2단계, 정주행에서 사용
 * savedSubtitles: 내 문장에서 사용
 */

interface State {
  savedMediaSubtitles: MemorizeSubtitleSummary[]
  savedSubtitle: {
    current: {
      count: number
      categoryId?: number
      titleId?: number
      seasonId?: number
      infinite: boolean
      page: number
      fluency: SubtitleFluency
      fluencyCounts: SubtitleFluencyCounts
    }
    subtitles: MemorizeSubtitle[]
    seasonIdsWithSubtitle: number[]
  }
  savedWords: {
    current: {
      page: number
      count: number
      infinite: boolean
    }
    words: MemorizeWord[]
    summaries: MemorizeWordSummary[]
  }
  savedLectureWords: {
    [lectureId: number]: MemorizeWord[]
  }
  savedClipWords: {
    [subtitleId: number]: MemorizeClipWord[]
  }
}

const initialState: State = {
  savedMediaSubtitles: [],
  savedSubtitle: {
    current: {
      count: 0,
      page: 1,
      fluency: SubtitleFluency.NEW,
      fluencyCounts: [0, 0, 0],
      infinite: false,
    },
    subtitles: [],
    seasonIdsWithSubtitle: [],
  },
  savedWords: {
    current: {
      page: 1,
      count: 0,
      infinite: false,
    },
    words: [],
    summaries: [],
  },
  savedLectureWords: {},
  savedClipWords: {},
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchMemorizeSubtitles.success, (state, action) => {
      const {
        page = 1,
        fluency = 0,
        categoryId,
        titleId,
        seasonId,
        infinite = state.savedSubtitle.current.infinite,
      } = action.payload.request

      const {
        count,
        result: subtitles,
        common: { newCount, canHearCount, canSpeakCount },
      } = action.payload.response

      return {
        ...state,
        savedSubtitle: {
          ...state.savedSubtitle,
          current: {
            count,
            categoryId,
            titleId,
            seasonId,
            page,
            fluency,
            fluencyCounts: [newCount, canHearCount, canSpeakCount],
            infinite,
          },
          subtitles: handleInfiniteList(
            state.savedSubtitle.subtitles,
            subtitles,
            infinite,
            page,
            PageSize.SUBTITLE
          ),
        },
      }
    })
    .addCase(fetchMemorizeMediaSubtitles.success, (state, action) => {
      const { result } = action.payload.response

      return { ...state, savedMediaSubtitles: result }
    })
    .addCase(saveMemorizeMediaSubtitles.success, (state, action) => {
      const { subtitleId } = action.payload.request
      return {
        ...state,
        savedMediaSubtitles: [...state.savedMediaSubtitles, { subtitleId }],
      }
    })
    .addCase(unsaveMemorizeMediaSubtitles.success, (state, action) => {
      const { subtitleId } = action.payload.request

      return {
        ...state,
        savedMediaSubtitles: state.savedMediaSubtitles.filter(
          (subtitle) => subtitle.subtitleId !== subtitleId
        ),
      }
    })
    .addCase(fetchMemorizeSubtitleLogs.success, (state, action) => {
      const { result: seasons } = action.payload.response
      return {
        ...state,
        savedSubtitle: {
          ...state.savedSubtitle,
          seasonIdsWithSubtitle: seasons.map((season) => season.seasonId),
        },
      }
    })
    .addCase(fetchMemorizeWords.success, (state, action) => {
      const { page = 1, infinite = state.savedWords.current.infinite } = action.payload.request
      const { result: words, count } = action.payload.response
      return {
        ...state,
        savedWords: {
          ...state.savedWords,
          current: {
            page,
            count,
            infinite,
          },
          words: handleInfiniteList(state.savedWords.words, words, infinite, page, PageSize.WORD),
        },
      }
    })
    .addCase(fetchMemorizeWordSummaries.success, (state, action) => {
      const { result: summaries } = action.payload.response
      return {
        ...state,
        savedWords: {
          ...state.savedWords,
          summaries,
        },
      }
    })
    .addCase(fetchMemorizeLectureWords.success, (state, action) => {
      const { request, response } = action.payload
      return {
        ...state,
        savedLectureWords: {
          ...state.savedLectureWords,
          [request.lectureId]: response.result,
        },
      }
    })
    .addCase(saveMemorizeLectureWord.success, (state, action) => {
      const { request, response } = action.payload
      const { lectureId } = request
      return {
        ...state,
        savedLectureWords: {
          ...state.savedLectureWords,
          [lectureId]: [...state.savedLectureWords[lectureId], response.result],
        },
      }
    })
    .addCase(unsaveMemorizeLectureWord.success, (state, action) => {
      const { lectureId, profileWordId } = action.payload.request
      return {
        ...state,
        savedLectureWords: {
          ...state.savedLectureWords,
          [lectureId]: state.savedLectureWords[lectureId].filter((w) => w.id !== profileWordId),
        },
      }
    })
    .addCase(fetchMemorizeClipWords.success, (state, action) => {
      const { request, response } = action.payload
      return {
        ...state,
        savedClipWords: {
          ...state.savedClipWords,
          [request.subtitleId]: response.result,
        },
      }
    })
    .addCase(saveMemorizeClipWord.success, (state, action) => {
      const { request, response } = action.payload
      const { subtitleId } = request
      return {
        ...state,
        savedClipWords: {
          ...state.savedClipWords,
          [subtitleId]: [...(state.savedClipWords[subtitleId] || []), response.result],
        },
      }
    })
    .addCase(unsaveMemorizeClipWord.success, (state, action) => {
      const { subtitleId, profileWordId } = action.payload.request
      return {
        ...state,
        savedClipWords: {
          ...state.savedClipWords,
          [subtitleId]: state.savedClipWords[subtitleId].filter((w) => w.id !== profileWordId),
        },
      }
    })
)

/**
 * infinite로 페이지를 불러온 경우,
 * 해당 페이지 뒷쪽은 무시한 채, 해당 페이지를 업데이트한다.
 * 1, 2, 3페이지를 불러온 상태에서 2페이지에 업데이트가 일어난 경우,
 * 업데이트되는 새로운 상태는 1페이지 + 2'페이지 가 된다.
 */
function handleInfiniteList<T>(
  originalList: T[],
  newList: T[],
  infinite: boolean,
  currentPage: number,
  pageSize: number
) {
  if (!infinite) return newList
  return [...originalList.slice(0, (currentPage - 1) * pageSize), ...newList]
}
