import { PrivateAPI } from '@store/ajax'
import * as T from './types'

type WithToken<Payload> = Payload & { token: string }

export const fetchLectureLogs = ({ courseId, dayId }: T.FetchLectureLogsPayload) =>
  PrivateAPI.get<T.FetchLectureLogsResponse>(`/courses/${courseId}/days/${dayId}/lectures`)

export const updateLectureLog = ({
  courseId,
  dayId,
  lectureId,
  currentPosition,
  startDatetimeToken,
}: T.UpdateLectureLogPayload) => {
  // realPlayTime 없을 때는 서버에 0 전달
  return PrivateAPI.post<T.UpdateLectureLogResponse>(
    `/courses/${courseId}/days/${dayId}/lectures/${lectureId}`,
    { currentPosition, startDatetimeToken, realPlayTime: 0 }
  )
}

export const fetchClipLogs = ({ courseId, dayId }: T.FetchClipLogsPayload) =>
  PrivateAPI.get<T.FetchClipLogsResponse>(`/courses/${courseId}/days/${dayId}/clips`)

export const updateClipLog = ({
  courseId,
  dayId,
  clipId,
  currentPosition,
  startDatetimeToken,
}: T.UpdateClipLogPayload) =>
  PrivateAPI.post<T.UpdateClipLogResponse>(`/courses/${courseId}/days/${dayId}/clips/${clipId}`, {
    currentPosition,
    startDatetimeToken,
    realPlayTime: 0,
  })

export const fetchTrainingLogs = ({ courseId, dayId }: T.FetchTrainingLogsPayload) =>
  PrivateAPI.get<T.FetchTrainingLogsResponse>(`/courses/${courseId}/days/${dayId}/trainings`)

export const updateTrainingLog = ({
  courseId,
  dayId,
  trainingId,
  isCompleted,
  startDatetimeToken,
}: T.UpdateTrainingLogPayload) =>
  PrivateAPI.post<T.UpdateTrainingLogResponse>(
    `/courses/${courseId}/days/${dayId}/trainings/${trainingId}`,
    {
      isCompleted,
      startDatetimeToken,
    }
  )

export const fetchDailyCoachingLogs = ({ courseId }: T.FetchDailyCoachingLogsPayload) =>
  PrivateAPI.get<T.FetchDailyCoachingLogsResponse>(`/courses/${courseId}/days/coachings`)

export const fetchCoachingLogs = ({ courseId, dayId }: T.FetchCoachingLogsPayload) =>
  PrivateAPI.get<T.FetchCoachingLogsResponse>(`/courses/${courseId}/days/${dayId}/coachings`)

export const updateCoachingLog = ({
  courseId,
  dayId,
  coachingId,
  token,
  isCompleted,
  isSkipped,
}: WithToken<T.UpdateCoachingLogPayload>) =>
  PrivateAPI.post<T.UpdateCoachingLogResponse>(
    `/courses/${courseId}/days/${dayId}/coachings/${coachingId}`,
    {
      startDatetimeToken: token,
      isCompleted,
      isSkipped,
    }
  )

export const fetchUploadUrl = ({ courseId, dayId, coachingId }: T.SubmitCoachingLogPayload) =>
  PrivateAPI.get<T.FetchUploadUrlResponse>(
    `/courses/${courseId}/days/${dayId}/coachings/${coachingId}/url`
  )

/* 내 클래스 페이지에서 영상 정주행 목록을 표시하기 위한 api -> 4.2.5 */
export const fetchCourseMediaLogs = ({ courseId }: { courseId: number }) =>
  PrivateAPI.get<T.FetchMediaLogsResponse>(`/courses/${courseId}/medias`)

/* 영상 정주행 페이지에서 재생 목록을 표시하기 위한 api -> 9.2.3 */
export const fetchSeasonMediaLogs = ({ seasonId }: { seasonId: number }) =>
  PrivateAPI.get<T.FetchMediaLogsResponse>(`/seasons/${seasonId}/medias`)

/* 영상 정주행 페이지에서 media 재생 시 기록용 토큰 가져오기 위한 api -> 9.1.2 */
export const fetchMediaLog = ({ seasonId, mediaId }: { seasonId: number; mediaId: number }) =>
  PrivateAPI.get<T.FetchMediaLogResponse>(`/seasons/${seasonId}/medias/${mediaId}`)

export const updateMediaLog = ({
  seasonId,
  mediaId,
  currentPosition,
  startDatetimeToken,
}: T.UpdateMediaLogPayload) =>
  PrivateAPI.post<T.UpdateMediaLogResponse>(`/seasons/${seasonId}/medias/${mediaId}`, {
    currentPosition,
    startDatetimeToken,
  })
