import { createAction, createAsyncAction } from '@store/async'
import * as T from './common.types'

export const saveStudyToken = createAction('@challenge/SAVE_STUDY_TOKEN/')<{
  dayStep: string
  token: string
}>()

export const checkStudyRewards = createAsyncAction(
  '@challenge/CHECK_STUDY_REWARD/REQUEST',
  '@challenge/CHECK_STUDY_REWARD/SUCCESS',
  '@challenge/CHECK_STUDY_REWARD/FAILURE'
)<T.CheckStudyRewardsPayload, T.CheckStudyRewardsResponse>()

const resetRewards = createAction('@challenge/RESET_REWARDS')()

export default {
  checkStudyRewards,
  resetRewards,
  saveStudyToken,
}
