import { atom, useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'
import { fetchProfiles, fetchProfileToken } from '@store/modules/profile/api'
import { LICENSE_TYPE } from '@store/modules/user/models'
import { useMe } from '@store/modules/user/swr'
import { useScreenContext } from '@contexts/screen'
import { useToast } from '@hoc/withToast'
import usePaths from '@constants/paths'
import { LIVE_COURSE_URL, LIVE_VOD_URL } from '@pages/live/hooks/useStoreLiveUrl'
import bridge from '@components/WebviewInterface/bridgeMethods'
import { setAccessToken, setRefreshToken } from '@utils/token'

export const activeProfileAtom = atom(0)

export default function useProfile() {
  const router = useRouter()
  const paths = usePaths()
  const { data: userData, mutate: mutateUser } = useMe()
  const [activeProfileId, setActiveProfileId] = useAtom(activeProfileAtom)
  const { data, mutate } = useSWR(userData?.id ? '@private/profiles' : null, () =>
    fetchProfiles({
      userId: userData?.id ?? 0,
      licenseType: userData?.licenseType ?? LICENSE_TYPE.REALCLASS_2,
    }).then((res) => res.data)
  )
  const { isApp } = useScreenContext()
  const { toast } = useToast()

  const profiles = data?.result ?? []

  // activeProfileId 는 변경된 토큰 -> 유저정보 리로드 -> activeProfileId 변경 으로만 변경된다
  useEffect(() => {
    if (userData?.activeProfileId) {
      setActiveProfileId(userData?.activeProfileId)
    }
  }, [userData?.activeProfileId])

  async function profileLogin(profileId: number, options = { redirect: true }) {
    if (userData?.type !== 'user') return
    try {
      // update token
      const res = await fetchProfileToken({ userId: userData.id, profileId })
      const { access, refresh } = res.data.result
      setAccessToken(access)
      setRefreshToken(refresh)
      if (isApp) {
        bridge.setToken({ accessToken: access, refreshToken: refresh })
      }

      // reload user info to update activeProfileId
      await mutateUser()

      // 기존 로직에서: 7세 프로필로 변경 + 클래스홈 + 수강가능한 클래스가 없는 경우 -> 원 프로필로 돌아가기
      // 라는 로직이 있었으나, 사실상 거의 사용하지 않을 것으로 예상되어 제거함.

      // redirect = true 인 경우, 학습 홈 (혹은 저장된 경로)으로 이동
      // redirect = false 인 경우, 각 호출 측에서 처리
      if (options?.redirect) {
        goForStudy()
      }
    } catch (e) {
      //
    }
  }

  function goForStudy() {
    const liveVodId = localStorage.getItem(LIVE_VOD_URL)
    localStorage.removeItem(LIVE_VOD_URL)

    const liveCourseId = localStorage.getItem(LIVE_COURSE_URL)
    localStorage.removeItem(LIVE_COURSE_URL)

    if (liveVodId) {
      void router.replace(liveVodId)
    } else if (liveCourseId) {
      void router.replace(liveCourseId)
    } else {
      void router.replace(paths.studyHome())
    }

    toast({ content: '로그인 되었습니다.' })
  }

  return {
    profiles,
    activeProfile: profiles.find((p) => p.id === activeProfileId),
    masterProfile: profiles.find((p) => p.isMaster),
    maxProfileCount: data?.common.maximumNumberOfProfiles ?? 1,
    profileCountWithSub: data?.count ?? 1,
    profileLogin,
    reloadProfiles: () => mutate(),
  }
}
