import { useEffect } from 'react'
import { BasicError } from '@components/Error'
import ErrorBoundary from './ErrorBoundary'

function DefaultErrorPage() {
  useEffect(() => {
    document.body.style.paddingTop = 'initial'
  }, [])

  return <BasicError type="error" />
}

export default function withDefaultErrorBoundary<P>(Component: React.ComponentType<P>) {
  return function WithErrBoundary(props: P & JSX.IntrinsicAttributes) {
    return (
      <ErrorBoundary fallback={<DefaultErrorPage />}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
