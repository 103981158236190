import Head from 'next/head'
import { isDeployTypeDev, isDeployTypeLive } from '@utils/deployType'

function getDeployType() {
  const prefix = process.env.NODE_ENV === 'production' ? '' : 'L-'

  if (process.env.NODE_ENV === 'production' && isDeployTypeLive) return null

  if (isDeployTypeLive) return `[${prefix}LIVE]`
  if (isDeployTypeDev) return `[${prefix}DEV]`
  if (process.env.DEPLOY_TYPE?.includes('stage')) return `[${prefix}STG]`

  if (process.env.NODE_ENV === 'development') return '[LOCAL]'

  return null
}

interface Props {
  children: string
}
export default function Title({ children }: Props) {
  const titleText = `${getDeployType() ?? ''} ${children}`
  return (
    <Head>
      <title>{titleText}</title>
    </Head>
  )
}
