import { useEffect, useState } from 'react'
import semver from 'semver'
import { useNativeAppContext } from '@contexts/nativeApp'
import { getAppInfo } from '@utils/app'

export enum AppType {
  iPhone = 'iPhone',
  iPad = 'iPad',
  Android = 'Android',
  unknown = 'unknown',
}

export const DEVICE_TYPE = {
  iPhone: 'iPhone',
  iPad: 'iPad',
  Android: 'Android',
  unknown: 'unknown',
} as const

export interface AppVersion {
  appType: AppType
  version: string
}

/**
 * getAppInfo 를 통해 파싱을 진행
 * getAppInfo는 window.navigator.userAgent를 확인하기 때문에, mount 된 이후에 호출해야 함
 * @returns currentAppVersion
 */
export function getAppVersion() {
  const [appType, _osVersion, deviceModel, version = '0.0.0', _service] = getAppInfo()

  return {
    appType: parseAppType(appType),
    version: parseVersion(version),
    deviceType: getDeviceType({ osType: appType, deviceModel }),
    osVersion: _osVersion,
  }
}

function parseAppType(appType?: string) {
  if (!appType) return AppType.unknown
  if (appType.toLowerCase().includes('android')) return AppType.Android
  if (appType.toLowerCase().includes('pad')) return AppType.iPad
  if (appType.toLowerCase().includes('ios')) return AppType.iPhone
  return AppType.unknown
}

function parseVersion(version: string) {
  return semver.coerce(version, { loose: true })?.format() ?? '' // version 정보 뒤에 붙는 env string 제거
}

function getDeviceType({ osType, deviceModel }: { osType?: string; deviceModel?: string }) {
  if (!osType || !deviceModel) return DEVICE_TYPE.unknown

  const _osType = osType.toLowerCase()
  const _deviceModel = deviceModel.toLowerCase()

  if (_osType.includes('pad')) return DEVICE_TYPE.iPad /* ipad 전용앱 임시호환용 */
  if (_osType.includes('android')) return DEVICE_TYPE.Android
  if (_deviceModel.includes('pad')) return DEVICE_TYPE.iPad
  if (_deviceModel.includes('iphone')) return DEVICE_TYPE.iPhone
  return DEVICE_TYPE.unknown
}

/**
 * useEffect를 통해 설정
 * mount 된 이후에야 제대로 된 값이 들어옴
 */
export default function useAppVersion(): AppVersion {
  const [appType, setAppType] = useState<AppType>(AppType.unknown)
  const [version, setVersion] = useState('0.0.0')

  useEffect(() => {
    const { appType, version } = getAppVersion()
    setAppType(appType)
    setVersion(version)
  }, [])

  return { appType, version }
}

export function isRNOver4_0_4() {
  // 4.0.5 부터 인앱 리뷰 작성 및 현재 스토어 버젼으로 이동 기능 추가
  const { version } = getAppVersion()
  return semver.satisfies(version, '>4.0.4')
}

export function isRNOver4_1_0() {
  //4.1.1 부터 2-depth를 풀스크린으로 띄우도록 변경함
  const { version } = getAppVersion()
  return semver.satisfies(version, '>4.1.0')
}

export function isSingleWebviewApp() {
  const { version } = getAppVersion()
  return semver.satisfies(version, '>=4.5.0')
}

export function isRNOver4_5_2() {
  // 4.6.0 부터 코칭 화면을 웹뷰로 사용
  const { version } = getAppVersion()
  return semver.satisfies(version, '>4.5.2')
}

/**
 * 브라우저에서 앱 화면 확인할 때 앱 버저닝을 간편하게 하기 위한 hook
 * chrome only
 */
export function useAppVersionForDev(version: string) {
  const { isApp } = useNativeAppContext()

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') return

    const { appType } = getAppVersion()
    const isDevOnBrowser = isApp && appType === AppType.unknown
    if (isDevOnBrowser) {
      const fakeUserAgent = `${window.navigator.userAgent} Realclass2(Android; 11; SM-G988N; ${version}; dev)`
      Object.defineProperty(navigator, 'userAgent', {
        get: function () {
          return fakeUserAgent
        },
      })
    }
  }, [])
}
