import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { URL_PREFIX } from '@constants/urls'
import { atomWithSessionStorage } from '@utils/jotai'

type Route = { url: string; key: string }

const routesAtom = atomWithSessionStorage<Route[]>('routes', [])

const routeBreadcrumbAtom = atom((get) => get(routesAtom).map((route) => route.url))

/**
 * 지금까지 거쳐온 pathname + searchParams + hash.
 * replace한 URL 포함. (톡톡 이전 URL 가져오기에 필요)
 **/
export function useRouteBreadcrumb() {
  return useAtomValue(routeBreadcrumbAtom)
}

export function useRouteBreadcrumbStore() {
  const router = useRouter()
  const setRoutes = useSetAtom(routesAtom)

  function saveBreadcrumb(url: string) {
    setRoutes((previous) => addRoute(previous, url))
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', saveBreadcrumb)
    router.events.on('hashChangeComplete', saveBreadcrumb)
    return () => {
      router.events.off('hashChangeComplete', saveBreadcrumb)
      router.events.off('routeChangeComplete', saveBreadcrumb)
    }
  }, [router])

  useEffect(() => {
    if (!router.isReady) return

    setRoutes((previous) => {
      const isInitialized = previous.length !== 0
      return isInitialized ? previous : addRoute(previous, router.asPath)
    })
  }, [router, router.isReady])
}

function addRoute(previous: Route[], url: string) {
  return [
    ...previous,
    { url: url.replace(new RegExp(`^${URL_PREFIX}`), ''), key: getStateKey(history) },
  ]
}

function getStateKey(history: History) {
  return (history.state as { key: string })?.key
}
