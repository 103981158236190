import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import jwt from 'jsonwebtoken'
import useSWR from 'swr'
import { isAxiosError } from '@store/ajax'
import { DAY } from '@constants/time'
import { getAccessToken } from '@utils/token'
import { DecodedTokenModel } from '../account/types'
import { fetchLicense, validateToken } from '../auth/api'
import * as API from './api'
import { Guest, QMSAgreementType, User } from './models'
import { EligibilityTypeCode, UsageRightPeriodResponse } from './types'
import {
  checkUsageRight,
  groupChallengesByDate,
  groupClassesByDate,
  groupFeaturesByDate,
  sortDateRangesByEndDate,
} from './utils'

export class UnauthenticatedError extends Error {}

export const ME_KEY = '@user/me'
export function useMe() {
  return useSWR<Guest | User | undefined, AxiosError>(
    ME_KEY,
    async () => {
      const token = getAccessToken()
      if (!token) {
        return { type: 'guest' } as Guest
      }

      // 토큰 유효성 확인
      if (!(await validateToken({ token }))) {
        // StudyHome에서 인증 오류 확인을 위한 에러설정.
        throw new UnauthenticatedError()
      }

      const { q_license_id, profile_id, q_license_type } = jwt.decode(token) as DecodedTokenModel
      if (!q_license_id) {
        throw new Error("Can't find q_license_id")
      }

      try {
        const license = await fetchLicense({ licenseId: q_license_id, token }).then(
          (res) => res.data.result
        )
        const user = await API.fetchMe().then((res) => res.data.result[0])
        const marketingAgreed = {
          // SMS 수신 동의와 이메일 수신 동의 created는 같음
          created:
            license.agreements.find((a) => a.agreementType === QMSAgreementType.SMS)?.created ?? '',
          isAgreed:
            license.agreements.find((a) => a.agreementType === QMSAgreementType.SMS)?.agree === 1 && // SMS 수신 동의
            license.agreements.find((a) => a.agreementType === QMSAgreementType.EMAIL)?.agree === 1, // 이메일 수신 동의
        }

        return {
          type: 'user',
          licenseType: q_license_type,
          id: q_license_id,
          serviceVersion: user.serviceVersion,
          activeProfileId: profile_id,
          info: {
            // 마스터 프로필 닉네임을 유저의 이름으로 본다
            nickname: user.profiles.find((p) => p.isMaster)?.nickname ?? '',
            accountName: license.accountName,
            phone: license.phoneNumber,
            dateJoined: user.dateJoined,
            // 성인인증 유효기간이 아직 지나지 않았다면 오케이
            verifiedAsAdult: dayjs(user.adultVerificationExpirationDatetime).isAfter(dayjs()),
            marketingAgreed,

            // 공유계정이 있는지 여부
            hasSubAccount: license.hasSubAccount,
            accountType: license.snsTypeCode ?? 'EMAIL',
          },
        } as const
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          throw new UnauthenticatedError()
        }
        throw new Error('Failed to fetch user information')
      }
    },
    // 이 API 는 하루동안 유효한 것으로 치자. 유저 정보를 매번 확인할 필요는 없다.
    // 유저 정보 재확인을 위해서는 mutate를 호출하면 된다.
    { dedupingInterval: 1 * DAY }
  )
}

export const USAGE_RIGHT_PERIOD_KEY = '@private/usage-right-period'
function useUsageRightPeriod() {
  return useSWR(
    USAGE_RIGHT_PERIOD_KEY,
    () =>
      API.fetchUsageRightPeriod().then((res) => ({
        ...res.data,
        date: res.headers.date,
      })),
    { dedupingInterval: 1 * DAY }
  )
}

export const useGroupUsageRightPeriod = () => {
  const { data, mutate, ...props } = useUsageRightPeriod()

  return {
    data: getGroupUsageRightPeriod(data),
    mutate: async () => getGroupUsageRightPeriod(await mutate()),
    date: data?.date,
    ...props,
  }
}

export function useCheckUsageRight() {
  const { data, mutate, date, ...props } = useGroupUsageRightPeriod()

  return {
    data: getUsageRightCheck(data, date),
    date,
    mutate: async () => getUsageRightCheck(await mutate()),
    ...props,
  }
}

export const useCheckEligibility = ({
  eligibilityTypeCode,
}: {
  eligibilityTypeCode: EligibilityTypeCode | null
}) =>
  useSWR(
    eligibilityTypeCode && `@private/user/checkEligibility/${eligibilityTypeCode}`,
    () => eligibilityTypeCode && API.checkEligibility({ eligibilityTypeCode }),
    { dedupingInterval: 2000 }
  )

export const useCheckDeviceReceiptEligibility = () => {
  const eligibilityTypeCode = 'CAN_SUBMIT_DEVICE_PURCHASE_PAYBACK_DOCUMENT'
  return useSWR(`@private/user/checkEligibility/${eligibilityTypeCode}`, () =>
    API.checkEligibility({ eligibilityTypeCode })
  )
}

function getGroupUsageRightPeriod(data?: UsageRightPeriodResponse) {
  if (!data) return

  const featureTypes = data.result.featureTypes
  const classCourses = groupClassesByDate(data.result.courses, data.common.courses)
  const challenges = groupChallengesByDate(data.result.challenges, data.common.challenges)
  const liveCourses = groupFeaturesByDate(data.result.featureTypes, 'LIVE_CLASS')
  const toktoks = groupFeaturesByDate(data.result.featureTypes, 'TALK')
  return { classCourses, challenges, liveCourses, toktoks, featureTypes }
}

function getUsageRightCheck(
  data?: ReturnType<typeof getGroupUsageRightPeriod>,
  currentServerTime?: string
) {
  if (!data) return

  const hasLive = checkUsageRight(data?.liveCourses, currentServerTime)
  const hasClass = checkUsageRight(data?.classCourses, currentServerTime)
  const hasTokTok = checkUsageRight(data?.toktoks, currentServerTime)
  const hasUsageRight = hasLive || hasClass || hasTokTok
  return { hasLive, hasClass, hasTokTok, hasUsageRight }
}

export function useContentsUsageRightEndDate() {
  const { data: studyPeriodData, ...rest } = useGroupUsageRightPeriod()
  if (!studyPeriodData) return { contentsLastEndDate: undefined, ...rest }

  const { classCourses, liveCourses } = studyPeriodData
  const sortedContentsGroup = sortDateRangesByEndDate([...classCourses, ...liveCourses])
  const contentsLastEndDate = sortedContentsGroup[sortedContentsGroup.length - 1].endDate
  return { contentsLastEndDate, ...rest }
}
