export enum AgreementType {
  SMS = 1,
  EMAIL = 2,
  PUSH = 3,
  ACCOUNT = 4,
  ALL = 9,
}

export const tmCounselTimeMap = {
  AM: '오전 9시 ~ 오후 12시',
  PM1: '오후 12시 ~ 오후 3시',
  PM2: '오후 3시 ~ 오후 6시',
}

export type TmCounselTime = keyof typeof tmCounselTimeMap
export type VerificationTypeCode =
  | 'ACCOUNT'
  | 'PASSWORD'
  | 'RESET_PWD'
  | 'ACCOUNT_CREATION'
  | 'ACCOUNT_MANAGE'
  | 'ACCOUNT_CANCELLATION'
  | 'CHANGE_PHONE'
  | 'CHANGE_SNS_TYPE'
  | 'LOGIN'
