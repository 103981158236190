import { B2BCompanyQuery, b2bCompanyTypeCode, B2BPlatformTypeCode } from '@store/modules/b2b/models'
import { isDeployTypeDev, isDeployTypeLive, isDeployTypeStage } from './deployType'

export function getB2BServiceURL() {
  if (isDeployTypeLive) return 'https://e-learning-bridge.realclass.co.kr'
  if (isDeployTypeStage) return 'https://e-learning-bridge-stage.realclass.co.kr'
  if (isDeployTypeDev) return 'https://e-learning-bridge-dev.realclass.co.kr'
  // return 'http://localhost:1232'
}

/**
 * useB2BContext를 사용할 수 없는 경우, 사용하는 boolean 값
 */
export const isB2BService =
  (typeof window !== 'undefined' && window.location.origin === getB2BServiceURL()) ||
  !!process.env.NEXT_PUBLIC_DEBUG_B2B

export function isB2BCompany(company: B2BCompanyQuery) {
  if (typeof company !== 'string') return false
  const companyTypeCode = company.toUpperCase()
  return Object.values(b2bCompanyTypeCode).includes(companyTypeCode)
}

export function isMatchingB2BTypeCode(
  company: B2BCompanyQuery,
  companyTypeCode: B2BPlatformTypeCode
) {
  if (typeof company !== 'string') return false
  return company.toUpperCase() === companyTypeCode
}
