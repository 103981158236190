import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { Reward } from './common.models'

interface State {
  rewards: { missionId: number; reward: Reward[] }[]
  token: {
    dayStep: string
    token: string
  }
}

const initialState: State = {
  rewards: [],
  token: {
    dayStep: '',
    token: '',
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.checkStudyRewards.success, (state, action) => {
      const { request, response } = action.payload
      state.rewards = response.map((r, i) => ({
        missionId: request.missionIds?.[i] || 0,
        reward: r.result,
      }))
    })
    .addCase(actions.resetRewards, (state) => {
      state.rewards = []
    })
    .addCase(actions.saveStudyToken, (state, action) => {
      // 같은 일차, 같은 단계일 경우 기존 토큰을 업데이트 하지 않는다
      if (state.token.dayStep === action.payload.dayStep) return
      state.token = {
        dayStep: action.payload.dayStep,
        token: action.payload.token,
      }
    })
)
