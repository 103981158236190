import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { NoCacheAPI } from '@store/ajax'
import { useNativeAppContext } from '@contexts/nativeApp'
import { useModal } from '@hooks/useModal'
import { openLink } from '@utils/utils'
import * as helper from './helpers'
import { getPriorityClassName, Notice } from './types'

dayjs.extend(isBetween)

function fetchNotice(id: string) {
  return NoCacheAPI.get<Notice>(`/notice-v2/${id}/notice-state.json`).then((res) => res.data)
}

const MINUTE = 1000 * 60

export default function useNotice(id: string) {
  const router = useRouter()
  const { openModal } = useModal()
  const { data: notice } = useSWR(`@notice/${id}`, () => fetchNotice(id), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: MINUTE,
  })
  const keyStorage = useMemo(() => new helper.KeyStorage(id), [id])
  const { isApp } = useNativeAppContext()

  useEffect(() => {
    if (!notice) return
    return popupNotice()
  }, [notice])

  if (!notice) return

  const { exposure, body, actions } = notice

  /* 투버튼 일 경우 오른쪽 버튼이 primary */
  const handleClickPrimaryButton = () => {
    const { primaryButton } = actions
    if (!primaryButton?.onClick) return
    const { dismissTime, outerLink: linkUrl } = primaryButton.onClick

    keyStorage.muteUntil(dismissTime)
    handleLink(linkUrl)
  }

  const handleClickSecondaryButton = () => {
    const { secondaryButton } = actions
    if (!secondaryButton?.onClick) return
    const { dismissTime, outerLink: linkUrl } = secondaryButton.onClick

    keyStorage.muteUntil(dismissTime)
    handleLink(linkUrl)
  }

  const handleLink = (link?: string) => {
    if (!link) return
    // 외부 링크인 경우 새탭으로 열기
    if (link.startsWith('https://') || link.startsWith('http://')) {
      openLink(isApp, link)
    } else {
      // 내부 링크인 경우 라우팅
      const innerLink = isApp ? `/app${link}` : link
      void router.push(innerLink)
    }
  }

  const popupNotice = () => {
    openModal({
      type: 'etc/Notice',
      props: {
        markdownUrl: body.src,
        primaryButton: actions.primaryButton && {
          text: actions.primaryButton.text,
          onClick: handleClickPrimaryButton,
        },
        secondaryButton: actions.secondaryButton && {
          text: actions.secondaryButton.text,
          onClick: handleClickSecondaryButton,
        },
      },
      overlayOptions: {
        closeOnOverlayClick: false,
        className: getPriorityClassName(exposure.priority),
      },
    })
  }
}
