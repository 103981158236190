import { useEffect, useRef, useState } from 'react'
import { ToastMessage } from '@hoc/withToast'
import * as S from './Toast.style'

type ToastState = 'hidden' | 'visible' | 'hiding' | 'closing'

interface Props {
  message: ToastMessage
  close: () => void
}
export default function Toast({ message, close }: Props) {
  const [state, setState] = useState<ToastState>('hidden')
  const toast = useRef<HTMLDivElement>(null)
  const wrapperHeight = useRef(0)
  const autoClose = useRef(0)

  useEffect(() => {
    wrapperHeight.current = toast.current?.clientHeight || 0
    setTimeout(() => setState('visible'))
    if (message?.autoClose) {
      autoClose.current = window.setTimeout(() => unmount(), message?.autoClose)
    }
  }, [])

  function unmount() {
    setState('hiding')
    setTimeout(() => setState('closing'), 300)
    setTimeout(() => close(), 800)
  }
  function action() {
    if (state === 'closing' || state === 'hiding') return
    clearTimeout(autoClose.current)
    message.action?.onClick()
    unmount()
  }

  const actionType = message?.action
    ? message.action.label.length >= 5
      ? 'long'
      : 'short'
    : 'none'

  return (
    <S.ToastWrapper
      className={state}
      style={{ height: state === 'visible' || state === 'hiding' ? wrapperHeight.current + 16 : 0 }}
    >
      <S.Toast ref={toast} type={actionType}>
        <S.ToastContent>{message.content}</S.ToastContent>
        {message.action && <S.ToastAction onClick={action}>{message.action.label}</S.ToastAction>}
      </S.Toast>
    </S.ToastWrapper>
  )
}
