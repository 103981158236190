import { QMS_API } from '@store/ajax'
import * as T from './types'

export const fetchUserCoupon = (params?: T.UserCouponPayload) =>
  QMS_API.get<T.CouponPrivate>('/coupon/private', { params })

export const fetchCouponPlan = () => QMS_API.get<T.CouponPlanResponse>('/coupon/coupon_plan')

export const fetchDefaultCoupon = () => QMS_API.get<T.DefaultCouponResponse>('coupon/default')

export const registerCoupon = (payload: T.RegisterCouponPayload) =>
  QMS_API.post<T.RegisterCouponResponse>('/coupon/private', payload)

export const fetchCouponByHash = ({ hash }: T.CouponByHashPayload) =>
  QMS_API.get<T.CouponByHashResponse>(`/coupon/coupon_plan/${hash}`)

export const fetchCouponByCode = ({ code }: T.CouponByCodePayload) =>
  QMS_API.get<T.CouponByCodeResponse>(`/coupon/coupon_plan/code`, { params: { code } })
