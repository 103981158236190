import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'
import { useB2BContext } from '@contexts/b2b'
import { useNativeAppContext } from '../contexts/nativeApp'
import { useScreenContext } from '../contexts/screen'

const withTheme = <P,>(Component: React.ComponentType<P>) => {
  const WithTheme = (props: P & JSX.IntrinsicAttributes) => {
    const { pathname, asPath } = useRouter()
    const { isApp, isWeb, isSmall, isLarge } = useScreenContext()
    const { appVersion } = useNativeAppContext()
    const { isB2B } = useB2BContext()

    // Web 인데, Desktop디자인이 없어서 Mobile 디자인을 적용해야 하는 경우
    const isMobileDesignOnlySellingPage = isLarge && isMobileDesignOnlyPage(asPath)

    const isCTAnullPage = !!nullCTAPages.find((item) => item === pathname)

    // 미디어쿼리에 휩쓸리지 않도록 root font-size: 10px로 고정
    const isRootFontFixedTo10px = rootFontfixedTo10pxPages.some((item) => asPath.startsWith(item))

    return (
      <ThemeProvider
        theme={{
          isApp,
          isWeb,
          isSmall,
          isLarge,
          isMobileDesignOnlySellingPage,
          appVersion,
          isCTAnullPage,
          isRootFontFixedTo10px,
          isB2B,
        }}
      >
        <Component {...props} />
      </ThemeProvider>
    )
  }
  return WithTheme
}

export default withTheme

function isMobileDesignOnlyPage(asPath: string) {
  return mobileDesignOnlyPages.some((path) => asPath.includes(path))
}

const mobileDesignOnlyPages: string[] = []

const nullCTAPages = [
  '/promotion/packages',
  '/promotion/package/genre-pass',
  '/promotion/guide',
  '/promotion/review',
  '/support',
]

const rootFontfixedTo10pxPages = [
  '/challenge/auth/notification',
  '/challenge/auth/account',
  '/challenge/auth/account-holder',
  '/challenge/auth/identification',
  '/challenge/auth/switch-account',
]
