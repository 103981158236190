import omit from 'lodash/omit'
import qs from 'querystring'
import { ParsedUrlQueryInput } from 'querystring'
import { removeNulls } from './utils'

export const makePath = (pathname: string) => (query: ParsedUrlQueryInput = {}): string => {
  // ex. pathname = "/class/[courseId]"
  const regex = /\[(.*?)\]/g

  // ex. matches = ["[courseId]"]
  const matches = pathname.match(regex) ?? []

  // ex. keys = ["courseId"]
  const keys = matches.map((matched) => regex.exec(matched)?.[1]).filter(removeNulls)

  // check query for dynamic path supplied
  const notSuppliedQueries = keys.filter((key) => !query[key])
  if (notSuppliedQueries.length > 0) {
    throw new Error(`PathError: query ${notSuppliedQueries.join(',')} is missing in ${pathname}.`)
  }
  return asPath({ pathname, query })
}

interface AsPathParams {
  pathname: string
  query: ParsedUrlQueryInput
}
/**
 * ex. pathname = "/class/[courseId]"
 * ex. query = { courseId: 3, foo: 'bar' }
 * => "/class/3?foo=bar"
 */
export const asPath = ({ pathname, query }: AsPathParams) => {
  const regex = /\[(.*?)\]/g
  const matches = pathname.match(regex) ?? []
  // regex는 내부 상태를 가지고 있어, regex.exec를 반복해서 실행하는 경우, 다른 결과가 반환된다.
  // 따라서 매번 새로운 regex를 만들어 실행한다.
  const keys = matches.map((matched) => /\[(.*?)\]/g.exec(matched)?.[1]).filter(removeNulls)
  const notOnPath = omit(query, keys)
  const asPath = matches.reduce((pathname, slug, i) => {
    const key = keys[i]
    return pathname.replace(slug, String(query?.[key]))
  }, pathname)
  const params = qs.stringify(notOnPath)
  return `${asPath}${params ? `?${qs.stringify(notOnPath)}` : ''}`
}
