import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import SemVer from 'semver'
import useSWR from 'swr'
import { NoCacheAPI } from '@store/ajax'
import { isOngoingChallenge } from '@store/modules/challenge/main/domain'
import { useChallenges } from '@store/modules/challenge/main/useChallengeApi'
import { useCheckUsageRight, useGroupUsageRightPeriod } from '@store/modules/user/swr'
import { getFeaturesPeriodByFeatureType } from '@store/modules/user/utils'
import { useNativeAppContext } from '@contexts/nativeApp'
import { getAppVersion } from '@hooks/useAppVersion'
import { isDeployTypeLive } from '@utils/deployType'
import * as helper from './helpers'
import { NoticeList } from './types'
import useNotice from './useNotice'

function fetchNoticeList() {
  const fileName = isDeployTypeLive ? 'list.json' : 'list_dev.json'
  return NoCacheAPI.get<NoticeList>(`/notice-v2/${fileName}`).then((res) => res.data)
}

export default function Notices() {
  const { asPath } = useRouter()
  const { data } = useSWR('@notice/list', fetchNoticeList)
  const notices = data?.notices ?? []
  const appContext = useNativeAppContext()
  const { version } = getAppVersion()
  const { data: challengeData } = useChallenges({ hideEnded: true })
  const { data: usageRightData } = useCheckUsageRight()

  const { data: studyPeriodData } = useGroupUsageRightPeriod()
  const hasSantaSay =
    getFeaturesPeriodByFeatureType('SANTA_SAY_SCHOLARSHIP', studyPeriodData?.featureTypes).length >
    0

  function isTargetPlatform({ exposure }: NoticeList['notices'][0]) {
    const allUser = exposure.targetPlatform === 'all'
    const appOnly = exposure.targetPlatform === 'appOnly' && appContext.isApp
    const aosOnly = exposure.targetPlatform === 'aosOnly' && appContext.isAOSApp
    const iosOnly = exposure.targetPlatform === 'iosOnly' && appContext.isIOSApp
    const webOnly = exposure.targetPlatform === 'webOnly' && !appContext.isApp
    return allUser || appOnly || aosOnly || iosOnly || webOnly
  }

  function isTargetVersion({ exposure }: NoticeList['notices'][0]) {
    if (!exposure.targetVersion) return true
    return SemVer.satisfies(version, exposure.targetVersion)
  }

  function isTargetPage({ exposure }: NoticeList['notices'][0]) {
    const targetPaths = helper.getTargetPage(exposure.targetPages)
    // 예외 페이지 설정이 있지만 사용하고 있지 않음
    // if (exposure.blockPages.some((item) => helper.isTargetPage(item, asPath))) return false
    return targetPaths.some((item) => helper.isTargetPage(item, asPath))
  }

  function isTargetUser({ exposure }: NoticeList['notices'][0]) {
    const allUser = exposure.targetUser === 'all'
    const liveUser = exposure.targetUser === 'live' && usageRightData?.hasLive
    const classUser = exposure.targetUser === 'class' && usageRightData?.hasClass
    const speakingCertifiedUser = exposure.targetUser === 'speakingCertified' && hasSantaSay
    const challengeUser =
      exposure.targetUser === 'challenge' && challengeData?.some((c) => isOngoingChallenge(c))

    return allUser || liveUser || classUser || challengeUser || speakingCertifiedUser
  }

  const activeNotices = useMemo(
    () =>
      notices
        .filter(isActiveNotice)
        .filter(isTargetPlatform)
        .filter(isTargetVersion)
        .filter(isTargetUser)
        .filter(isTargetPage)
        .filter((n) => !isMutedNotice(n)),
    [notices, asPath, appContext, version, usageRightData, studyPeriodData]
  )

  return (
    <div key="notices" id="notices">
      {activeNotices.map((notice) => (
        <Notice key={notice.id} id={notice.id} />
      ))}
    </div>
  )
}

// 기간 조건 체크
function isActiveNotice({ exposure }: NoticeList['notices'][0]) {
  return dayjs().isBetween(exposure.period.startDate, exposure.period.endDate)
}

// 이미 확인한 공지사항인지 체크
function isMutedNotice({ id }: NoticeList['notices'][0]) {
  const mutedUntil = localStorage.getItem(`rc2-${id}-notice-muted-until`)
  return mutedUntil ? dayjs().isBefore(mutedUntil) : false
}

// 노출 조건이 맞는 경우에만 렌더링
function Notice({ id }: { id: string }) {
  useNotice(id)
  return null
}
