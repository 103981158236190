import { useEffect, useState } from 'react'
import { useScreenContext } from '@contexts/screen'
import { getAppInfo } from '@utils/app'

interface AppInfo {
  deviceType?: string
  osVersion?: string
  deviceName?: string
  appVersion?: string
  envType?: string
}

export default function useAppInfo() {
  const { isApp } = useScreenContext()
  const [appInfo, setInfo] = useState<AppInfo>({})

  useEffect(() => {
    if (!isApp) return
    const appInfo = getAppInfo()
    const deviceType = appInfo?.[0]
    const osVersion = appInfo?.[1]
    const deviceName = appInfo?.[2]
    const appVersion = appInfo?.[3]
    const envType = appInfo?.[4]
    setInfo({ deviceType, osVersion, deviceName, appVersion, envType })
  }, [])

  return appInfo
}
