import styled from 'styled-components'
import { DefaultTheme } from 'styled-components'
import { cta } from '@components/CallToActionButton/CallToActionButton.style'
import { onLarge } from '@styles/mediaQuery'
import Z_INDEX from '@styles/zIndex'

const getBottomByScreen = (theme: DefaultTheme) =>
  `calc(${cta.bottom} + ${cta.height[theme.isLarge ? 'pc' : 'mo']})`

const getBottomByCTA = ({ theme }: { theme: DefaultTheme }) =>
  theme.isCTAnullPage ? '4.4rem' : getBottomByScreen(theme)

export const ButtonsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  z-index: ${Z_INDEX.CHANNEL_TALK_ENTRY};
  right: 1.6rem;
  bottom: ${getBottomByCTA};
  padding-bottom: 1rem;

  ${onLarge} {
    right: 6.2rem;
    bottom: ${getBottomByCTA};
  }
`

export const MessengerChannelButton = styled.button`
  position: relative;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 10rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  transition: transform ease 0.3s;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.03);
    }
  }
`
