import { createAction, createAsyncAction } from '@store/async'
import * as T from './types'

export const fetchLogReport = createAsyncAction(
  '@studyReport/FETCH_LOG_REPORT_REQUEST',
  '@studyReport/FETCH_LOG_REPORT_SUCCESS',
  '@studyReport/FETCH_LOG_REPORT_FAILURE'
)<undefined, T.FetchLogReportResponse>()

export const fetchRecentMedia = createAsyncAction(
  '@studyReport/FETCH_RECENT_MEDIA_REQUEST',
  '@studyReport/FETCH_RECENT_MEDIA_SUCCESS',
  '@studyReport/FETCH_RECENT_MEDIA_FAILURE'
)<undefined, T.FetchRecentMediaResponse>()

export const fetchRecentCourses = createAsyncAction(
  '@studyReport/FETCH_RECENT_COURSES_REQUEST',
  '@studyReport/FETCH_RECENT_COURSES_SUCCESS',
  '@studyReport/FETCH_RECENT_COURSES_FAILURE'
)<undefined, T.FetchRecentCoursesResponse>()

export const fetchRecentCoaching = createAsyncAction(
  '@studyReport/FETCH_RECENT_COACHING/REQUEST',
  '@studyReport/FETCH_RECENT_COACHING/SUCCESS',
  '@studyReport/FETCH_RECENT_COACHING/FAILURE'
)<undefined, T.FetchRecentCoachingResponse>()

export const fetchRecentCoachingDay = createAsyncAction(
  '@studyReport/FETCH_RECENT_COACHING_DAY/REQUEST',
  '@studyReport/FETCH_RECENT_COACHING_DAY/SUCCESS',
  '@studyReport/FETCH_RECENT_COACHING_DAY/FAILURE'
)<{ courseId: number }, T.FetchRecentCoachingResponse>()

export const fetchCourseIdsOfCoachingLogExists = createAsyncAction(
  '@studyReport/FETCH_COURSE_IDS_OF_COACHING_LOG_EXISTS/REQUEST',
  '@studyReport/FETCH_COURSE_IDS_OF_COACHING_LOG_EXISTS/SUCCESS',
  '@studyReport/FETCH_COURSE_IDS_OF_COACHING_LOG_EXISTS/FAILURE'
)<undefined, T.FetchCourseIdsOfCoachingLogExistsResponse>()

export const clearRecentCourses = createAction('@studyReport/CLEAR_RECENT_COURSES')()

export default {
  fetchLogReport,
  fetchRecentMedia,
  fetchRecentCourses,
  fetchRecentCoaching,
  fetchRecentCoachingDay,
  fetchCourseIdsOfCoachingLogExists,
  clearRecentCourses,
}
