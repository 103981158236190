import { ServiceVersion } from '../account/types'
import {
  ChallengeGroupTypeCode,
  ChallengeStatus,
  ServiceVersionTypeCode,
} from '../challenge/main/domain'
import { COURSE_TYPE } from '../global/models'
import { Profile } from '../profile/models'

/** FACEBOOK, GOOGLE은 사용하지 않음 */
export const SnsTypeCodeMap = {
  // FACEBOOK: '페이스북',
  // GOOGLE: '구글',
  KAKAO: '카카오',
  NAVER: '네이버',
  APPLE: '애플',
}
export type SnsTypeCode = keyof typeof SnsTypeCodeMap

export enum QMSAgreementType {
  SMS = 1,
  EMAIL = 2,
  PUSH = 3,
}

export interface QMSAgreement {
  agree: 0 | 1
  agreementType: QMSAgreementType
  created: string
}

export interface QMSLicense {
  accountName: string
  created: string
  id: number
  licenseTypeCode: number
  phoneNumber: string
  agreements: QMSAgreement[]
  hasSubAccount: boolean
  snsTypeCode: SnsTypeCode
}

export interface RC2User {
  id: number
  qLicenseType: string
  qLicenseId: number
  serviceVersion: ServiceVersion
  adultVerificationExpirationDatetime: string
  profiles: Profile[]
  dateJoined: string
}

export interface Guest {
  type: 'guest'
  id: undefined
  licenseType: undefined
  activeProfileId: undefined
  serviceVersion: undefined
  info: null
}

export interface User {
  type: 'user'
  licenseType: number
  id: number
  serviceVersion: ServiceVersion
  activeProfileId: number
  info: {
    nickname: string
    accountName: string
    phone: string
    dateJoined: string
    // 성인인증 유효기간이 아직 지나지 않았다면 오케이
    verifiedAsAdult: boolean
    marketingAgreed: {
      isAgreed: boolean
      created: YYYYMMDD_HHMMSS_Z
    }

    // 공유계정이 있는지 여부
    hasSubAccount: boolean
    accountType: SnsTypeCode | 'EMAIL'
  }
}

export type FeatureGroupTypeCode = 'GENERAL' | 'LIVE_CLASS' | 'CHALLENGE' | 'TALK'

export type FeatureTypeCode =
  | 'MULTI_PROFILE'
  | 'EXTRA_DOUBLE_DURATION'
  | 'EXTRA_THREE_MONTH'
  | 'EXTRA_SIX_MONTH'
  | 'EXTRA_ONE_YEAR'
  | 'EXTRA_EIGHTEEN_MONTH'
  | 'EXTRA_NINETY_DAY'
  | 'SANTA_SAY_SCHOLARSHIP'
  | 'REAL_LIVE' // 기본 권한
  | 'REAL_LIVE_V2' // NBCU IP를 사용한 수업 수강 불가
  | 'REAL_LIVE_V3' // 타일러-베스트 표현 입문 수업 수강 불가
  | 'REAL_LIVE_V4' // 라이브 정주행 10개(1020~1024, 1030, 1040~1043) 수업 수강 불가
  | 'WEEKLY_POINT_CHALLENGE_V1'
  | 'WEEKLY_MONEY_CHALLENGE_V1'
  | 'WEEKLY_MONEY_CHALLENGE_V2'
  | 'MARATHON_MISSION'
  | 'DAILY_MISSION_V1'
  | 'DAILY_MISSION_V2'
  | 'DAILY_MISSION_V3'
  | 'DAILY_MISSION_V4'
  | 'WEEKLY_MISSION'
  | 'DAILY_MISSION_V5'
  | 'WEEKLY_MISSION_V2'
  | 'V4_0_DAILY_CHALLENGE_V1'
  | 'V4_0_WEEKLY_CHALLENGE_V1'
  | 'V4_0_MARATHON_CHALLENGE_V2'
  | 'REAL_TALK_V1' // 톡톡 이용권 1년
  | 'REAL_TALK_V2' // 톡톡 이용권 1일

export interface CommonClassCourse {
  id: number
  name: string
  qLineItemIds: number[]
  type: COURSE_TYPE
  parentCourseId: number
}

export interface CommonChallenge {
  id: string
  name: string
  challengeGroupTypeCode: ChallengeGroupTypeCode
  serviceVersionTypeCode: ServiceVersionTypeCode
}

export interface ChallengesPeriod {
  startDate: YYYYMMDD
  endDate: YYYYMMDD
  transactionId: number
  id: string
  status: ChallengeStatus
}

export interface ClassCourse {
  startDate: YYYYMMDD
  endDate: YYYYMMDD
  transactionId: number
  id: number
}
export interface ClassCoursesPeriod {
  startDate: YYYYMMDD
  endDate: YYYYMMDD
  transactionId: number
  id: number
}

export interface FeatureTypesPeriod {
  startDate: YYYYMMDD
  endDate: YYYYMMDD
  featureGroupTypeCode: FeatureGroupTypeCode
  featureTypeCode: FeatureTypeCode
  transactionId: number
}

export type UsageRightPeriodType = ClassCoursesPeriod | ChallengesPeriod | FeatureTypesPeriod

// FIXME: LicenseType 및 LicenseTypeCode 정리가 필요함
export const LICENSE_TYPE = {
  REALCLASS_2: 10,
}
