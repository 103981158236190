import { createContext, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'

interface TRenderingContext {
  isInitialRender: boolean
}

const RenderingContext = createContext({} as TRenderingContext)

export const useRenderingContext = () => useContext(RenderingContext)

const HideAndLoad = styled.div<{ isInitialRender: boolean }>`
  visibility: ${({ isInitialRender }) => (isInitialRender ? 'hidden' : 'visible')};
`

function HidingChildrenOnInitialRender({ children }: { children: React.ReactNode }) {
  const { isInitialRender } = useRenderingContext()

  return <HideAndLoad isInitialRender={isInitialRender}>{children}</HideAndLoad>
}

export const withRendering = <P,>(Component: React.ComponentType<P>) => {
  const WithRendering = (props: P & JSX.IntrinsicAttributes) => {
    const [isInitialRender, setIsInitialRender] = useState(true)

    useEffect(() => {
      isInitialRender && setIsInitialRender(false)
    }, [])

    return (
      <RenderingContext.Provider value={{ isInitialRender }}>
        <HidingChildrenOnInitialRender>
          <Component {...props} />
        </HidingChildrenOnInitialRender>
      </RenderingContext.Provider>
    )
  }
  return WithRendering
}
