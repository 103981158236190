import { ServiceVersion } from '../account/types'

export enum CHALLENGE_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum NON_FAIL_CHALLENGE_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
}

export enum DAY_STATUS {
  NOT_SELECTED = 'NOT_SELECTED',
  ONGOING = 'ONGOING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PASSED = 'PASSED', // 면제
}

export enum MISSION_ID {
  WEEKLY_CHALLENGE = 2, // V2_5
  CLASS_COMPLETE = 3, // V2_5
  DAILY_MISSION = 7, // V3_0
  MARATHON_MISSION = 8, // V3_0
  DAILY_MISSION_V2 = 9, // V3_5
  WEEKLY_MISSION = 10, // V3_5
}

export enum MISSION_TYPE {
  CLASS = 'CLASS',
  LIVE = 'LIVE',
  ASSIGNMENT = 'ASSIGNMENT',
}

export enum ResetType {
  ACQUIRED = 'ACQUIRED',
  EXIPRED = 'EXIPRED',
  FAILED = 'FAILED',
}

export enum ChallengeDayStatusType {
  SUCCESS = 'SUCCESS',
  ONGOING = 'ONGOING',
  FAILED = 'FAILED',
}
export interface ChallengeDayStatus {
  dayId: number
  status: ChallengeDayStatusType
}

export interface ChallengeInfo {
  type?: 'V2_0' | 'V2_5' | 'V3_0' | '3_5'
  challengeName?: string | null
  challengeProfileId: number | null
  startedDate?: string
  realVersion: ServiceVersion
}

export interface RegularChallengeStatus extends CommonChallengeStatus {
  challengeName: string
  dayOfChallenge: number | null
  date: string // 종료 안되었으면 오늘 날짜, 종료되었으면 마지막으로 챌린지를 진행했던 날짜
  order: number | null
  dayId: number | null
  courseId: number | null
  courseName: string | null
  lectureCompleted: boolean | null
  clipCompleted: boolean | null
  trainingCompleted: boolean | null
  coachingCompleted: boolean | null
  hasLive: boolean
  liveAttendedDurationInMinutes: number | null
  viableMissions: MISSION_TYPE[]
  dateCompletedBy: null | MISSION_TYPE
  dailyMissionDaysForThisMonth: number
  qMissionRewardId: number
}

export interface CommonChallengeStatus {
  authCode: string | null
  dayStatus: DAY_STATUS
}

export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7

export enum V2_RewardType {
  MONEY = 'MONEY',
  POINT = 'POINT',
}

export interface V2_RewardSummary {
  rewardType: V2_RewardType
  startDate: string
  endDate: string
  successCnt: number
  failedCnt: number
  moneyRewardAmount: number | null
  pointRewardAmount: number | null
  totalMoneyRewardAmount: number | null
  totalPointRewardAmount: number | null
}

export interface Reward {
  id: number
  pointAmount: number
  moneyAmount: number
}
