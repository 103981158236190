import { DateRange } from '@pages/challenge/history/reward/RewardDetail/utils'
import { dayjs } from '@utils/dayjs'
import { COURSE_TYPE } from '../global/models'
import {
  ChallengesPeriod,
  ClassCoursesPeriod,
  CommonChallenge,
  CommonClassCourse,
  FeatureGroupTypeCode,
  FeatureTypeCode,
  FeatureTypesPeriod,
  UsageRightPeriodType,
} from '../user/models'
import {
  ChallengeGroupForClient,
  ClassGroupForClient,
  FeatureGroupForClient,
  UsageRightFeaturesForClient,
} from './enums.client'

export function checkUsageRight<T extends UsageRightFeaturesForClient>(
  products?: T[],
  currentServerTime?: string
) {
  if (!products) return undefined

  const remains = filterFeaturesByEndDate(products)
  if (remains.length === 0) return false

  const currentTime = currentServerTime ? dayjs(currentServerTime) : dayjs()

  return remains.some((period) =>
    currentTime.isBetween(dayjs(period.startDate), dayjs(period.endDate), 'day', '[]')
  )
}

export function getFeaturesByFeatureGroupType(
  type: FeatureGroupTypeCode,
  featureTypes?: FeatureTypesPeriod[]
) {
  if (!featureTypes) return []
  return featureTypes.filter(({ featureGroupTypeCode }) => featureGroupTypeCode === type)
}

export function getFeaturesPeriodByFeatureType(
  type: FeatureTypeCode,
  featureTypes?: FeatureTypesPeriod[]
) {
  if (!featureTypes) return []
  return featureTypes.filter(({ featureTypeCode }) => featureTypeCode === type)
}

export function groupByDate<T extends UsageRightPeriodType>(features: T[]) {
  const group = features.reduce((acc, cur) => {
    const dateKey = `${cur.startDate}|${cur.endDate}`
    if (!acc[dateKey]) acc[dateKey] = []
    acc[dateKey].push(cur)
    return acc
  }, {} as { [K: string]: T[] })

  return Object.values(group)
}

export function groupClassesByDate(
  classes: ClassCoursesPeriod[],
  commonClasses: CommonClassCourse[]
): ClassGroupForClient[] {
  const getCourseInfoById = (id: number) => commonClasses.find((item) => item.id === id)
  const groupClassesByDate = groupByDate(classes)

  return groupClassesByDate.map((items) => ({
    startDate: items[0].startDate,
    endDate: items[0].endDate,
    courses: items.map((item) => ({
      id: item.id,
      name: getCourseInfoById(item.id)?.name ?? '',
      type: getCourseInfoById(item.id)?.type || COURSE_TYPE.NORMAL,
      parentCourseId: getCourseInfoById(item.id)?.parentCourseId ?? null,
    })),
  }))
}

export function groupChallengesByDate(
  challenges: ChallengesPeriod[],
  commonChallenges: CommonChallenge[]
): ChallengeGroupForClient[] {
  const getChallengeType = (id: string) =>
    commonChallenges.find((challenge) => challenge.id === id)?.challengeGroupTypeCode ??
    'V4_0_DAILY_CHALLENGE'
  const groupChallengesByDate = groupByDate(challenges).flat()
  const addedChallengeTypeAndTitle = groupChallengesByDate.map((item) => ({
    startDate: item.startDate,
    endDate: item.endDate,
    status: item.status,
    challengeGroupTypeCode: getChallengeType(item.id),
  }))

  return sortChallengesByStatusAndTitle(addedChallengeTypeAndTitle)
}

export function groupFeaturesByDate(
  features: FeatureTypesPeriod[],
  type: FeatureGroupTypeCode
): FeatureGroupForClient[] {
  const featuresByType = getFeaturesByFeatureGroupType(type, features)
  const groupLiveByDate = groupByDate(featuresByType).flat()
  const sortedByEndDate = sortDateRangesByEndDate(groupLiveByDate)

  return sortedByEndDate.map((item) => ({
    startDate: item.startDate,
    endDate: item.endDate,
    featureTypeCode: item.featureTypeCode,
  }))
}

/** 시작일과 만료일을 고려해서 필터 해야할 때 사용 */
export function filterFeaturesByBetweenDate<T extends UsageRightFeaturesForClient>(features: T[]) {
  const now = dayjs()
  return features.filter(({ startDate, endDate }) => now.isBetween(startDate, endDate, 'day', '[]'))
}

/** 만료일을 기준으로 시작일은 고려하지 않아도 될 때 사용. 예) 내 정보 > 콘텐츠 이용 기간 */
export function filterFeaturesByEndDate<T extends UsageRightFeaturesForClient>(features: T[]) {
  const now = dayjs()
  return features.filter(({ endDate }) => now.isSameOrBefore(endDate, 'day'))
}

export function sortDateRangesByEndDate<T extends DateRange>(arr: T[]) {
  return arr.sort((a, b) => (a.endDate < b.endDate ? -1 : a.endDate > b.endDate ? 1 : 0))
}

function sortChallengesByStatusAndTitle(challenges: ChallengeGroupForClient[]) {
  const addedBaseGroupTypeCodeChallenges = getChallengeBaseGroupTypeCode(challenges)
  const statusPriority = {
    ENDED: 1,
    ONGOING: 2,
    NOT_STARTED: 3,
  }
  const baseGroupPriority = {
    DAILY: 1,
    WEEKLY: 2,
    MARATHON: 3,
    OTHERS: 4,
  }

  return addedBaseGroupTypeCodeChallenges.sort((a, b) => {
    // 1. status 우선순위로 정렬
    const statusComparison = statusPriority[a.status] - statusPriority[b.status]
    // 2. status가 같을 경우 baseGroupTypeCode 우선순위로 정렬
    return statusComparison !== 0
      ? statusComparison
      : baseGroupPriority[a.baseGroupTypeCode] - baseGroupPriority[b.baseGroupTypeCode]
  })
}

// 챌린지 type에 따라 baseGroupTypeCode를 추가
function getChallengeBaseGroupTypeCode(challenges: ChallengeGroupForClient[]) {
  return challenges.map((challenge) => {
    switch (challenge.challengeGroupTypeCode) {
      case 'DAILY_MISSION':
      case 'DAILY_MISSION_V2':
      case 'V4_0_DAILY_CHALLENGE':
        return { ...challenge, baseGroupTypeCode: 'DAILY' } as const
      case 'WEEKLY_MISSION':
      case 'V4_0_WEEKLY_CHALLENGE':
        return { ...challenge, baseGroupTypeCode: 'WEEKLY' } as const
      case 'MARATHON_MISSION':
      case 'V4_0_MARATHON_CHALLENGE':
      case 'V4_0_MARATHON_CHALLENGE_V2':
        return { ...challenge, baseGroupTypeCode: 'MARATHON' } as const
      default:
        return { ...challenge, baseGroupTypeCode: 'OTHERS' } as const
    }
  })
}

/**
 *
 * B2B 학습 환경은 무조건 단일 프로필 단일 클래스 이용권이 부여된 상태가 기준
 * @returns 단일 클래스 courseId
 */
export function pickClassCourseId(classCourses: ClassGroupForClient[]) {
  const filteredClassCourses = filterFeaturesByEndDate(classCourses)
  return filteredClassCourses[0].courses[0].id
}
