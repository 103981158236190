import { StateFromReducersMapObject } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import challenge from '@modules/challenge/reducer'
import memorize from '@modules/memorize/reducer'
import player from '@modules/player/reducer'
import study from '@modules/study/reducer'
import studyLog from '@modules/studyLog/reducer'
import studyReport from '@modules/studyReport/reducer'

const reducers = {
  challenge,
  memorize,
  player,
  study,
  studyLog,
  studyReport,
}

const appReducer = combineReducers(reducers)

export type RootState = StateFromReducersMapObject<typeof reducers>

export default appReducer
