import { all, takeEvery } from 'typed-redux-saga'
import { createAPISaga } from '@store/ajax'
import { fetchMediaSummaries } from './actions'
import * as API from './api'

const fetchMediaSummariesSaga = createAPISaga(fetchMediaSummaries, API.fetchMediaSummaries)

export default function* playlistSaga() {
  yield all([takeEvery(fetchMediaSummaries.request, fetchMediaSummariesSaga)])
}
