import { all, fork, takeLatest } from 'typed-redux-saga'
import { createAPISaga } from '@store/ajax'
import { fetchClip, fetchTrainings, fetchCoachings, fetchFurtherMedia } from './actions'
import * as API from './api'
import playlistSaga from './playlist/sagas'
import progressStepsSaga from './progressSteps/sagas'

const fetchClipSaga = createAPISaga(fetchClip, API.fetchClip)
const fetchTrainingsSaga = createAPISaga(fetchTrainings, API.fetchTrainings)
const fetchCoachingsSaga = createAPISaga(fetchCoachings, API.fetchCoachings)
const fetchFurtherMediaSaga = createAPISaga(fetchFurtherMedia, API.fetchFurtherMedia)

export default function* studySaga() {
  yield* fork(playlistSaga)
  yield* fork(progressStepsSaga)

  yield all([
    takeLatest(fetchClip.request, fetchClipSaga),
    takeLatest(fetchTrainings.request, fetchTrainingsSaga),
    takeLatest(fetchCoachings.request, fetchCoachingsSaga),
    takeLatest(fetchFurtherMedia.request, fetchFurtherMediaSaga),
  ])
}
