// TODO: 계층 구조로 하나씩 옮겨오기

const modalRegister = {
  /** template: children을 받는 모달 */
  'template/BlurringModal': () => import('./template/BlurringModal'),
  'template/FlexibleModal': () => import('./template/FlexibleModal'),

  /** common */
  'common/Alert': () => import('./common/Alert'),
  'common/Confirm': () => import('./common/Confirm'),
  'common/NewConfirm': () => import('./common/NewConfirm'),
  'common/ActionModal': () => import('./common/ActionModal'),
  'common/BottomModal': () => import('./common/BottomModal'),
  'common/VideoModal': () => import('./common/VideoModal'),
  'common/TwoWayActionModal': () => import('./common/ActionModal/TwoWayActionModal'),
  'common/CustomPeriodCalendar': () => import('./common/CustomPeriodCalendar'),
  'common/FullOnSmall': () => import('./common/FullOnSmall'),

  /** class */
  'class/ConfirmAdult': () => import('./class/ConfirmAdult'),
  'class/ConfirmChallengeSelect': () => import('./class/ConfirmChallengeSelect'),
  'class/ClassHomeFilter': () => import('./class/ClassHomeFilter'),
  'class/PhilosophySelect': () => import('./class/PhilosophySelect'),

  /** study */
  'study/CoachingComplete': () => import('./study/CoachingComplete'),
  'study/AssignmentComplete': () => import('./study/AssignmentComplete'),
  'study/DictionaryClip': () => import('./study/Dictionary/Dictionary.Clip'),
  'study/DictionaryLecture': () => import('./study/Dictionary/Dictionary.Lecture'),
  'study/StudyComplete': () => import('./study/StudyComplete'),
  'study/StudyContinue': () => import('./study/StudyContinue'),
  'study/LectureVideo': () => import('./study/LectureVideo'),
  'study/LandscapeAlert': () => import('./study/LandscapeAlert'),

  /** live */
  'live/LODModal': () => import('./live/LODModal'),
  'live/TwoPageModal': () => import('./live/TwoPageModal'),
  'live/AlarmMiniModal': () => import('./live/AlarmMiniModal'),
  'live/AssignmentTutorialModal': () => import('./live/AssignmentTutorialModal'),
  'live/AssignmentPracticeModal': () => import('./live/AssignmentPracticeModal'),
  'live/AssignmentNoticeModal': () => import('./live/AssignmentNoticeModal'),
  'live/SoundCheckModal': () => import('./live/SoundCheckModal'),

  /** account */
  'account/Kickout': () => import('./account/Kickout'),
  'account/ChangePassword': () => import('./account/ChangePassword'),
  'account/ChangePhoneNumber': () => import('./account/ChangePhoneNumber'),
  'account/FindPassword': () => import('./account/FindPassword'),
  'account/ConfirmEmail': () => import('./account/ConfirmEmail'),
  'account/MultiProfileChanger': () => import('./account/MultiProfileChanger'),
  'account/RetryNaverLogin': () => import('./account/RetryNaverLogin'),
  'account/WithdrawConfirm': () => import('./account/WithdrawConfirm'),
  'account/SubAccountCreate': () => import('./account/SubAccountCreate'),
  'account/SubAccountGuide': () => import('./account/SubAccountGuide'),
  'account/CourseList': () => import('./account/CourseList'),
  'account/ProfileUpdate': () => import('./account/ProfileSetting/ProfileUpdate'),
  'account/ProfileRegister': () => import('./account/ProfileSetting/ProfileRegister'),

  /** easyAccount */
  'easyAccount/EasySignup': () => import('./EasyAccount/Steps/EasySignup'),
  'easyAccount/Signup': () => import('./EasyAccount/Steps/Signup'),
  'easyAccount/Login': () => import('./EasyAccount/Steps/Login'),
  'easyAccount/FindEmail': () => import('./EasyAccount/Steps/FindEmail'),
  'easyAccount/FindPassword': () => import('./EasyAccount/Steps/FindPassword'),

  /** promotion */
  'promotion/BenefitPopup': () => import('./promotion/BenefitPopup'),
  'promotion/PanZoomModal': () => import('./promotion/PanZoomModal'),
  'promotion/ReviewInterview': () => import('./promotion/ReviewInterview'),
  'promotion/ShortInfo': () => import('./promotion/ShortInfo'),
  'promotion/TMCallRequested': () => import('./promotion/TMCallRequested'),
  'promotion/TMTerms': () => import('./promotion/TMTerms'),
  'promotion/BlurringModal': () => import('./promotion/BlurringModal'),
  'promotion/LiveClassDetailModal': () => import('./promotion/LiveClassDetailModal'),
  'promotion/CouponDownloaded': () => import('./promotion/CouponDownloaded'),
  'promotion/ApplyReopenNotify': () => import('./promotion/ApplyReopenNotify'),
  'promotion/TokTokFreeTrial': () => import('./promotion/TokTokFreeTrial'),

  /** coupon */
  'coupon/MCodeCoupon': () => import('./coupon/MCodeCoupon'),
  'coupon/ImageCouponModal': () => import('./coupon/ImageCouponModal'),

  /** chalenge */
  'challenge/BankList': () => import('./challenge/BankList'),
  'challenge/Agreement/PersonalInfo': () => import('./challenge/Agreement/Agreement.PersonalInfo'),
  'challenge/Agreement/ThirdParty': () => import('./challenge/Agreement/Agreement.ThirdParty'),
  'challenge/RewardRecieved': () => import('./challenge/RewardRecieved'),
  'challenge/ReportNotice': () => import('./challenge/ReportNotice'),
  'challenge/Identification': () => import('./challenge/Identification'),
  'challenge/RemindChallengeSelect': () => import('./challenge/RemindChallengeSelect'),
  'challenge/InduceAppReview': () => import('./challenge/InduceAppReview'),
  'challenge/MiniModal': () => import('./challenge/MiniModal'),
  'challenge/NotChangableAccount': () => import('./challenge/NotChangableAccount'),
  'challenge/NotReverifiable': () => import('./challenge/NotReverifiable'),
  'challenge/DailyWinnerReward': () => import('./challenge/DailyWinnerReward'),
  'challenge/ReviewDetail': () => import('./challenge/ReviewDetail'),

  /** TokTok */
  'toktok/onboarding': () => import('./toktok/Onboarding'),

  /** payment */
  'payment/DaumPostcode': () => import('./payment/DaumPostcode'),
  'payment/PaymentAlert': () => import('./payment/PaymentAlert'),
  'payment/PaymentDetail': () => import('./payment/PaymentDetail'),
  'payment/B2BProduct': () => import('./payment/B2BProduct'),
  'payment/ReadMeDetail': () => import('./payment/ReadMeDetail'),

  /** 학습리포트 / 내 학습 */
  'report/CourseSelect': () => import('./report/CourseSelectTitleSelect/CourseSelect'),
  'report/TitleSelect': () => import('./report/CourseSelectTitleSelect/TitleSelect'),
  'report/StudyCalendarInfo': () => import('./report/StudyCalendarInfo'),
  'report/StudyLogs': () => import('./report/StudyLogs'),
  'report/MySubtitleGuide': () => import('./report/MySubtitleGuide'),

  /** APP */
  'app/ForceUpdateAlert': () => import('./app/ForceUpdateAlert'),
  'app/ForceUpdateConfirm': () => import('./app/ForceUpdateConfirm'),

  /** toktok */
  'toktok/OngoingChat': () => import('./toktok/Chat/OngoingChat'),
  'toktok/PastChat': () => import('./toktok/Chat/PastChat'),

  /** etc */
  // 뮤지 계정 제공시 제공되는 팝송 1000곡 목록
  'etc/Popsong1000': () => import('./etc/Popsong1000'),
  // 약관 류의 마크다운
  'etc/Terms': () => import('./etc/Terms'),
  // 리포트 / 챌린지 캘린더 월 선택
  'etc/SlideupSelect': () => import('./etc/SlideupSelect'),
  // 아무것도 없는 모달
  'etc/FakeModal': () => import('./etc/FakeModal'),
  // 공지사항
  'etc/Notice': () => import('./etc/Notice'),
  // 제품에서 쓰이는 이미지 팝업
  'etc/PromotionImageModal': () => import('./etc/PromotionImageModal'),
}

export default modalRegister

export type ModalType = keyof typeof modalRegister
