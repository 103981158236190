import styled, { css } from 'styled-components'
import { COLORS } from '@styles/palette'
import { Position, Placement } from './types'

const adjustPlacementToTop = (visible: boolean, bottom: number) => css`
  bottom: ${bottom}px;
  ${visible && `transform: translateX(-50%) translateY(-.5rem);`}

  &:before {
    top: 100%;
  }
`

const adjustPlacementToBottom = (visible: boolean, top: number) => css`
  top: ${top}px;
  ${visible && `transform: translateX(-50%) translateY(.5rem);`}

  &:before {
    top: 0;
  }
`

export const Tooltip = styled.div<{
  position: Position
  placement: Placement
  visible: boolean
  noPadding: boolean
}>`
  position: absolute;
  padding: ${({ noPadding }) => (noPadding ? 0 : '0.3rem 0.6rem')};
  font-size: 1.4rem;
  font-weight: 400;
  color: ${COLORS.WHITE};
  border-radius: 0.4rem;
  background: ${COLORS.GREY_03};
  transform: translateX(-50%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    width: 0.7rem;
    height: 0.7rem;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    background: ${COLORS.GREY_03};
  }

  ${({ placement, position, visible }) =>
    css`
      left: ${position[2]}px;
      ${visible && `opacity: 1;`}

      ${placement === 'top'
        ? adjustPlacementToTop(visible, position[1])
        : adjustPlacementToBottom(visible, position[0])}
    `}
`
