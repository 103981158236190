import { QualityLevel } from 'src/@types/videojs-contrib-quality-levels'
import { createAction } from '@store/async'
import { Subtitle, SubtitleToRepeat } from './types'

export const ready = createAction('@player/READY')()
export const setControlReady = createAction('@player/SET_CONTROL_READY')()
export const clearControlReady = createAction('@player/CLEAR_CONTROL_READY')()
export const setError = createAction('@player/ERROR')<boolean>()

export const setVideoRange = createAction('@player/SET_VIDEO_RANGE')<{
  startTime: number
  endTime: number
}>()

export const play = createAction('@player/PLAY')()
export const pause = createAction('@player/PAUSE')()
export const complete = createAction('@player/COMPLETE')()
export const setPlaybackRate = createAction('@player/SET_PLAYBACK_RATE')<number>()

export const toggleKor = createAction('@player/TOGGLE_KOR')<boolean>()
export const toggleEng = createAction('@player/TOGGLE_ENG')<boolean>()

export const toggleLectureSubtitle = createAction('@player/TOGGLE_LECTURE_SUBTITLE')()
export const toggleRepeat = createAction('@player/TOGGLE_REPEAT')()

export const handleTime = createAction('@player/HANDLE_TIME')<number>()
export const setCurrentTime = createAction('@player/SET_CURRENT_TIME')<number>()
export const setSubtitleToRepeat = createAction('@player/SET_SUBTITLE_TO_REPEAT')<
  SubtitleToRepeat
>()
export const freezeSubtitle = createAction('@player/FREEZE_SUBTITLE')()
export const unfreezeSubtitle = createAction('@player/UNFREEZE_SUBTITLE')()

export const toggleFullscreen = createAction('@player/TOGGLE_FULLSCREEN')<boolean | undefined>()

export const setSubtitles = createAction('@player/SET_SUBTITLES')<Subtitle[]>()
export const resetState = createAction('@player/RESET_STATE')()
export const remove = createAction('@player/REMOVE')()
export const setQualityLevels = createAction('@player/SET_QUALITY_LEVELS')<{
  defaultQuality: number
  levels: QualityLevel[]
}>()
export const setCurrentQuality = createAction('@player/SET_CURRENT_QUALITY')<number>()

export const seek = createAction('@player/SEEK')<number>()

export default {
  ready,
  setControlReady,
  clearControlReady,
  setError,
  setVideoRange,
  play,
  pause,
  complete,
  setPlaybackRate,
  toggleKor,
  toggleEng,
  toggleLectureSubtitle,
  toggleRepeat,
  handleTime,
  setCurrentTime,
  setSubtitleToRepeat,
  freezeSubtitle,
  unfreezeSubtitle,
  toggleFullscreen,
  setSubtitles,
  resetState,
  remove,
  setQualityLevels,
  setCurrentQuality,
  seek,
}
