import { PublicAPI } from '@store/ajax'
import {
  FetchLecturePayload,
  FetchLectureResponse,
  FetchLecturesPayload,
  FetchLecturesResponse,
  FetchClipPayload,
  FetchClipResponse,
  FetchTrainingsPayload,
  FetchTrainingsResponse,
  FetchCoachingsPayload,
  FetchCoachingsResponse,
  FetchFurtherMediaPayLoad,
  FetchFurtherMediaResponse,
} from './types'

export function fetchLecture(payload: FetchLecturePayload) {
  const { courseId, dayId, lectureId } = payload
  return PublicAPI.get<FetchLectureResponse>(
    `/courses/${courseId}/days/${dayId}/lectures/${lectureId}`
  )
}

export function fetchLectures(payload: FetchLecturesPayload) {
  const { courseId, dayId } = payload
  return PublicAPI.get<FetchLecturesResponse>(`/courses/${courseId}/days/${dayId}/lectures`)
}

export function fetchClip(payload: FetchClipPayload) {
  const { courseId, dayId } = payload
  return PublicAPI.get<FetchClipResponse>(`/courses/${courseId}/days/${dayId}/clips`)
}

export function fetchTrainings(payload: FetchTrainingsPayload) {
  const { courseId, dayId } = payload
  return PublicAPI.get<FetchTrainingsResponse>(`/courses/${courseId}/days/${dayId}/trainings`)
}

export function fetchCoachings(payload: FetchCoachingsPayload) {
  const { courseId, dayId } = payload
  return PublicAPI.get<FetchCoachingsResponse>(`/courses/${courseId}/days/${dayId}/coachings`)
}

export function fetchFurtherMedia(payload: FetchFurtherMediaPayLoad) {
  const { seasonId, mediaId } = payload

  return PublicAPI.get<FetchFurtherMediaResponse>(`/seasons/${seasonId}/medias/${mediaId}`)
}
