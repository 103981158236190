import { PrivateAPI } from '@store/ajax'
import { PageSize, SubtitleFluency } from './models'
import * as T from './types'

// id3가 있는 경우 2단계, 없는 경우 정주행에서 사용
export const fetchMemorizeMediaSubtitles = ({
  id1,
  id2,
  id3,
}: T.FetchMemorizeMediaSubtitlesPayload) => {
  if (id3) {
    return PrivateAPI.get<T.FetchMemorizeMediaSubtitlesResponse>(
      `/courses/${id1}/days/${id2}/clips/${id3}/subtitles`
    )
  }
  return PrivateAPI.get<T.FetchMemorizeMediaSubtitlesResponse>(
    `/seasons/${id1}/medias/${id2}/subtitles`
  )
}

export const saveMemorizeMediaSubtitles = ({
  id1,
  id2,
  id3,
  subtitleId,
}: T.ToggleMemorizeMediaSubtitlesPayload) => {
  if (id3) {
    return PrivateAPI.post<T.FetchMemorizeMediaSubtitlesResponse>(
      `/courses/${id1}/days/${id2}/clips/${id3}/subtitles`,
      { subtitleId }
    )
  }
  return PrivateAPI.post<T.FetchMemorizeMediaSubtitlesResponse>(
    `/seasons/${id1}/medias/${id2}/subtitles`,
    { subtitleId }
  )
}

export const unsaveMemorizeMediaSubtitles = ({
  id1,
  id2,
  id3,
  subtitleId,
}: T.ToggleMemorizeMediaSubtitlesPayload) => {
  if (id3) {
    return PrivateAPI.delete<T.FetchMemorizeMediaSubtitlesResponse>(
      `/courses/${id1}/days/${id2}/clips/${id3}/subtitles/${subtitleId}`
    )
  }
  return PrivateAPI.delete<T.FetchMemorizeMediaSubtitlesResponse>(
    `/seasons/${id1}/medias/${id2}/subtitles/${subtitleId}`
  )
}

export const fetchMemorizeSubtitles = ({
  page = 1,
  seasonId,
  titleId,
  categoryId,
  fluency = 0,
}: T.FetchMemorizeSubtitlesPayload) =>
  PrivateAPI.get<T.FetchMemorizeSubtitlesResponse>(`/study/subtitles`, {
    params: {
      page,
      page_size: PageSize.SUBTITLE,
      seasonId,
      titleId,
      categoryId,
      fluency: SubtitleFluency[fluency],
    },
  })

export const fetchMemorizeSubtitleLogs = () =>
  PrivateAPI.get<T.FetchMemorizeSubtitleLogsResponse>(`/study/subtitle-log-seasons`)

export const updateMemorizeSubtitle = ({ subtitleId, fluency }: T.UpdateMemorizeSubtitlesPayload) =>
  PrivateAPI.patch(`/study/subtitles/${subtitleId}`, {
    fluency: SubtitleFluency[fluency],
  })

// 내문장에서 사용. TODO: 정주행에서 쓰는 것 처럼 바꿀 수 있음
export const unsaveMemorizeSubtitle = ({
  seasonId,
  mediaId,
  subtitleId,
}: T.UnsaveMemorizeSubtitlePayload) =>
  PrivateAPI.delete(`seasons/${seasonId}/medias/${mediaId}/subtitles/${subtitleId}`)

export const fetchMemorizeWords = ({ page = 1, text__contains }: T.FetchMemorizeWordsPayload) =>
  PrivateAPI.get(`/study/words`, { params: { page, page_size: PageSize.WORD, text__contains } })

export const fetchMemorizeWordSummaries = () => PrivateAPI.get(`/study/words/search`)

export const unsaveMemorizeWord = ({ memorizeWordId }: T.UnsaveMemorizeWordPayload) =>
  PrivateAPI.delete(`/study/words/${memorizeWordId}`)

export const unsaveMemorizeClipWord = ({
  courseId,
  dayId,
  clipId,
  subtitleId,
  profileWordId,
}: T.UnsaveMemorizeClipWordPayload) => {
  return PrivateAPI.delete(
    `/courses/${courseId}/days/${dayId}/clips/${clipId}/subtitles/${subtitleId}/words/${profileWordId}`
  )
}

export const fetchMemorizeLectureWords = ({
  courseId,
  dayId,
  lectureId,
}: T.FetchMemorizeLectureWordsPayload) =>
  PrivateAPI.get<T.FetchMemorizeLectureWordsResponse>(
    `/courses/${courseId}/days/${dayId}/lectures/${lectureId}/words`
  )

export const saveMemorizeLectureWord = ({
  courseId,
  dayId,
  lectureId,
  text,
}: T.SaveMemorizeLectureWordPayload) =>
  PrivateAPI.post(`/courses/${courseId}/days/${dayId}/lectures/${lectureId}/words`, { text })

export const unsaveMemorizeLectureWord = ({
  courseId,
  dayId,
  lectureId,
  profileWordId,
}: T.UnsaveMemorizeLectureWordPayload) => {
  return PrivateAPI.delete(
    `/courses/${courseId}/days/${dayId}/lectures/${lectureId}/words/${profileWordId}`
  )
}
export const fetchMemorizeClipWords = ({
  courseId,
  dayId,
  clipId,
  subtitleId,
}: T.FetchMemorizeClipWordsPayload) =>
  PrivateAPI.get<T.FetchMemorizeClipWordsResponse>(
    `/courses/${courseId}/days/${dayId}/clips/${clipId}/subtitles/${subtitleId}/words`
  )

export const saveMemorizeClipWord = ({
  courseId,
  dayId,
  clipId,
  subtitleId,
  text,
}: T.SaveMemorizeClipWordPayload) =>
  PrivateAPI.post(
    `/courses/${courseId}/days/${dayId}/clips/${clipId}/subtitles/${subtitleId}/words`,
    { text }
  )
