import { createReducer } from '@reduxjs/toolkit'
import { LectureSummary, MediaSummary } from '../models'
import { fetchMediaSummaries } from './actions'

interface State {
  lectures: {
    [dayId: number]: LectureSummary[]
  }
  medias: {
    [seasonId: number]: MediaSummary[]
  }
}

export const initialState: State = {
  lectures: {},
  medias: {},
}

export const actions = [fetchMediaSummaries.success]

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(fetchMediaSummaries.success, (state, action) => {
    const { seasonId } = action.payload.request
    const { result } = action.payload.response
    return { ...state, medias: { ...state.medias, [seasonId]: result } }
  })
)
