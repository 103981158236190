import { MakeStore, createWrapper } from 'next-redux-wrapper'
import { Store, createStore, applyMiddleware, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { Task } from 'redux-saga'
import { isDeployTypeLive } from '@utils/deployType'
import rootReducer, { RootState } from './reducers'
import rootSaga from './sagas'

export interface SagaStore extends Store {
  sagaTask?: Task
}

const configureStore: MakeStore<RootState, AnyAction> = () => {
  const sagaMiddleware = createSagaMiddleware()

  // composeWithDevTools({ trace: true }) options 주었을 시에, redux devTools 에서 api call 위치 추적 가능
  const withDevTools = composeWithDevTools({ trace: true })
  const compose = !isDeployTypeLive ? withDevTools : <T>(e: T) => e
  const enhancers = compose(applyMiddleware(sagaMiddleware))

  // create a makeStore function
  const store = createStore(rootReducer, enhancers) as SagaStore
  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

const wrapper = createWrapper(configureStore, { debug: false })

export default wrapper
