import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/ko'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import minMax from 'dayjs/plugin/minMax'
import objectSupport from 'dayjs/plugin/objectSupport'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.locale('ko') // use locale
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isToday)
dayjs.extend(isoWeek)
dayjs.extend(objectSupport)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(minMax)
dayjs.extend(localeData)

export const kst = 'Asia/Seoul'

export function isSaturday(date: Dayjs) {
  return date.day() === 6
}
export function isSunday(date: Dayjs) {
  return date.day() === 0
}

export function getDateKST(date: Dayjs) {
  return date.locale('ko').utcOffset(9)
}

export function formatDateForRaffle({ date, isEnded = false }: { date: Dayjs; isEnded?: boolean }) {
  return isEnded ? date.format('YYYY-MM-DDT23:59:59') : date.format('YYYY-MM-DDT00:00:00')
}

export { dayjs }
export type { Dayjs }

/** @example 07:30:00 */
export type HHMMSS = string & Opaque

const _unused = Symbol()
interface Opaque {
  [_unused]: never
}
