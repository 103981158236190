//리얼 1.0 과 도메인을 함께 사용하는 동안 붙여둘 prefix
//리얼 1.0 close 이후 prefix 제거할 때 font.css 파일도 함께 수정필요합니다.
export const URL_PREFIX = '/new'

const PATH_BASE = 'https://public.realclass.co.kr'
export const FONT_PATH_BASE = `${PATH_BASE}/fonts`
export const IMAGE_PATH_BASE = `${PATH_BASE}/images`
export const PROMOTION_IMAGE_PATH = `${IMAGE_PATH_BASE}/promotion`
export const SERVICE_IMAGE_PATH = `${IMAGE_PATH_BASE}/service`
export const VIDEO_PATH_BASE = `${PATH_BASE}/video`
export const AUDIO_PATH_BASE = `${PATH_BASE}/audio`
export const PROMOTION_VIDEO_PATH = `${VIDEO_PATH_BASE}/promotion`
export const NO_CACHE_PATH = `${PATH_BASE}/no-cache`
export const SCRIPT_PATH = `${PATH_BASE}/script`
export const REAL_CHALLENGE_NOW_IMAGE_PATH = `${PROMOTION_IMAGE_PATH}/real-challenge-now`

const REALPASS_IMAGE_PATH = `${PROMOTION_IMAGE_PATH}/real-pass`
export const LANGUAGE_COURSE_IMAGE_PATH = `${REALPASS_IMAGE_PATH}/language-course`

export const LANGUAGE_IMAGE_PATH = `${PROMOTION_IMAGE_PATH}/3.0/language`
export const CAREER_IMAGE_PATH = `${PROMOTION_IMAGE_PATH}/3.0/career`
export const TOUR_IMAGE_PATH = `${PROMOTION_IMAGE_PATH}/3.0/tour`

export const LANGUAGE_COURSE_VIDEO_PATH = `${PROMOTION_VIDEO_PATH}/real-pass/language-course`
export const LEARNING_CHALLENGE_IMAGE_PATH = `${IMAGE_PATH_BASE}/challenge/learning`
export const CHALLENGE_EVENT_IMAGE_PATH = `${IMAGE_PATH_BASE}/challenge/events`
export const DAILY_CHALLENGE_IMAGE_PATH = `${IMAGE_PATH_BASE}/challenge/daily`
export const REALLIVE_IMAGE_PATH = `${IMAGE_PATH_BASE}/reallive`

export const IMAGE_BASE = 'https://realclass2-public.imgix.net'

export const TERMS = {
  service: {
    histories: 'https://riiid.notion.site/ab257f9e59944078b921e4e9e5ad706d',
  },
  privacy: { latest: 'https://riiid.notion.site/d7191644bbb549ba958b1737e1784c39?pvs=4' },
  notice: 'https://riiid.notion.site/e379590b4ded44aeb96b8c7d504f6390?pvs=4',
}

export const B2B_SESSION_TIMEOUT_PATH = `${URL_PREFIX}/auth/b2b/session-timeout`

export const REAL_ACADEMY_URL =
  'https://www.realacademy.co.kr/promotion?utm_source=realclass&utm_medium=cpc&utm_campaign=referral'
