import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import semver from 'semver'
import { ServiceVersion } from '@store/modules/account/types'
import { useMe, useCheckUsageRight } from '@store/modules/user/swr'
import { useScreenContext } from '@contexts/screen'
import { useModal } from '@hooks/useModal'
import bridge from '../../components/WebviewInterface/bridgeMethods'
import { AppType, getAppVersion } from '../useAppVersion'
import useServiceJson, { TargetUser, TargetVersion } from './useServiceJson'

export default function useChallengeGuideModal() {
  const { openModal } = useModal()
  const { isWeb } = useScreenContext()
  const { asPath } = useRouter()

  const { data } = useServiceJson()
  const { challengeGuide } = data || {}

  const { data: usageRightData } = useCheckUsageRight()

  const { data: userData } = useMe()
  const serviceVersion = userData?.serviceVersion

  useEffect(() => {
    if (isWeb) return
    if (!challengeGuide) return
    if (hasNoticed()) return

    const { enabled, targetPages, targetUser, targetVersion, contents } = challengeGuide
    const isTargetPage = targetPages.some((item) => asPath.startsWith(item))
    if (!enabled || !isTargetPage) return

    const { isOldApp, appType } = isTargetAppVersion(targetVersion) || {}
    if (!isOldApp || !appType) return

    if (
      !serviceVersion ||
      !isTargetUser({ targetUser, usageRight: usageRightData?.hasUsageRight, serviceVersion })
    )
      return

    void setTimeout(() => openModal(guideModal(contents)))
  }, [challengeGuide?.enabled, usageRightData, serviceVersion])
}

const MUTED_UNTIL = 'rc2-challenge-guide-muted-until'
const noticedDateStore = createLocalStore(MUTED_UNTIL)

function createLocalStore(key: string) {
  return {
    set: (value: number, unit?: dayjs.OpUnitType) =>
      localStorage.setItem(key, dayjs().add(value, unit).format('YYYY-MM-DDTHH:mm:ss')),
    get: () => localStorage.getItem(key),
    remove: () => localStorage.removeItem(key),
  }
}

function hasNoticed() {
  const mutedUntil = noticedDateStore.get()
  const isNoticeMuted = mutedUntil ? dayjs().isBefore(mutedUntil) : false

  return isNoticeMuted
}

function isTargetAppVersion(targetVersion?: TargetVersion) {
  if (!targetVersion) return
  const { appType, version } = getAppVersion()
  if (
    (isAndroidApp(appType) && semver.satisfies(version, targetVersion.Android)) ||
    (isIPhoneApp(appType) && semver.satisfies(version, targetVersion.iPhone)) ||
    (isiPadApp(appType) && semver.satisfies(version, targetVersion.iPad))
  ) {
    return { isOldApp: true, appType }
  }
  return { isOldApp: false, appType }
}

interface IsTargetUserParams {
  targetUser?: TargetUser
  usageRight: boolean | undefined
  serviceVersion?: ServiceVersion
}
function isTargetUser({ targetUser, usageRight, serviceVersion }: IsTargetUserParams) {
  if (!targetUser || !serviceVersion) return false

  if (targetUser.purchased === usageRight && targetUser.serviceVersion === serviceVersion) {
    return true
  }
  return false
}

function isAndroidApp(currentAppType: AppType) {
  return currentAppType === AppType.Android
}
function isIPhoneApp(currentAppType: AppType) {
  return currentAppType === AppType.iPhone
}
function isiPadApp(currentAppType: AppType) {
  return currentAppType === AppType.iPad
}

interface Params {
  title: string
  message: string
  actionText: string
  cancelText: string
  outLink: string
}

function guideModal({ title, message, actionText, cancelText, outLink }: Params) {
  return {
    type: 'common/ActionModal',
    props: {
      title,
      message,
      actionText,
      onAction: () => {
        noticedDateStore.set(1, 'd')
        bridge.openBrowser(outLink)
      },
      cancelText,
      onCancel: () => noticedDateStore.set(100, 'y'),
    },
    overlayOptions: { closeOnOverlayClick: false, className: 'middle-priority' },
  } as const
}
