import { isB2BService } from './b2b'

const B2C_TOKEN = {
  ACCESS: 'rc2_uid',
  REFRESH: 'rc2_ruid',
}
const B2B_TOKEN = {
  ACCESS: 'rc2_b2b_uid',
  REFRESH: 'rc2_b2b_ruid',
}
const TOKEN = isB2BService ? B2B_TOKEN : B2C_TOKEN
const getStorage = () => (isB2BService ? sessionStorage : localStorage)

export const getAccessToken = (): string => {
  if (typeof window === 'undefined') return ''
  return getStorage().getItem(TOKEN.ACCESS) || ''
}

export const getRefreshToken = (): string => {
  if (typeof window === 'undefined') return ''
  return getStorage().getItem(TOKEN.REFRESH) || ''
}

export const setAccessToken = (token: string) => {
  getStorage().setItem(TOKEN.ACCESS, token)
}

export const setRefreshToken = (token: string) => {
  getStorage().setItem(TOKEN.REFRESH, token)
}

export const removeAccessToken = () => {
  getStorage().removeItem(TOKEN.ACCESS)
}

export const removeAllToken = () => {
  function removeAllB2CToken() {
    localStorage.removeItem(TOKEN.ACCESS)
    localStorage.removeItem(TOKEN.REFRESH)
  }

  function removeAllB2BToken() {
    localStorage.removeItem(B2B_TOKEN.ACCESS)
    localStorage.removeItem(B2B_TOKEN.REFRESH)
    sessionStorage.removeItem(B2B_TOKEN.ACCESS)
    sessionStorage.removeItem(B2B_TOKEN.REFRESH)
  }

  isB2BService ? removeAllB2BToken() : removeAllB2CToken()
}
