/**
 * user agent로 읽어온 앱정보를 배열형태로 return
 * ex) Realclass2(IOS;14.4;iPhone10,6;3.0.5;local)
 * ex) Realclass2(IOS-Pad;14.4;iPad11,1;1.0.5;stage)
 * ex) Realclass2(Android; 11; SM-F700N; 2.0.6dev; dev)
 * @returns appInfo // ['IOS', '14.4', 'iPhone10,6', '3.0.5', 'local'] | []
 */
export function getAppInfo() {
  if (typeof window === 'undefined') return []

  const RC2_KEY = 'Realclass2'
  const replacedUserAgent = window.navigator.userAgent.replace(/\s+|\(|\)/g, '')
  const hasAppInfo = replacedUserAgent.includes(RC2_KEY)
  const appInfoString = hasAppInfo ? replacedUserAgent.split(RC2_KEY).pop() : ''
  const appInfo = appInfoString ? appInfoString.split(';') : []
  return appInfo
}
