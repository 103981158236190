import { createReducer } from '@reduxjs/toolkit'
import { COURSE_TYPE } from '@store/modules/global/models'
import { fetchDayProgressLog, fetchSimpleDaySummary } from './actions'
import { DayProgress, SimpleDaySummary } from './types'

interface ProgressStepsState extends SimpleDaySummary {
  dayProgress: DayProgress
  clipCount: string
}

// To be a partial state of study reducer
// export initialState and actions
export const initialState: ProgressStepsState = {
  dayProgress: {
    lectureStatus: null,
    clipStatus: null,
    trainingStatus: null,
    coachingStatus: null,
    testStatus: null,
  },
  id: 0,
  type: COURSE_TYPE.NORMAL,
  day: 0,
  isPreview: false,
  dayName: '',
  courseName: '',
  clipCount: '',
  thumbnailUrl: '',
}

export const actions = [fetchDayProgressLog.success, fetchSimpleDaySummary.success]

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDayProgressLog.success, (state, { payload: { response } }) => {
      return { ...state, dayProgress: response.result }
    })
    .addCase(fetchSimpleDaySummary.success, (state, { payload: { response } }) => {
      //말줄임표 디자인 때문에 count 분리
      const [dayName, _clipCount] = response.result.dayName.split(' (')
      const clipCount = _clipCount ? ' (' + _clipCount : ''
      return { ...state, ...response.result, dayName, clipCount }
    })
)
