import { InternalAPI } from '@store/ajax'
import { LICENSE_TYPE } from '../user/models'
import { fetchSurveys, updateSurvey } from '../util/api'
import * as T from './types'

export const fetchProfiles = ({ userId, licenseType }: { userId: number; licenseType: number }) =>
  InternalAPI.get<T.FetchProfilesResponse>('/rc20/profiles', {
    params: { qLicenseType: licenseType, qLicenseId: userId },
  })

export const createProfile = ({ userId, ...payload }: T.CreateProfilePayload) =>
  InternalAPI.post<T.FetchProfileResponse>('/rc20/profiles', payload, {
    params: { qLicenseType: LICENSE_TYPE.REALCLASS_2, qLicenseId: userId },
  })

export const updateProfile = ({ userId, profileId, ...payload }: T.UpdateProfilePayload) =>
  InternalAPI.patch<T.FetchProfileResponse>(`/rc20/profiles/${profileId}`, payload, {
    params: { qLicenseType: LICENSE_TYPE.REALCLASS_2, qLicenseId: userId },
  })

export const deleteProfile = ({ userId, profileId }: T.DeleteProfilePayload) =>
  InternalAPI.delete(`/rc20/profiles/${profileId}`, {
    params: { qLicenseType: LICENSE_TYPE.REALCLASS_2, qLicenseId: userId },
  })

export const fetchProfileToken = (payload: T.FetchProfileTokenPayload) =>
  InternalAPI.post<T.FetchProfileTokenResponse>('/rc20/users/login', {
    qLicenseId: payload.userId,
    qLicenseType: LICENSE_TYPE.REALCLASS_2,
    profileId: payload.profileId,
  })

/** 톡톡 온보딩 시 성별, 생년월일 관련 */
export const fetchTokTokProfiles = () =>
  fetchSurveys<T.TokTokProfilesResponse>({ surveyTypeCode: 'REALCLASS_PROFILE_EXTRA_INFOS' })

export const updateTokTokProfiles = ({ birthday, gender }: T.TokTokProfilesPayload) =>
  updateSurvey({
    surveyTypeCode: 'REALCLASS_PROFILE_EXTRA_INFOS',
    value: { birthday, gender },
  })
