/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { PUBLIC_QMS_API, PublicAPI, QMS_API } from '@store/ajax'
import { URL_PREFIX } from '@constants/urls'
import { removeAllToken } from '@utils/token'
import { LICENSE_TYPE } from '../user/models'
import { AgreementType } from './models'
import { AdultVerificationUrlResponse, AdultVerificationResultResponse } from './types'
import * as T from './types'

export const sendAccountAuthCode = async (payload: T.AccountVerificationPayload) =>
  QMS_API.post('/account/license/v3/verification', { licenseTypeCode: 'REALCLASS_2', ...payload })

export const verifyAccountAuthCode = async (
  payload: T.AccountVerificationPayload & { code: string }
) =>
  QMS_API.post<{ result: T.AccountVerificationResponse }>(
    '/account/license/v3/verification/check',
    { licenseTypeCode: 'REALCLASS_2', ...payload }
  )

// 등록된 이메일이 있으면 200에 result 반환, 없으면 404
export const checkRegisteredEmail = async ({ email }: { email: string }) =>
  QMS_API.get<{ status: number; result: T.AccountVerificationResponse[] }>(
    '/account/license/v3/verification',
    { params: { licenseTypeCode: 'REALCLASS_2', email } }
  )

// 등록된 번호가 있으면 200에 result 반환, 없으면 404
export const checkRegisteredPhoneNumber = async ({ phoneNumber }: { phoneNumber: string }) =>
  QMS_API.get<{ status: number; result: T.AccountVerificationResponse[] }>(
    '/account/license/v3/verification',
    { params: { licenseTypeCode: 'REALCLASS_2', phoneNumber } }
  )

export const updateUserInfo = async (payload: {
  accountName?: string
  phoneNumber?: string
  password?: string
  licenseId: number
}) =>
  QMS_API.patch(`/account/license/v3/${payload.licenseId}`, {
    accountName: payload.accountName,
    phoneNumber: payload.phoneNumber,
    password: payload.password,
  })

export const withdraw = async (payload: { licenseId: number }) => {
  if (payload.licenseId < 0) throw new Error('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.')

  /**
   * 탈퇴시 사유가 필요하지 않기 때문에 cancellationReasonTypeCode: 'ETC' 고정 값으로 보내기로 함
   * 탈퇴시 유효한 이용권이 있는지 검증이 필요해서 licenseId를 넘겨줘야 함
   */
  await QMS_API.delete(`/account/license/v3/${payload.licenseId}`, {
    data: { cancellationReasonTypeCode: 'ETC', id: payload.licenseId },
  })
  // 로그인 페이지로 이동
  window.location.href = `${URL_PREFIX}/auth/login?withdraw=true`
  // 탈퇴 이후 토큰 날리기
  removeAllToken()
}

export const getAdultVerificationUrl = () =>
  PublicAPI.get<AdultVerificationUrlResponse>('/users/me/adult-verification/url')

export const getAdultVerificationResult = () =>
  PublicAPI.get<AdultVerificationResultResponse>('/users/me/adult-verification/result')

export const toggleMarketingAgreement = ({
  licenseId,
  agree,
}: {
  licenseId: number
  agree: boolean
}) =>
  QMS_API.post('/account/license/agreement/history', {
    licenseId,
    licenseType: LICENSE_TYPE.REALCLASS_2,
    agreements: [
      {
        licenseId,
        agree: agree ? 1 : 0,
        licenseType: LICENSE_TYPE.REALCLASS_2,
        agreementType: AgreementType.SMS,
      },
      {
        licenseId,
        agree: agree ? 1 : 0,
        licenseType: LICENSE_TYPE.REALCLASS_2,
        agreementType: AgreementType.EMAIL,
      },
    ],
  })

export const requestTMCounsel = (payload: T.CreateTMPayload) =>
  PUBLIC_QMS_API.post('account/license/v3/tm_db', payload)

export const saveUTMInfo = (payload: T.SaveUTMInfoPayload) =>
  PUBLIC_QMS_API.post('account/traffic', payload)
