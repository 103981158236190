import { all, takeEvery, takeLatest } from 'typed-redux-saga'
import { createAPISaga } from '@store/ajax'
import {
  fetchLogReport,
  fetchRecentMedia,
  fetchRecentCourses,
  fetchRecentCoaching,
  fetchRecentCoachingDay,
  fetchCourseIdsOfCoachingLogExists,
} from './actions'
import * as API from './api'

const fetchLogReportSaga = createAPISaga(fetchLogReport, API.fetchLogReport)
const fetchRecentMediaSaga = createAPISaga(fetchRecentMedia, API.fetchRecentMedia)
const fetchRecentCoursesSaga = createAPISaga(fetchRecentCourses, API.fetchRecentCourses)
const fetchRecentCoachingSaga = createAPISaga(fetchRecentCoaching, API.fetchRecentCoaching)
const fetchRecentCoachingDaySaga = createAPISaga(fetchRecentCoachingDay, API.fetchRecentCoachingDay)
const fetchCourseIdsOfCoachingLogExistsSaga = createAPISaga(
  fetchCourseIdsOfCoachingLogExists,
  API.fetchCourseIdsOfCoachingLogExists
)

export default function* studyReportSagas() {
  yield all([
    takeEvery(fetchLogReport.request, fetchLogReportSaga),
    takeEvery(fetchRecentMedia.request, fetchRecentMediaSaga),
    takeLatest(fetchRecentCourses.request, fetchRecentCoursesSaga),
    takeEvery(fetchRecentCoaching.request, fetchRecentCoachingSaga),
    takeEvery(fetchRecentCoachingDay.request, fetchRecentCoachingDaySaga),
    takeEvery(fetchCourseIdsOfCoachingLogExists.request, fetchCourseIdsOfCoachingLogExistsSaga),
  ])
}
