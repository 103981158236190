import { createGlobalStyle } from 'styled-components'
import { TOOLTIP_ROOT_ID } from '@components/Tooltip/Tooltip'
import { onLarge, onSmall } from '@styles/mediaQuery'
import { COLORS } from './palette'
import { Shimmer } from './styleUtils'
import Z_INDEX from './zIndex'

const GlobalStyle = createGlobalStyle`
  html {
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-overflow-style: scrollbar;
  }

  body {
    overflow-wrap: break-word;
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;

    &.noScroll {
      overflow: hidden !important;
      position: fixed;
      width: 100%;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  a,
  button {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  button {
    padding: 0;
    border: 0;
    background: none;
    &:disabled {
      cursor: not-allowed;
    }
  }

  input {
    padding: 0;
    color: inherit;
    border-width: 0.1rem;
  }

  textarea {
    padding: 0;
    border-radius: 0;
    resize: none;
  }

  input[name='password'],
  input[type='password'] {
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      position: absolute;
      left: 100%;
    }
  }

  input {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  img {
    -webkit-user-drag: none;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
   }
  .modal-overlay.dim {
    background: rgba(0, 0, 0, 0.75);
  }
  .modal-overlay.low-priority {
    z-index: 1800;
  }
  .modal-overlay.middle-priority {
    z-index: 1900;
  }
  .modal-overlay.high-priority {
    z-index: 2000;
  }
  .modal-overlay.click-through {
    pointer-events: none;
  }

  #${TOOLTIP_ROOT_ID} {
    z-index: ${Z_INDEX.OVERLAY};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .blind {
    overflow: hidden;
    position: absolute;
    width: .1rem;
    height: .1rem;
    font-size: 1rem;
    text-indent: 100%;
    white-space: nowrap;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${COLORS.REAL_GREEN};
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }

  [data-event] {
    a, button {
      pointer-events: all;
    }
  }

  @keyframes fadeIn {
    from {
      transform: translateY(5rem);
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .rc2-fade-in {
    animation: fadeIn 500ms;
  }
  .rc2-b4-fade-in {
    transform: translateY(5rem);
    opacity: 0;
  }
  .overflow-x-hidden {
    overflow-x: hidden
  }
  .rc2-channel-talk-show {
    transform: translateX(0);
    transition: transform 250ms ease-in-out;
  }
  .rc2-channel-talk-hide {
    transform: translateX(10rem);
    transition: transform 250ms ease-in-out;

    ${onLarge} {
      transform: translateX(15rem);
    }
  }

  ${onSmall} {
    br.large-only {
      display: none;
    }
  }
  ${onLarge} {
    br.small-only {
      display: none;
    }
  }

  .shimmer, .loading {
    ${Shimmer};
  }


`

export default GlobalStyle
