import styled, { css } from 'styled-components'
import { PlacementType } from './types'

export const Menu = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  list-style: none;
`

export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  padding: 1rem 2rem;

  white-space: nowrap;

  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.25rem;
`

interface MenuListContainerProps {
  buttonHeight: number
  buttonWidth: number
  placement: keyof typeof PlacementType
  offset: number
}

const top = css<MenuListContainerProps>`
  top: ${({ placement, buttonHeight }) => {
    if (placement === 'rightBottom' || placement === 'leftBottom') return 0
    if (placement.startsWith('bottom')) return `${buttonHeight}px`
    return 'initial'
  }};
`
const bottom = css<MenuListContainerProps>`
  bottom: ${({ placement, buttonHeight }) => {
    if (placement === 'rightTop' || placement === 'leftTop') return 0
    if (placement.startsWith('top')) return `${buttonHeight}px`
    return 'initial'
  }};
`
const left = css<MenuListContainerProps>`
  left: ${({ placement, buttonWidth }) => {
    if (placement === 'topRight' || placement === 'bottomRight') return 0
    if (placement.startsWith('right')) return `${buttonWidth}px`
    return 'initial'
  }};
`
const right = css<MenuListContainerProps>`
  right: ${({ placement, buttonWidth }) => {
    if (placement === 'topLeft' || placement === 'bottomLeft') return 0
    if (placement.startsWith('left')) return `${buttonWidth}px`
    return 'initial'
  }};
`
const centering = css<MenuListContainerProps>`
  ${({ placement, buttonWidth, buttonHeight }) => {
    if (placement === 'top' || placement === 'bottom')
      return `
        left: ${buttonWidth / 2}px;
        transform: translate(-50%, 0);
      `
    if (placement === 'right' || placement === 'left')
      return `
        top: ${buttonHeight / 2}px;
        transform: translate(0, -50%);
      `
    return ''
  }}
`
const offset = css<MenuListContainerProps>`
  ${({ placement, offset }) => {
    if (placement.startsWith('top')) return `padding-bottom: ${offset / 10}rem`
    if (placement.startsWith('bottom')) return `padding-top: ${offset / 10}rem`
    if (placement.startsWith('left')) return `padding-right: ${offset / 10}rem`
    if (placement.startsWith('right')) return `padding-left: ${offset / 10}rem`
  }};
`

export const MenuListContainer = styled.div<MenuListContainerProps>`
  position: absolute;
  ${top}
  ${bottom}
  ${left}
  ${right}
  ${centering}
  ${offset}
  z-index: 1;
`

export const MenuList = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border: 1px solid #eee;
  background-color: #fff;
`

export const MenuItem = styled.button`
  display: flex;
  align-items: center;

  width: 100%;
  min-height: 2rem;
  padding: 0 1rem;

  text-align: left;

  background-color: inherit;
  border: none;

  cursor: pointer;
`

export const MenuDivider = styled.hr`
  width: auto;
  margin: 0.5rem;

  border: 1px solid #eee;
  opacity: 0.6;
`
