import unique from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import qs from 'querystring'
import { Dayjs, dayjs } from '@utils/dayjs'
import { DayStatus } from '../days/domain'
import { AccumulatedDayTypeCode } from '../marathon/domain'

export const CHALLENGES_TYPE = {
  V2_0_CHALLENGE: 'V2_0_CHALLENGE',
  V2_5_CHALLENGE: 'V2_5_CHALLENGE',
  REAL_LEARNING_CHALLENGE: 'REAL_LEARNING_CHALLENGE',
  DAILY_MISSION: 'DAILY_MISSION',
  MARATHON_MISSION: 'MARATHON_MISSION',
  ONBOARDING_WEEKLY_CHALLENGE: 'ONBOARDING_WEEKLY_CHALLENGE',
  DAILY_MISSION_V2: 'DAILY_MISSION_V2',
  WEEKLY_MISSION: 'WEEKLY_MISSION',
  V4_0_DAILY_CHALLENGE: 'V4_0_DAILY_CHALLENGE',
  V4_0_WEEKLY_CHALLENGE: 'V4_0_WEEKLY_CHALLENGE',
  V4_0_MARATHON_CHALLENGE: 'V4_0_MARATHON_CHALLENGE',
  V4_0_MARATHON_CHALLENGE_V2: 'V4_0_MARATHON_CHALLENGE_V2',
  V4_0_CLASS_101_CHALLENGE: 'V4_0_CLASS_101_CHALLENGE',
  V2_0_CHALLENGE_2: 'V2_0_CHALLENGE_2',
}
export type ChallengeGroupTypeCode = keyof typeof CHALLENGES_TYPE

export const STANDALONE_CHALLENGES: ChallengeGroupTypeCode[] = [
  'V2_0_CHALLENGE',
  'REAL_LEARNING_CHALLENGE',
  'ONBOARDING_WEEKLY_CHALLENGE',
  'V2_0_CHALLENGE_2',
]

/** @constant
 * 챌린지 api에는 리워드 타입이 무엇인지 알 수 없어, 리워드 api를 부르지 않고 ChallengeGroupTypeCode만으로 구분할 때 사용.
 * 해당 챌린지가 pointChallenge인지 정보가 필요할 때는 usePointChallenges 훅 사용 권장.
 */
export const POINT_CHALLENGES: ChallengeGroupTypeCode[] = [
  'V4_0_DAILY_CHALLENGE',
  'V4_0_WEEKLY_CHALLENGE',
  'V4_0_MARATHON_CHALLENGE_V2',
]

export type ServiceVersionTypeCode = 'V2_0' | 'V2_5' | 'V3_0' | 'V3_5' | 'V4_0'
export type ChallengeStatus = 'ONGOING' | 'ENDED' | 'NOT_STARTED' // 더 이상 챌린지를 진행할 수 없게 될 경우 ENDED. ex) 365 실패 or 데일리 endDate 도달

export type MissionType = 'CLASS' | 'ASSIGNMENT' | 'CLASS101_STUDY_REVIEW'

export type Challenge = {
  serviceVersionTypeCode: ServiceVersionTypeCode
  challengeId: number
  profileChallengeId: number
  challengeGroupTypeCode: ChallengeGroupTypeCode
  accumulatedDayTypeCode: AccumulatedDayTypeCode | null

  startDate: YYYYMMDD
  endDate: YYYYMMDD

  missionId: number
  missionRewardIds: number[] // 값은 배열이지만, 실제로는 1개의 값만 가짐 https://qualsonteam.slack.com/archives/C018JEH2FGQ/p1679378677842329?thread_ts=1679301039.645839&cid=C018JEH2FGQ
  challengeStatus: ChallengeStatus
  lastActivityDate: YYYYMMDD | null // 마지막으로 챌린지 미션을 할 수 있었던 날. 챌린지가 진행중인 경우 오늘, 종료된 경우 마지막으로 학습을 할 수 있었던 날, 아직 시작하지 않은 경우 null. (참고) 클래스101/애플마라톤에서는 챌린지 종료일까지 계속 오늘 날짜로 업데이트된다
  subChallengeStatus: 'NOT_STARTED' | 'ONGOING' | 'SUCCESS' | 'FAILED' | 'PASSED' // 환급 단위의 상태. 환급 단위란? 데일리 = 1일, 위클리 = 1주일, 마라톤 300/500/700/1000일 등 보상을 획득하기 위해 학습을 성공해야 하는 단위
  subChallengeStatusDeterminationDate: YYYYMMDD | null // subChallengeStatus의 성공/실패가 확정된 날짜. 보상 확정 이전에는 null
  dayStatus: DayStatus | null

  authCode: string | null
  challengeMissions: MissionType[] // 이 챌린지가 기본적으로 성공 인정을 받기 위해 참가가능한 미션들을 챌린지 자체의 속성만 가지고 판단하여 리턴.
  targetMissions: MissionType[] // 유저의 이용권을 고려하여 이 챌린지가 기본적으로 성공 인정을 받기 위해 참가 가능한 미션들. 유저의 이용권이 없으면 빈배열.
  viableMissions: MissionType[] // 현재 해당 챌린지 성공을 위해 도전할 수 있는 미션들. 라이브 휴방인 경우 ASSIGNMENT가 존재하지 않습니다.
  dateCompletedMissionTypeCode: MissionType | 'PASSED' | null
  currentDays: number | null // 환급 단위 중에서 성공한 일차 개수. WEEKLY_MISSION 유저가 월,화에 각각 학습 완료한 경우 2로 표기되며, 다음주가 되면 0으로 리셋됨.

  challengeSpecificInfo: {
    succeededByLectureId?: number
    missionInfos: MissionInfo[] | [] //라이브는 여러 강의를 동시에 출석체크 할 수 있기 때문에 여러개일 수 있다. dayStatus가 not-selected 인 경우 빈배열입니다.
    numOfFailedProfileSubChallenges?: number // 'V4_0_MARATHON_CHALLENGE' | 'V4_0_CLASS_101_CHALLENGE'에서 사용
    numOfSucceededProfileSubChallenges?: number // 'V4_0_MARATHON_CHALLENGE' | 'V4_0_CLASS_101_CHALLENGE'에서 사용
    usageRightEndDate: YYYYMMDD | null // 4.0BM 이후로 종료된 챌린지에서만 사용
  }

  challengeDuration: number // 4.0 (애플) 마라톤에서 꼭 필요한 값으로 마라톤 데일리든, 마라톤 위클리든 durationType은 'WEEK'
  challengeDurationType: string

  transactionId: number

  /** 클래스학습 "챌린지(일차 선택)" 재시도 횟수, null이거나 retries: 0 모두 재시도를 시작하지 않은 상태를 나타낸다. 테스트 2회차와 관련 없음에 주의 */
  extras: null | { retries: 0 | 1 }
}

export type MarathonChallenge = Challenge & { accumulatedDayTypeCode: AccumulatedDayTypeCode }

export type MissionInfo = ClassDayMissionInfo | LiveDayMissionInfo | Class101DayMissionInfo
type OneOfMission<T> = T extends 'CLASS'
  ? ClassDayMissionInfo
  : T extends 'CLASS101_STUDY_REVIEW'
  ? Class101DayMissionInfo
  : LiveDayMissionInfo

export type ClassDayMissionInfo = {
  missionTypeCode: 'CLASS'
  dayOrder: number | null
  dayId: number | null
  courseId: number | null
  courseName: string | null
  lectureCompleted: boolean | null
  clipCompleted: boolean | null
  trainingCompleted: boolean | null
  coachingCompleted: boolean | null

  /**
    일차 선택 후) 진행중 false, 1회차 통과 true, 1회차 실패 false, 2회차 통과 true, 2회차 실패 true
    일차 재선택 후) 진행중 false, 3회차 통과 true, 3회차 실패 false, 4회차 통과 true, 4회차 실패 true
    */
  testCompleted: boolean | null
  testPassed: boolean | null
}

export type LiveDayMissionInfo = {
  missionTypeCode: 'ASSIGNMENT'
  courseId: number | null
  courseName: string | null
  lectureId: number | null
  lectureName: string | null
  testPassed: boolean | null
}

export type Class101DayMissionInfo = {
  missionTypeCode: 'CLASS101_STUDY_REVIEW'
  courseId: null
  courseName: null
  reviewSubmittedDirect: boolean
  reviewSubmittedSns: boolean
}

export type ChallengeInfo = {
  challengeProfileId: number | null
}

export interface FetchChallengesResponse {
  common: {
    hasHiddenEnded: boolean
    today: YYYYMMDD
  }
  result: Challenge[]
}

export interface FetchChallengeInfoResponse {
  result: ChallengeInfo
}

export function isPointChallenge(challenge: Challenge) {
  return POINT_CHALLENGES.includes(challenge.challengeGroupTypeCode)
}

export function getUniqChallengeTypes(challenges: Challenge[]) {
  return [...new Set(challenges.map((c) => c.challengeGroupTypeCode))]
}
export function getChallengesByType(type: ChallengeGroupTypeCode, challenges?: Challenge[]) {
  return challenges?.filter((challenge) => challenge.challengeGroupTypeCode === type)
}
export function getChallengesByTypes(types: ChallengeGroupTypeCode[], challenges?: Challenge[]) {
  return challenges?.filter((challenge) => types.includes(challenge.challengeGroupTypeCode))
}
export function getChallengesByMissionType(type: MissionType, challenges?: Challenge[]) {
  return challenges?.filter((challenge) => challenge.targetMissions.includes(type))
}
export function getChallengeByProfileChallengeId(
  profileChallengeId: number,
  challenges?: Challenge[]
) {
  return challenges?.find((challenge) => challenge.profileChallengeId === profileChallengeId)
}
export function getOnlyChallengeByMissionType(type: MissionType, challenges?: Challenge[]) {
  return challenges?.filter((c) => c.targetMissions.every((mission) => mission === type))?.[0]
}
export function getChallengesWithSingleMission(challenges?: Challenge[]) {
  return challenges?.filter((challenge) => challenge.targetMissions.length === 1)
}
export function getChallengesWithMultiMissions(challenges?: Challenge[]) {
  return challenges?.filter((challenge) => challenge.targetMissions.length > 1)
}
export function getChallengesByMonth(date: YYYYMMDD | Dayjs, challenges: Challenge[]) {
  return challenges.filter((c) => dayjs(date).isBetween(c.startDate, c.endDate, 'month', '[]'))
}
export function getSpecificChallenge(type: ChallengeGroupTypeCode, challenges?: Challenge[]) {
  return challenges?.find((challenge) => challenge.challengeGroupTypeCode === type)
}
export function getChallengeByChallengeMission(challenges: Challenge[], type: MissionType) {
  const challengeByType = challenges.filter((c) => c.challengeMissions.includes(type))
  return challengeByType.pop()
}
export function getChallengeByTargetMission(challenges: Challenge[], type: MissionType) {
  const challengeByType = challenges.filter((c) => c.targetMissions.includes(type))
  return challengeByType[challengeByType.length - 1]
}

export function sortClassMissionChallengesByPriority(challenges?: Challenge[]) {
  const identifyChallengeType = (type: ChallengeGroupTypeCode) => {
    if (type.includes('DAILY')) return 'DAILY'
    if (type.includes('MARATHON')) return 'MARATHON'
    return type
  }

  const CLASS_MISSION_CHALLENGES_TYPE_ORDER = ['DAILY', 'MARATHON']
  const getClassMissionPriorityByChallengeType = (type: ChallengeGroupTypeCode) => {
    return CLASS_MISSION_CHALLENGES_TYPE_ORDER.indexOf(identifyChallengeType(type))
  }

  return challenges?.sort((a, b) =>
    getClassMissionPriorityByChallengeType(a.challengeGroupTypeCode) <
    getClassMissionPriorityByChallengeType(b.challengeGroupTypeCode)
      ? -1
      : 1
  )
}

const CHALLENGE_STATUS_ORDER: ChallengeStatus[] = ['ONGOING', 'NOT_STARTED', 'ENDED']

export const getPriorityByChallengeStatus = (status: ChallengeStatus) =>
  CHALLENGE_STATUS_ORDER.indexOf(status)

export function sortChallengesByPriority(challenges: Challenge[]) {
  function makeKey(challenge: Challenge) {
    // 1순위 : 챌린지 상태
    const first = getPriorityByChallengeStatus(challenge.challengeStatus)
    // 2순위 : 시작일
    const second = challenge.startDate
    // 3순위: missionId
    const third = String(challenge.missionId).padStart(2, '0')

    return `${first}-${second}-${third}`
  }

  const mapped = challenges
    .map((c) => ({ key: makeKey(c), index: challenges.indexOf(c) }))
    .sort((a, b) => (a.key < b.key ? -1 : 1))
  const result = mapped.map(({ index }) => challenges[index])
  return result
}

export function sortByStartDate(challenges: Challenge[]) {
  return challenges.sort((a, b) =>
    a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
  )
}

export function getLastChallengeableDate(challenge: Challenge) {
  // 애플마라톤의 경우, 챌린지 성공/실패가 확정된 시점 이후로 7일이 지나면 비노출이지만 lastActivityDate는 챌린지 종료일까지 계속 업데이트 되기 때문에 예외처리
  const lastChallengeableDate = isV40Marathon(challenge)
    ? challenge.subChallengeStatusDeterminationDate
    : challenge.lastActivityDate
  return lastChallengeableDate
}

export function isLastChallengeableDateWithin7Days(challenge: Challenge): boolean {
  const lastChallengeableDate = getLastChallengeableDate(challenge)
  const today = dayjs()
  // lastActivityDate null이면 챌린지 시작 전. subChallengeStatusDeterminationDate가 null이면 챌린지 성공/실패가 확정되지 않았으므로 true
  if (!lastChallengeableDate) return true
  return today.diff(dayjs(lastChallengeableDate), 'd') <= 7
}

/* 챌린지 상태 관련 : 단일 챌린지에 대한 상태 체크만 구현한다 */
export function isNotStartedChallenge(challenge: Challenge): boolean {
  return challenge.challengeStatus === 'NOT_STARTED'
}
export function isOngoingChallenge(challenge: Challenge): boolean {
  if (isV40Marathon(challenge)) return isOngoingV40MarathonChallenge(challenge)
  return challenge.challengeStatus === 'ONGOING'
}
export function isEndedChallenge(challenge: Challenge): boolean {
  if (isV40Marathon(challenge)) return isEndedV40MarathonChallenge(challenge)
  return challenge.challengeStatus === 'ENDED'
}
export function isSucceededSubChallenge(challenge: Challenge): boolean {
  return challenge.subChallengeStatus === 'SUCCESS'
}
export function isFailedSubChallenge(challenge: Challenge): boolean {
  return challenge.subChallengeStatus === 'FAILED'
}
export function isDayNotSelected(challenge: Challenge): boolean {
  return challenge.dayStatus === 'NOT_SELECTED'
}
export function isDayOngoing(challenge: Challenge): boolean {
  return challenge.dayStatus === 'ONGOING'
}
export function isDaySucceeded(challenge: Challenge): boolean {
  return challenge.dayStatus === 'SUCCESS' || challenge.dayStatus === 'PASSED'
}
export function isDayFailed(challenge: Challenge): boolean {
  return challenge.dayStatus === 'FAILED'
}
export function isSubChallengeSucceeded(challenge: Challenge): boolean {
  return challenge.subChallengeStatus === 'SUCCESS' || challenge.subChallengeStatus === 'PASSED'
}
export function isEndedAllChallenges(challenges?: Challenge[]) {
  return challenges?.every(isEndedChallenge)
}

/* 오늘의 챌린지 학습 관련 */
export function hasTargetMission(type: MissionType, challenge: Challenge) {
  return challenge.targetMissions.includes(type)
}
export function hasViableMission(type: MissionType, challenge: Challenge) {
  return challenge.viableMissions.includes(type)
}
export function hasAttendedLiveMission(mission: LiveDayMissionInfo) {
  return !!mission.courseId
}

export function getAllMissionInfos(challenges?: Challenge[]) {
  const allMissionInfos = (challenges ?? []).flatMap(
    (challenge) => challenge.challengeSpecificInfo.missionInfos
  )

  if (allMissionInfos.length === 0) {
    return []
  }

  const missionInfos = uniqBy(allMissionInfos, (mission) => {
    switch (mission?.missionTypeCode) {
      case 'CLASS':
        return qs.stringify({
          type: mission.missionTypeCode,
          courseId: mission.courseId,
          dayId: mission.dayId,
        })
      case 'ASSIGNMENT':
        return qs.stringify({
          type: mission.missionTypeCode,
          courseId: mission.courseId,
          lectureId: mission.lectureId,
        })
      default:
        return null
    }
  })
  return missionInfos
}

export function getMissionInfosByType<T extends MissionType>(
  type: T,
  challenges?: Challenge[]
): OneOfMission<T>[] | null {
  const allMissionInfos = getAllMissionInfos(challenges)
  if (allMissionInfos.length === 0) {
    return null
  }

  function isSpecificMissionType<T>(type: T) {
    return (mission: MissionInfo): mission is OneOfMission<T> => mission.missionTypeCode === type
  }
  return allMissionInfos.filter(isSpecificMissionType(type))
}

/* reward id 관련 */
export function getRewardIds(challenges?: Challenge[]) {
  // 마라톤 챌린지는 rewardIds: [null] 이므로, 이를 필터링해야한다.
  return (
    unique(challenges?.flatMap((challenge) => challenge.missionRewardIds.filter((e) => e))) || []
  )
}

/* 유저가 가지고 있는 챌린지로 도전할 수 있는 미션  */
export function getTargetMissionsByType(type: ChallengeGroupTypeCode, challenges?: Challenge[]) {
  const challenge = challenges?.find((challenge) => challenge.challengeGroupTypeCode === type)
  return challenge?.targetMissions
}

const UNMERGEABLE_CHALLENGE: ChallengeGroupTypeCode[] = ['V4_0_CLASS_101_CHALLENGE']

function isUnmergeableChallenge(challenge: Challenge) {
  return UNMERGEABLE_CHALLENGE.includes(challenge.challengeGroupTypeCode)
}

export const getVisibleEndedChallengesByType = (challenges: Challenge[]) => {
  const endedChallenges = getEndedChallengesExceptOngoingType(challenges)
  const unmergeableChallenges: Challenge[] = []
  const mergeableChallenges: Challenge[] = []

  for (const challenge of endedChallenges) {
    if (isUnmergeableChallenge(challenge)) {
      unmergeableChallenges.push(challenge)
    } else {
      mergeableChallenges.push(challenge)
    }
  }
  const uniqEndedChallengesByType = uniqBy(mergeableChallenges, (c) => c.challengeGroupTypeCode)

  return [...unmergeableChallenges, ...uniqEndedChallengesByType]
}

// 현재 진행 중인 챌린지 타입은 제외한다.
function getEndedChallengesExceptOngoingType(challenges: Challenge[]) {
  const notEnded = challenges.filter((c) => !isEndedChallenge(c))
  const endeds = challenges.filter(isEndedChallenge)
  const notEndedTypeCodes = notEnded.map((on) => on.challengeGroupTypeCode)
  const endedChallenges = endeds.filter((e) => {
    if (isUnmergeableChallenge(e)) return true
    return !notEndedTypeCodes.includes(e.challengeGroupTypeCode)
  })
  return endedChallenges
}

/** 애플마라톤 예외 처리 관련
 * - 애플챌린지는 성공 혹은 실패 확정 시점을 기준으로 진행중 혹은 종료로 판단한다
 */
function isV40Marathon(challenge: Challenge) {
  return challenge.challengeGroupTypeCode === 'V4_0_MARATHON_CHALLENGE'
}
function isOngoingV40MarathonChallenge(challenge: Challenge) {
  const lastChallengeableDate = getLastChallengeableDate(challenge)
  const isOngoing = challenge.challengeStatus === 'ONGOING'
  return isOngoing && !lastChallengeableDate
}
function isEndedV40MarathonChallenge(challenge: Challenge) {
  const lastChallengeableDate = getLastChallengeableDate(challenge)
  const today = dayjs()
  return !!lastChallengeableDate && today.isAfter(dayjs(lastChallengeableDate))
}
