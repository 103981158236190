import { compose } from 'redux'
import { withB2B } from './b2b'
import { withNativeApp } from './nativeApp'
import { withRendering } from './rendering'
import { withScreen } from './screen'

// WARN: HOC 간 상호 의존성 존재함
// 순서를 변경하지 말 것
const withAppContext = compose(withRendering, withScreen, withB2B, withNativeApp)

export default withAppContext
