import dayjs from 'dayjs'
// import { AppType } from '@hooks/useAppVersion'
import { TargetPage } from './types'

const SESSION_NOTICED = 'noticed'
const MUTED_UNTIL = 'notice-muted-until'

interface Storage {
  muteUntil: (time: 'session' | number) => void
  isSessionMuted: () => boolean
  isLocalMuted: () => boolean
}

export class KeyStorage implements Storage {
  sessionKey: string
  localKey: string

  constructor(noticeName: string) {
    this.sessionKey = `rc2-${noticeName}-${SESSION_NOTICED}`
    this.localKey = `rc2-${noticeName}-${MUTED_UNTIL}`
  }

  muteUntil(time: 'session' | number) {
    if (time === 'session') return _closeDuringSession(this.sessionKey)
    if (time === -1) return _closePermanently(this.localKey)
    if (time > 0) return _closeForSomeDays(this.localKey, time)
  }

  isSessionMuted() {
    return !!sessionStorage.getItem(this.sessionKey)
  }

  isLocalMuted() {
    const mutedUntil = localStorage.getItem(this.localKey)
    const isNoticeMuted = mutedUntil ? dayjs().isBefore(mutedUntil) : false
    return isNoticeMuted
  }
}

const _closeForSomeDays = (key: string, dismissDay: number) => {
  localStorage.setItem(key, dayjs().add(dismissDay, 'd').format('YYYY-MM-DDTHH:mm:ss'))
}

const _closeDuringSession = (key: string) => {
  sessionStorage.setItem(key, dayjs().format('YYYY-MM-DDTHH:mm:ss'))
}

const _closePermanently = (key: string) => {
  localStorage.setItem(key, dayjs().add(100, 'y').format('YYYY-MM-DDTHH:mm:ss'))
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function getTargetPage(values: (TargetPage | string)[]) {
  return values.flatMap((val) => {
    switch (val) {
      case 'selling':
        return '/promotion'
      case 'payments':
        return ['/payments']
      case 'login/signUp':
        return ['/auth/login', '/auth/signup', '/app/auth/login', '/app/auth/signup']
      case 'liveDetail':
        return ['/live/', '/app/live/']
      case 'liveHome':
        return ['/live', '/app/live']
      case 'classDetail':
        return ['/class/', '/app/class/']
      case 'classHome':
        return ['/class', '/app/class']
      case 'challengeHome':
        return ['/challenge', '/app/challenge']
      case 'support':
        return ['/support', '/app/support']
      case 'toktok':
        return ['/toktok', '/app/toktok']
      default:
        return val
    }
  })
}

export function isTargetPage(page: string, asPath: string): boolean {
  switch (page) {
    case '/live':
    case '/app/live':
    case '/class':
    case '/app/class':
      return page === asPath
    default:
      return asPath.startsWith(page)
  }
}
