import { makePath } from './utils'

export type PathKey = keyof ReturnType<typeof paths>
export default function paths(prefixer?: (path: string) => string) {
  return {
    // study
    lecture: makePath('/study/lecture/[ids]', prefixer),
    clip: makePath('/study/media/[ids]', prefixer),
    training: makePath('/study/training/[ids]', prefixer),
    assignment: makePath('/study/assignment/[ids]', prefixer),
    classAssignmentPractice: makePath('/study/assignment/practice/[ids]', prefixer),
    coaching: makePath('/study/coaching/[ids]', prefixer),
    furtherMedia: makePath('/further/media/[ids]', prefixer),
    // furtherTraining: makePath('/further/training/[courseId]', prefixer), // spec out

    // study home
    // 라이브홈 또는 클래스홈으로 리다이렉트
    studyHome: makePath('/home', prefixer),

    // class
    classHome: makePath('/class', prefixer),
    class: makePath('/class/[courseId]', prefixer),
    classWatch: makePath('/class/[courseId]/watch', prefixer),

    // my study
    myAllSentence: makePath('/my/sentence', prefixer),
    mySentence: makePath('/my/sentence/[ids]', prefixer),
    myWord: makePath('/my/word', prefixer),
    myReport: makePath('/my/report', prefixer),
    myCoachingIndex: makePath('/my/coaching', prefixer),
    myCoaching: makePath('/my/coaching/[courseId]', prefixer),

    // challenge
    challengeHome: makePath('/challenge', prefixer),
    challengeRewardHistory: makePath('/challenge/history/reward', prefixer),
    challengeStatusHistory: makePath('/challenge/history/status', prefixer),
    challengeV40Raffle: makePath('/challenge/history/raffle', prefixer),
    challengeV40PointHistory: makePath('/challenge/history/point', prefixer),

    challenge365: makePath('/challenge/type/365', prefixer),
    challenge100DaysRealLearning: makePath('/challenge/type/100-days-real-learning', prefixer),
    challengeV25Weekly: makePath('/challenge/type/v25-weekly', prefixer),
    challengeV30Daily: makePath('/challenge/type/v30-daily', prefixer),
    challengeV30Marathon: makePath('/challenge/type/v30-marathon', prefixer),
    challengeV30MarathonRewardClaim: makePath('/challenge/marathon/v30-claim', prefixer),
    challengeV30MarathonReward: makePath('/challenge/marathon/v30-reward', prefixer),
    challengeV35Daily: makePath('/challenge/type/v35-daily', prefixer),
    challengeV35Weekly: makePath('/challenge/type/v35-weekly', prefixer),
    challengeV40Daily: makePath('/challenge/type/v40-daily', prefixer),
    challengeV40Weekly: makePath('/challenge/type/v40-weekly', prefixer),
    challengeV40Marathon: makePath('/challenge/type/v40-marathon', prefixer),
    challengeV40PointMarathon: makePath('/challenge/type/v40-point-marathon', prefixer),
    challengeV40MarathonReward: makePath('/challenge/marathon/v40-point-reward', prefixer),
    challengeClass101: makePath('/challenge/type/class101/[profileChallengeId]', prefixer),
    challengeV50Everyday365: makePath('/challenge/type/v50-365', prefixer),

    challengeRanking: makePath('/challenge/report/ranking', prefixer),
    challengeGoals: makePath('/challenge/report/goals', prefixer),
    challengeReportSuccessTips: makePath('/challenge/report/success-tips', prefixer),

    challengePurchasedDeviceReceipt: makePath('/challenge/receipt/purchased-device', prefixer),

    // 학습 일기 장학금, '먼슬리 챌린지'에서 네이밍 변경되어 url은 변경되었으나 관련된 컴포넌트 이름은 그대로 사용
    studyNote: makePath('/challenge/study-note', prefixer),
    studyNoteAll: makePath('/challenge/study-note/all', prefixer),

    // 스피킹 인증 장학금
    speakingCertified: makePath('/challenge/speaking-certified', prefixer),
    speakingCertifiedGrades: makePath('/challenge/speaking-certified/grades', prefixer),
    speakingCertifiedRewardReview: makePath(
      '/challenge/speaking-certified/reward/[YYYYMM]',
      prefixer
    ),

    // 클래스101 챌린지
    class101DirectReview: makePath('/challenge/review/direct', prefixer),
    class101SnsReview: makePath('/challenge/review/sns-link', prefixer),
    class101History: makePath('/challenge/type/class101/[profileChallengeId]/all', prefixer),

    // 계좌 인증 및 본인 인증: 공통
    challengeAuthNotification: makePath('/challenge/auth/notification', prefixer),
    challengeAccountHolder: makePath('/challenge/auth/account-holder', prefixer),
    challengeAccountAuth: makePath('/challenge/auth/account', prefixer),
    challengeIdentificationAuth: makePath('/challenge/auth/identification', prefixer),
    challengeAuthConfirm: makePath('/challenge/auth/confirm', prefixer),
    challengeSwitchAccount: makePath('/challenge/auth/switch-account', prefixer),

    // auth
    signupEmail: makePath('/auth/signup/email', prefixer),
    signupSocial: makePath('/auth/signup/social', prefixer),
    signup: makePath('/auth/signup', prefixer),
    login: makePath('/auth/login', prefixer),
    findId: makePath('/auth/find-email', prefixer),
    findPassword: makePath('/auth/find-password', prefixer),

    // my-info
    myInfo: makePath('/my-info', prefixer),
    accountSettings: makePath('/my-info/account-settings', prefixer),
    paymentHistory: makePath('/my-info/payments', prefixer),
    studyPeriod: makePath('/my-info/study-period', prefixer),
    classmateInvitation: makePath('/my-info/classmate-invitation', prefixer),

    // multi profile
    profile: makePath('/profile', prefixer),

    // misc
    sorryie: makePath('/sorryie'),
    support: makePath('/support', prefixer),

    // live
    liveHome: makePath('/live', prefixer),
    liveDetail: makePath('/live/[courseId]', prefixer),
    liveLOD: makePath('/live/[courseId]/lod', prefixer),
    liveSchedule: makePath('/live/schedule', prefixer),
    liveGuide: makePath('/live/guide', prefixer),
    liveCourseGuide: makePath('/live/course-guide', prefixer),
    liveLevelGuide: makePath('/live/level-guide', prefixer),
    liveAssignmentPractice: makePath('/live/assignment/practice', prefixer),

    // live study
    liveStudyWarmup: makePath('/live/study/warmup/[courseId]', prefixer),
    liveStudyTraining: makePath('/live/study/training/[courseId]', prefixer),
    liveStudyCoaching: makePath('/live/study/coaching/[courseId]', prefixer),
    liveStudyAssignment: makePath('/live/study/assignment/[courseId]', prefixer),

    // toktok
    toktokHome: makePath('/toktok', prefixer),
    toktokSettings: makePath('/toktok/settings', prefixer),
    toktokSettingsTopic: makePath('/toktok/settings/topic', prefixer),
    toktokSettingsAlarm: makePath('/toktok/settings/alarm', prefixer),
    toktokWeeklyReport: makePath('/toktok/weekly-report', prefixer),
    toktokWeeklyReportDetail: makePath('/toktok/weekly-report/[startDate]', prefixer),
    toktokMyTokDetail: makePath('/toktok/mytok', prefixer),

    // etc
    reward: makePath('/reward', prefixer), // 2.5 챌린지 보상 팝업을 띄워주기 위한 페이지
    tryLuckyScholarship5: makePath('/events/try-lucky-scholarship5', prefixer),

    //for app
    onBoarding: makePath('/on-boarding', prefixer),

    // B2B
    b2bSessionTimeOut: makePath('/auth/b2b/session-timeout', prefixer),
    b2bLogin: makePath('/auth/b2b/[company]/login', prefixer),
  }
}
