import getConfig from 'next/config'
import styled from 'styled-components'
import { isDeployTypeLive } from '@utils/deployType'
import { onSmall } from '@styles/mediaQuery'

const StyledBranchIndicator = styled.span`
  position: fixed;
  bottom: 0;
  right: 0;
  background: black;
  padding: 3px;
  font-size: 12px;
  color: white;
  z-index: 100;
  ${onSmall} {
    font-size: 10px;
  }
`

const { publicRuntimeConfig } = getConfig()

const standardBranches = ['dev/cuba', 'stage/cuba', 'live/cuba']
const BranchIndicator = () => {
  if (isDeployTypeLive) return <span id="branch-indicator" />
  if (standardBranches.includes(publicRuntimeConfig.currentBranch))
    return <span id="branch-indicator" />
  return (
    <StyledBranchIndicator id="branch-indicator">
      {publicRuntimeConfig.currentBranch}
    </StyledBranchIndicator>
  )
}

export default BranchIndicator
