/* FontStyle이 ResetStyle에 의해 override되는 것을 방지하기 위해 파일을 분리하고 이 파일을 prettier ignore에 추가함 */
import ResetStyle from './ResetStyle'
import GlobalStyle from './GlobalStyle'
import FontStyle from './FontStyle'

export default function Styles() {
  return (
    <>
      <ResetStyle />
      <GlobalStyle />
      <FontStyle />
    </>
  )
}
