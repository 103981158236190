import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import { useEffect, useState } from 'react'
import { useMe } from '@store/modules/user/swr'
import { useNativeAppContext } from '@contexts/nativeApp'
import useAppInfo from '@hooks/useAppInfo'
import { CHANNEL_TALK_LAUNCHER_ID } from '@components/ChannelTalkButton/ChannelTalkButton'
import bridge from '@components/WebviewInterface/bridgeMethods'
import { prefixUserId } from '@utils/gtm'
import { dev } from '@utils/utils'
import useProfile from './useProfile'

const ERROR_WORKFLOW_ID = '49446'

export const useChannelTalk = (bootSetting?: ChannelService.BootOption) => {
  const [initialized, setInitialized] = useState(false)

  const { data: userData } = useMe()
  const { isApp } = useNativeAppContext()
  const { activeProfile } = useProfile()
  const appInfo = useAppInfo()

  useEffect(() => {
    ChannelService.loadScript()

    if (!userData) return
    ChannelService.boot(
      {
        pluginKey: process.env.NEXT_PUBLIC_CHANNELIO_PLUGIN_KEY as string,
        hideChannelButtonOnBoot: true, // custom button 이용하기 위해 기본 버튼 비노출
        language: 'ko',
        memberId: prefixUserId(userData.id),
        profile: {
          email: userData.info?.accountName ?? null,
          name: `(리얼2.0 유저)${activeProfile?.nickname || ''}`,
          mobileNumber: userData.info?.phone ?? null,
          'RC2-device-type': appInfo.deviceType ?? null,
          'RC2-device-name': appInfo.deviceName ?? null,
          'RC2-os-version': appInfo.osVersion ?? null,
          'RC2-app-version': appInfo.appVersion ?? null,
          'RC2-env-type': appInfo.envType ?? null,
        },
        customLauncherSelector: `#${CHANNEL_TALK_LAUNCHER_ID}`,
        ...(bootSetting || {}),
      },
      (error) => {
        if (error) {
          dev.console.log('ChannelIO boot error:', error)
          return
        }
        setInitialized(true)
      }
    )
    isApp && bottomTabControl()
    return ChannelService.shutdown
  }, [userData, appInfo])

  function bottomTabControl() {
    ChannelService.onShowMessenger(() => bridge.bottomTabControl({ show: false }))
    ChannelService.onHideMessenger(() => bridge.bottomTabControl({ show: true }))
  }

  return {
    initialized,
    showChannelTalk: ChannelService.showMessenger,
    hideChannelTalk: ChannelService.hideMessenger,
    bottomTabControl,
    openWorkflow: ChannelService.openWorkflow,
    openErrorWorkflow: () => ChannelService.openWorkflow(ERROR_WORKFLOW_ID),
    onShowChannelTalk: ChannelService.onShowMessenger,
    onHideChannelTalk: ChannelService.onHideMessenger,
  }
}
