import cx from 'classnames'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useChannelTalk } from '@hooks/useChannelTalk'
import { PROMOTION_IMAGE_PATH } from '@constants/urls'
import { MessengerChannelButton } from '@pages/promotion/components/MessengerChannelButtons/MessengerChannelButtons.style'
import Imgix from '@components/Imgix'

interface ChannelTalkButtonProps {
  className?: string
}

export const CHANNEL_TALK_LAUNCHER_ID = 'channel_talk_launcher'

export default function ChannelTalkButton({ className }: ChannelTalkButtonProps) {
  const [showButton, setShowButton] = useState(false)
  const { initialized, onShowChannelTalk, onHideChannelTalk } = useChannelTalk()

  useEffect(() => {
    if (initialized) {
      setShowButton(true)
      // messenger 창 띄워져 있을 때 버튼 안보이도록 처리
      onShowChannelTalk(() => setShowButton(false))
      onHideChannelTalk(() => setShowButton(true))
    }
  }, [initialized])

  return (
    <Button
      id={CHANNEL_TALK_LAUNCHER_ID}
      className={cx({ hide: !showButton, className })}
      data-click="floating_channeltalk"
    >
      <Imgix
        src={`${PROMOTION_IMAGE_PATH}/common/channel_talk.png`}
        layout="fill"
        alt="구매 상담 버튼"
      />
    </Button>
  )
}

const Button = styled(MessengerChannelButton)`
  transition: transform ease 0.3s;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.03);
    }
  }

  &.hide {
    opacity: 0;
  }
`
