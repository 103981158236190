import { Button } from '@qualson/ui-kit'
import Router, { useRouter } from 'next/router'
import styled from 'styled-components'
import { useB2BContext } from '@contexts/b2b'
import { useNativeAppContext } from '@contexts/nativeApp'
import { useChannelTalk } from '@hooks/useChannelTalk'
import paths from '@constants/paths/product'
import { IMAGE_PATH_BASE } from '@constants/urls'
import Imgix from '@components/Imgix'
import { onLarge } from '@styles/mediaQuery'
import { Colors, Typography } from '@styles/system'

interface ErrorProps {
  message: React.ReactNode | string
  subMessage: React.ReactNode | string
  imgSrc?: string
  buttons?: {
    primaryButton: ButtonType
    secondaryButton?: ButtonType
  }
}

interface ButtonType {
  text: string
  onClick?: () => void
}

export default function Error({
  message,
  subMessage,
  imgSrc = `${IMAGE_PATH_BASE}/etc/error@2x.png`,
  buttons,
}: ErrorProps) {
  return (
    <Container>
      <Wrapper>
        <Content>
          <Imgix src={imgSrc} width="420" height="288" alt="에러 이미지" />
          <MainMessage>{message}</MainMessage>
          <SubMessage>{subMessage}</SubMessage>
        </Content>
        {buttons && (
          <ButtonWrapper>
            {buttons.secondaryButton && (
              <Button
                onClick={buttons.secondaryButton.onClick}
                size="large"
                variant="secondary"
                fullWidth
              >
                {buttons.secondaryButton.text}
              </Button>
            )}
            <Button
              onClick={buttons.primaryButton.onClick}
              size="large"
              variant="primary"
              fullWidth
            >
              {buttons.primaryButton.text}
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

export type BasicErrorProp = Partial<Pick<ErrorProps, 'message' | 'subMessage'>> & {
  type: 'error' | 'notFoundUrl'
}

export function BasicError({
  type,
  message = '불편을 드려 죄송합니다.',
  subMessage = (
    <>
      페이지에 문제가 생겼습니다. <br />
      잠시 후 다시 시도해주세요.
    </>
  ),
}: BasicErrorProp) {
  const { isApp } = useNativeAppContext()
  const router = useRouter()
  const { showChannelTalk, openErrorWorkflow } = useChannelTalk()
  const { isB2B } = useB2BContext()

  function goToHome() {
    // 404 에러 페이지의 경우, pathname에 '/app' 포함되지 않는 이슈 대응
    const prefixer = (path: string) => (isApp ? `/app${path}` : path)
    return router.push(paths(prefixer).studyHome())
  }

  const buttons = {
    error: {
      primaryButton: {
        text: '새로고침',
        onClick: () => Router.reload(),
      },
      secondaryButton: {
        text: '오류 문의하기',
        onClick: openErrorWorkflow,
      },
    },
    notFoundUrl: {
      primaryButton: {
        text: '홈으로 돌아가기',
        onClick: goToHome,
      },
      secondaryButton: {
        text: '1:1 문의하기',
        onClick: showChannelTalk,
      },
    },
  }

  return (
    <Error message={message} subMessage={subMessage} buttons={isB2B ? undefined : buttons[type]} />
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 1.6rem 1.6rem;
  ${onLarge} {
    padding-bottom: calc(100vh * 0.15); // 중앙보다 아래에 있어 보이는 점 보정
    justify-content: center;
  }
`

const Wrapper = styled.div`
  max-width: 48rem;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${onLarge} {
    justify-content: inherit;
  }
`

const Content = styled.div`
  & > * {
    margin-top: 3.2rem;
  }
`

const MainMessage = styled.h2`
  ${Typography.H4};
`

const SubMessage = styled.p`
  ${Typography.ButtonMedium};
  color: ${Colors.Grey070};
  white-space: pre-line;

  ${onLarge} {
    margin-top: 1.6rem;
  }
`

const ButtonWrapper = styled.div`
  ${onLarge} {
    margin-top: 7.3rem;
  }

  display: flex;
  button {
    flex: 1;
  }
  button ~ button {
    margin-left: 0.8rem;
  }
`
