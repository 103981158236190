import { QMS_API } from '@store/ajax'
import { LicenseType } from '../coupon/models'
import { Term, TermType } from './models'

export async function getLatestTermId() {
  // created의 값이 가장 최근인 가입 동의서 사용
  const params: { termType: TermType; licenseType: LicenseType } = {
    termType: 'ACCOUNT_CREATION',
    licenseType: 'REALCLASS_2',
  }
  const terms = await QMS_API.get<{ result: Term[] }>('/config-type/term/v2', { params }).then(
    (res) => res.data.result
  )
  const termId = terms.sort((a, b) => (a.created < b.created ? 1 : -1))[0].id
  return termId
}
